.all_products_page_wrapper {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
}
.all_products_controll_bord {
    display: flex;
    width: 100%;
    height: 76px;
    padding: 15px 20px;
    margin-top: 20px;
    align-items: center;
    justify-content: space-between;
}

.loading {
    clip-path: inset(0 4ch 0 0);
    animation: l 2s steps(5) infinite;
  }
  
  @keyframes l {
    to {
      clip-path: inset(0 -1ch 0 0)
    }
  }

.add_new_product span {
    text-decoration: none;
    display: flex;
    padding: 10px 15px;
    color: white;
    background-color: #008060;
    font-weight: 600;
    letter-spacing: 0.03em;
    border-radius: 5px;
    cursor: pointer;
}
.add_new_product span:hover {
    background-color: #1b9879;
    transition: background-color 0.4s ease 0s;
}

.info_name {
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.03em;
}

.products_panel_wrapper {
    height: 100%;
    width: 100%;
    padding: 18px;
    display: flex;

}

.table_products_adm {
    display: flex;
    height: 100%;
    width: 100%;
    /*background-color: antiquewhite;*/

    border-radius: 5px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;

}

.filters_and_controll_products_block {

    padding: 10px 10px;
    display: flex;
    width: 100%;
    border-bottom: 1px solid #74827b42;
    align-items: center;

}
.search_filter_container {
    max-width: 300px;
    width: 100%;
    display: flex;
    height: 100%;
    flex-direction: column;
    padding: 10px;
    display: flex;
}
.controls_button_search {
    display: flex;
}
.search_btn_param {
    font-size: 12px;
    font-weight: 600;
    padding: 5px;
    border: 1px solid black;
    display: flex;
    height: 30px;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
    user-select: none;
    transition: background-color 0.4s ease 0s, color 0.4s ease 0s;
}

.search_btn_param.active {
    background-color: black;
    color: white;
    transition: background-color 0.4s ease 0s, color 0.4s ease 0s;
}

.search_btn_param:hover {
    box-shadow: 0px 0px 4px 0px rgba(34, 60, 80, 0.8);
}

.adm_marker_block {
    height: 36px;
    display: flex;
    width: 100%;
    justify-content: space-between;

}
.lft_block {
    display: flex;
}
.rgt_block {
    display: flex;
    font-size: 12px;
    height: 100%;
    align-items: center;

    line-height: 10px;
    font-weight: 600;
    text-align: center;
    justify-content: center;
}
.del_bl_rgt {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 40px;
}
.user_bl_rgt {
    width: 70px;
    display: flex;
}
.time_bl_rgt {
    width: 80px;
    margin-right: 15px;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
}
.info_of_product.id {
    width: 50px;
    height: 100%;
    /*background-color: #999999;*/

    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
}
.info_of_product.img {
    width: 50px;
    height: 100%;
    /*background-color: #999999;*/
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
}

.info_of_product {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.05em;
    cursor: no-drop;
    user-select: none;
}
.info_of_product.name {
    width: 190px;
    /*background-color: pink;*/
    justify-content: flex-start;
}
.info_of_product.articul {
    width: 120px;
    justify-content: flex-start;
}
.info_of_product.status {
    /*background-color: antiquewhite;*/
    width: 65px;
}
.info_of_product.category {
    /*background-color: skyblue;*/
    width: 120px;
}
.info_of_product.predl {
    width: 80px;
    font-size: 12px;
}

.product_wrapper_gener {
    display: flex;
    align-items: center;
    height: 100%;
    font-weight: 600;
    text-transform: uppercase;
    flex-direction: column;
}

.container_for_search_field {
    display: flex;
    width: 100%;
    height: 40px;
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.search_input_products_page {
    border: 1px solid #c9cccf;
    display: flex;
    width: 100%;
    height: 100%;
    font-weight: 600;
    padding-left: 10px;
    letter-spacing: 0.03em;
}

.sort_field_items {
    display: flex;
    max-width: 215px;
    flex-wrap: wrap;
    align-items: center;
}
.sort_field_items .search_btn_param {
    margin-left: 10px;

}
.sort_field_p {
    cursor: pointer;
    user-select: none;
}
.sort_field_p.active {
    background-color: lightgrey;
    color: black;
    transition: background-color 0.4s ease 0s, color 0.4s ease 0s;
}
.sort_field_p:hover {
    background-color: lightgrey;
}

.container_for_change_mode_catalog_admin_page {
    display: flex;
    height: 100px;
    padding: 10px;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 30px;
}
.catalog_change_mode_heading {
    font-weight: 600;
}
.catalog_mode_change_buttons {
    display: flex;

}

.btn_for_control_catalog_mode {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 14px;
    font-weight: 600;
    border: 1px solid black;
}
.btn_for_control_catalog_mode {
    margin-left: 25px;
    cursor: pointer;
    transition: all 0.4s ease 0s;
}
.btn_for_control_catalog_mode:hover {
    background-color: black;
    color: white;
    transition: all 0.4s ease 0s;
}
.btn_for_control_catalog_mode:first-child {
    margin-left: 0px;
}
.btn_for_control_catalog_mode.active {
    background-color: black;
    color: white;
    transition: all 0.4s ease 0s;
}
.wrapper_for_operating_mode_container {
    display: flex;
    flex-direction: column;
}
.search_child_input {
    border: 1px solid #DADADA;
    border-radius: 5px;
    height: 35px;
    font-size: 15px;
    color: black;
    font-weight: 600;
    padding: 5px;
    width: 100%;
    margin-top: 15px;
}
.sort_heading_notificator {
    display: flex;
    font-weight: 600;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
    margin-top: 15px;
}
.one_change_sort_btn_save {
    margin-left: 30px;
}
.one_change_sort_btn_save:first-child {
    margin-left: 0px;
}