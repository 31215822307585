.videos {
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	font-weight: 600;
	height: 100%;
	text-transform: uppercase;
}

.videos__wrapper{
	width:100%;
}

.video__video-name-input {
	border: 1px solid #c9cccf;
	display: flex;
	width: 260px;
	height: 100%;
	padding: .5rem;
	font-weight: 600;
	letter-spacing: 0.03em;
}

.video__new-file {
	display: flex;
	gap: 0.5rem;
	align-items: center;
	max-width: 100%;
}

.video__new-file-icon {
	display: flex;
	justify-content: center;
	align-items: center;
	flex: none;
	border-radius: 1.2rem;
	width: 1.6rem;
	height: 1.6rem;
	background: #c7cad0;
}

.video__new-file-name {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-size: .8rem;
}

.video__upload-file-icon {
	display: flex;
	justify-content: center;
	align-items: center;
	flex: none;

	background: #e9eaec;
	transition: 0.3s background ease-in;

	border-radius: 4px;
	width: 1.6rem;
	height: 1.6rem;
}

.video__video-input {
	position: absolute;
	left: 0;
	top: 0;
	margin: 0;
	width: 0;
	height: 0;
	opacity: 0;
	visibility: hidden;
}

.video__upload-file-label {
	display: flex;
	gap: .5rem;
	align-items: center;
	cursor:pointer;
}


.video__file-text {
	font-size: .8rem;
	white-space: nowrap;
}

.video__input-container {
	display: flex;
	gap: 2rem;
	align-items: center;
}

.video__submit-button {
	background-color: black;
	color: white;
	margin: 1rem 0 0;
	width: 260px;
}

.videos__list {
	list-style: none;
	margin-top: 2rem;
	display:flex;
	flex-direction: column;
	gap:.8rem;
}

.videos__list-item {
	display: flex;
	align-items: center;
	justify-content: space-between
}

.videos__list-delete-button {
	width:5rem;
	margin:0;
}
.videos__error {
	color: red;
	font-weight: bold;
}

.videos__message {
	color: #ada2a2;
}

.videos__pagination {
	list-style:none;
	margin-top: 2rem;
	display:flex;
	gap:1.5rem;
}

.videos__page-item{
	border:1px solid black;
	padding:.3rem;
	width:2rem;
	height:2rem;
	text-align: center;
	cursor: pointer
}

.videos__description-label {
	font-size:.8rem;
}
.videos__info {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 1rem;
}

.videos__page-item--active {
	color:red;
}