.one_shop {
    padding: 0px 20px 40px 0px;
}
.one_shop_non {
    /*padding-right: 30px;*/
}
.one_shop_child {
    display: flex;
    width: 100%;
    /*min-height: 340px;*/
    height: 100%;
    background: #FFFFFF;
    border: 2px solid #000000;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
}
.one_shop_child:hover {
    /*box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);*/
    /*border: 4px solid #E0E0E0;*/
}
.one_shop_child.active {
    /*box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);*/
    border: 4px solid #000000;
    /*transition: border 0.8s ease 0s;*/
}
/*.s_shop_img {*/
/*    max-width: 279px;*/
/*}*/
.s_img {
    /*width: 100%;*/
    /*height: 100%;*/
    /*object-fit: cover;*/
}
.s_shop_conteiner {
    /*max-width: 591px;*/
    width: 100%;
    height: 100%;
    padding: 40px 30px 25px 40px;
}
.s_name_store {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #1A1A1A;
}
.s_items_text {

}
.s_adress {
    margin-top: 40px;
}
.s_worktime {
    margin-top: 18px;
}
.s_subline {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
}
.s_text_info {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #757575;
    margin-top: 10px;
    white-space: pre-line;
}
.s_phone {
    margin-top: 18px;
    display: flex;
    align-items: center;
}
.s_phone_button {
    max-width: 153px;
    width: 100%;
    min-height: 49px;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #1A1A1A;
    padding: 13px;
    border: 1px solid #1A1A1A;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 0 0 0 #000, 0 1px 0 0 #000, 1px 1px 0 0 #000, 1px 0 0 0 #000 inset, 0 1px 0 0 #000 inset;
}
.s_phone_button:hover {
    color: white;
    background-color: black;
    transition: background-color 0.4s ease 0s, color 0.4s ease 0s;
}

.s_phone_front {
    margin-left: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #9A9A9A;
    text-decoration: none;
    cursor: pointer;
    display: flex;
}
.s_phone_front:hover {
    text-decoration: underline;
}

.container_maps_icons {
    display: flex;
    align-items: center;
    cursor: pointer;
}
.maps_icons {
    width: 40px;
    height: 40px;
}
.maps_icons_text_one {
    font-size: 12px;
    font-weight: 600;
    margin-left: 12px;
    border-bottom: 1px solid transparent;
    transition: border 0.4s ease 0s;
}
.maps_icons_text_all {
    font-size: 12px;
    font-weight: 600;
    margin-left: 12px;
    border-bottom: 1px solid transparent;
    transition: border 0.4s ease 0s;
}
.container_maps_icons:hover .maps_icons_text_one {
    border-bottom: 1px solid black;
    transition: border 0.4s ease 0s;
}
.maps_city_and_shop {
    display: flex;
    align-items: flex-start;
    margin-top: 20px;
    justify-content: space-between;
}
.button_open_maps:hover .maps_icons_text_all {
    border-bottom: 1px solid black;
    transition: border 0.4s ease 0s;
}
.button_open_maps {
    display: flex;
    align-items: center;
    cursor: pointer;
}


@media (max-width: 1024px) {

    .one_shop_child {
        /*min-height: 297px;*/
    }
    .s_shop_conteiner {
        padding: 40px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }
    .s_phone_front {
        margin-left: 0px;
        margin-top: 10px;
        margin-bottom: 0px;
    }
    .s_adress {
        margin-top: 18px;
    }
    .s_phone {
        margin-top: 0px;
    }


}

@media (max-width: 768px) {
    .s_shop_conteiner {
        padding: 20px 10px 26px 20px;
        flex-direction: column;
    }
    .one_shop {
        /*padding: 0px 10px 20px 0px;*/
        padding: 0px 0px 20px 0px;
    }
    .s_adress {
        margin-top: 20px;
    }
    .s_worktime {
        margin-top: 20px;
    }
    .s_phone {
        margin-top: 20px;
    }

}
