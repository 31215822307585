.wrapper_trash_basket {
    height: 100%;
    width: 100%;
    display: flex;
    cursor: pointer;
}

.wrapper_trash_basket:hover svg path {
    opacity: 1;
    transition: opacity 0.4s ease 0s;
}
