.popup-box {
    transition: none;
    background-color: #fff;
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99;
}

.box {
    background-color: #fff;
    position: relative;
    width: 50%;
    margin: 0 auto;
    height: auto;
    max-height: 70vh;
    margin-top: calc(100vh - 60vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
}

.close-icon {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: calc(25% - 30px);
    top: calc(100vh - 60vh - 33px);
    background: #ededed;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    font-size: 20px;
}

.delete_item_button {
    margin-top: 30px;
    padding: 10px 30px;
    border: 1px solid #CE3F3F;
    color: #CE3F3F;
    font-weight: 600;
    cursor: pointer;
    user-select: none;
}
.delete_item_button:hover {
    background-color:#df7272;
    color: black;
}