.delete_image {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: rgba(255, 0, 0, 0.35);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    z-index: 15;
    cursor: pointer;
    color: black;
    font-weight: 700;
    font-size: 11px;
}