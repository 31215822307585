.add_one_stock_and_trends__delete-slider {
	padding: 5px;
	cursor: pointer;
	border: 1px solid #CE3F3F;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: auto;
}

.add_one_stock_and_trends__slider-title {
	font-weight: 500;
	color: black;
	display: flex;
	align-items: center;
}

.add_one_stock_and_trends__delete-slider:hover {
	background-color: #CE3F3F;
}

.admin_inputs_wrapper--slider {
	flex-direction: column;
	gap: 1rem;
	margin-top: 1.5rem;
	margin-bottom:1.5rem;
}

.add_one_stock_and_trends__slider-code-title {}

.stock_slider__inline-hint {
	color: #00000073;
	font-size: .9em;
}