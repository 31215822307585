.head {
    padding: 75px 29px 17px;
    border-bottom: 1px solid #000;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title {
    color: rgba(0, 0, 0, 0.70);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    display: flex;
    align-items: center;
    gap: 16px;
}

.reset {
    padding: 0;
    margin: 0;

    color: rgba(0, 0, 0);
    text-align: right;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    text-decoration: underline;
    cursor: pointer;
    transition: .3s;
}

.reset:disabled {
    pointer-events: none;
    color: rgba(0, 0, 0, 0.5);
}

.count {
    font-weight: 550;
}

@media all and (max-width: 1024px) {
    .head {
        padding: 65px 17px 17px 29px;
    }
}

@media all and (max-width: 768px) {
    .head {
        padding: 51px 9px 19px;
    }

    .title {
        font-size: 12px;
        gap: 5px;
    }

    .reset {
        font-size: 12px;
    }
}