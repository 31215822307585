
.vacancies_city_child {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90px;
    width: 33.33%;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    color: #FFFFFF;
    opacity: 0.5;
    cursor: pointer;
    transition: opacity 0.4s ease 0s, font-weight 0.2s ease 0s;
    padding: 0px 10px;
}

.vacancies_city_child:hover {
    opacity: 0.8;
}

.vacancies_city_child:nth-child(7n+1) {
    width: 25%;
}
.vacancies_city_child:nth-child(7n+2) {
    width: 25%;
}
.vacancies_city_child:nth-child(7n+3) {
    width: 25%;
}
.vacancies_city_child:nth-child(7n+4) {
    width: 25%;
}

.vacancies_city_child.active {
    opacity: 1;
    font-weight: 700;
    transition: opacity 0.4s ease 0s, font-weight 0.4s ease 0s;
}