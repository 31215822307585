.payment_methods {
    /* max-width: 153px; */
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 60px;
}
.payment_methods_text {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    /*text-transform: uppercase;*/
    color: #1A1A1A;
}
.payment_images_wrapper {
    display: flex;
    max-width: 153px;
    flex-wrap: wrap;
    justify-content: space-between;
}
.top_images_payment {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    margin-right: 18px;
}


@media (max-width: 1500px) {
    .payment_methods {
        margin-left: 0px;
    }
}
@media (max-width: 1024px) {
    .top_images_payment {
        margin-top: 17px;
    }
}
@media (max-width: 767px) {
    .payment_images_wrapper {
        flex-direction: row;
        flex-wrap: wrap;
    }
    .top_images_payment {
        margin-top: 20px;
        justify-content: flex-start;
        width: auto;
    }
    .top_images_payment img:first-child {
        margin-left: 0px;
    }
    .top_images_payment img {
        margin-left: 15px;
        object-fit: contain;
    }

    .payment_methods {
        max-width: unset;
        align-items: center;
    }
    .payment_methods_text {
        font-size: 16px;
    }
}

@media (max-width: 428px) {
    .payment_methods {
        align-items: flex-start;
    }
}

