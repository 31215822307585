.adm_page_lookbook{
    display: flex;
    height: 100%;
    width: 100%;
}
.adm_lookbook_wrapper {
    padding: 20px;
    width: 100%;
}
.adm_lookbook_title{
    display: flex;
    width: 100%;
    height: 76px;
    padding: 15px 20px;
    margin-top: 20px;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.03em;
}
.adm_lookbook_seo {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    background-color: #fff;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    padding: 10px
}
.field_lookbook {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.thumbnail_active {
    display: flex;
    align-items: center;
    margin-top: 18px;
}
.one_field_lookbook_wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    width: 100%;
    padding: 5px;
}
.two_field_lookbook_wrapper{
    display: flex;
}
.one_field_lookbook {
    font-weight: 600;
    font-size: 12px;
}
.lookbook_field_adm {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #c9cccf;
    font-weight: 600;
    font-size: 12px;
    padding: 5px 5px;
    border-radius: 5px;
    margin-top: 5px;
}
.lookbook_field_adm.textarea {
    resize: none;
    height: 100px;
    margin-right: 10px;
}


.save_fields_lookbook {
    display: flex;
    height: 40px;
    width: 500px;
    margin: 0 auto;
    margin-top: 20px;
    background: white;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    font-weight: 600;
    cursor: pointer;
}
.save_fields_lookbook:hover {
    box-shadow: 0px 0px 4px 0px rgb(34 60 80 / 80%);
}

.save_fields_lookbook.trig {
    animation: trigerSaveVacancy 2s ease infinite;
}

.message_lookbook {
    height: 30px;
    color: #CE3F3F;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    margin-top: 10px;
}


.content_lookbook_adm {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    background-color: #fff;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    padding: 10px;
}
.one_line_look {
    display: flex;
    height: 100%;
    text-decoration: none;
    color: black;
    width: 100%;
    align-items: center;
}
.one_line_look.non_active {
    background: rgba(217, 217, 217, 0.5);
}
.add_new_look {
    display: flex;
    max-width: 250px;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    background-color: white;
    border: 1px solid black;
    font-weight: 600;
    color: black;
    cursor: pointer;
}

.one_line_look {
    width: 100%;
    min-height: 60px;
    border: 1px solid #c9cccf;
    display: flex;
    margin-top: 5px;
    align-items: center;
}
.wrap_look_line {
    display: flex;
    width: 100%;
    align-items: center;
    text-decoration: none;
}
.look_previews {
    width: 190px;
    display: flex;
    justify-content: flex-start;
    padding: 0px 15px;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    text-transform: none;
    text-decoration: none;
    cursor: pointer;
    color: black;
}


.one_look {
    display: flex;
    justify-content: space-between;
    margin: 10px;
    margin-top: 30px;
    flex-direction: column;
    border: 2px solid #c9cccf;
    padding: 20px;
    background: white;
}
.delete_block_look {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    padding: 10px;
}
.del_look_child {
    width: 24px;
    height: 24px;
    cursor: pointer;
    position: relative;
}
.del_look_child::before {
    content: " ";
    position: absolute;
    width: 24px;
    height: 4px;
    transform: rotate(-45deg);
    background-color: #000000;
    top: 12px;
    right: 0;
}
.del_look_child::after {
    content: " ";
    position: absolute;
    width: 24px;
    height: 4px;
    transform: rotate(45deg);
    background-color: #000000;
    top: 12px;
    right: 0;
}
.look_previews_photo {
    display: flex;
}
.container_img_look {
    height: 250px;
    max-width: 250px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.field_selection_new_look {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    min-height: 200px;
    padding: 30px;
}
.heading_new_look {
    display: flex;
    justify-content: center;
    width: 100%;
    font-weight: 600;
    font-size: 16px;
}
.block_selection_text {
    display: flex;
    margin: 0 auto;
    margin-top: 20px;
    font-size: 12px;
    color: #949393;
    max-width: 300px;
    width: 100%;
    text-align: center;
    font-weight: 600;
}
.wrapper_block_selection {
    display: flex;
    max-width: 700px;
    width: 100%;
    margin: 0 auto;
    margin-top: 30px;
    height: 200px;
    justify-content: space-between;
}
.image_block_look_choosing {
    display: flex;
    height: 100%;
    width: 49%;
    justify-content: space-around;
    align-items: center;
    border: 1px solid lightgrey;
}
.image_block_look_choosing:hover {
    border-color: black;
    cursor: pointer;
}
.one_img_look {
    height: 80%;
    width: 80%;
    background-color: #d3d3d366;
}
.two_img_look {
    width: 41%;
    height: 80%;
    background-color: #d3d3d366;
}
.block_with_photo_look {
    max-width: 700px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin-top: 20px;
}
.big_image_child_look {
    min-height: 325px;
    height: 100%;
    display: flex;
    width: 100%;
    border: 1px solid #c9cccf;
    border-radius: 5px;
    padding: 15px;
    margin-top: 20px;
    position: relative;
}
.display_selection_type {
    position: absolute;
    right: -100px;
    top: 0px;
    width: 60px;
    height: 60px;
    border: 1px solid #c9cccf;
    border-radius: 5px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.double_img_block {
    display: flex;
}
.wrap_img_and_link {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 440px;
}
.wrap_img_and_link .preview_container_img {
    max-width: 325px;
}
.wrap_img_and_link .horizontal_upload_image {
    height: 100%;
}
.all_chose_look {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    cursor: pointer;
}
.delete_look_img::before {
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    height: 2px;
    width: 35px;
    background-color: black;
    transform: translate(-50%,-50%) rotate(-45deg);
}
.delete_look_img::after {
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    height: 2px;
    width: 35px;
    background-color: black;
    transform: translate(-50%,-50%) rotate(45deg);
}

.choose_look_img_up {

}
.choose_look_img_up:before {
    position: absolute;
    content: "";
    top: 50%;
    left: 39%;
    height: 2px;
    width: 24px;
    background-color: black;
    transform: translate(-50%,-50%) rotate(-45deg);
    border-radius: 5px;
}
.choose_look_img_up:after {
    position: absolute;
    content: "";
    top: 50%;
    left: 63%;
    height: 2px;
    width: 24px;
    background-color: black;
    transform: translate(-50%,-50%) rotate(45deg);
    border-radius: 5px;
}
.choose_look_img_down{

}
.choose_look_img_down:before {
    position: absolute;
    content: "";
    top: 50%;
    left: 39%;
    height: 2px;
    width: 24px;
    background-color: black;
    transform: translate(-50%,-50%) rotate(45deg);
    border-radius: 5px;
}
.choose_look_img_down:after {
    position: absolute;
    content: "";
    top: 50%;
    left: 63%;
    height: 2px;
    width: 24px;
    background-color: black;
    transform: translate(-50%,-50%) rotate(-45deg);
    border-radius: 5px;
}
.double_img_look_wrapper {
    min-height: 500px;
    width: 100%;
    height: 100%;
    margin-top: 20px;
    border: 1px solid #c9cccf;
    border-radius: 5px;
    padding: 15px;
    justify-content: space-between;
    position: relative;
}
