.trend_page_manager {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;

}

.add_new_trend_title {
    display: flex;
    width: 100%;
    height: 76px;
    padding: 15px 20px;
    margin-top: 20px;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.03em;
}
.add_new_trend_container {
    height: 100%;
    width: 100%;
    padding: 18px;
    display: flex;
    flex-direction: column;
}

.trend_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;

}

.trend_wrapper_content_area {
    width: 100%;
    padding: 0px 10px;
    display: flex;
    background-color: #fff;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}

.input_wrapper_add_trend {
    display: flex;
    align-items: center;
    height: 50px;
    margin-top: 40px;
}
.add_trend_input_click {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 120px;
    border: 1px solid #999999;
    border-radius: 5px;
    margin-left: 20px;
    cursor: pointer;
}
.add_trend_input_click:hover {
    background-color: #999999;
    transition: background-color 0.4s ease 0s;
}

.inp_text_trend {
    border: 1px solid gray;
    display: flex;
    height: 50px;
    padding-left: 10px;
    font-size: 25px;
    font-weight: 600;
    max-width: 200px;
}
.message_trend {
    display: flex;
    height: 50px;
    align-items: center;
}
.message_trend {
    font-weight: 600;
    font-size: 13px;
    margin-left: 40px;
    letter-spacing: 0.03em;
}
.wrapper_settings_trend {
    height: 100%;
    width: 100%;
    padding: 18px;
    display: flex;

}
.settings_trend_controller {
    display: flex;
    width: 100%;
    padding: 0px 25px;
    background-color: #fff;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 200px;
    flex-direction: column;
}
.text_info {
    margin-top: 20px;
    font-weight: 500;
    max-width: 50%;
    display: flex;
}
.go_actual_position {
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    border: 1px solid black;
    cursor: pointer;
    color: black;
    font-weight: 600;
}
.go_actual_position:hover {
    background-color: black;
    color: white;
    font-weight: 600;
}
.valid_text_st_trend {
    margin: 20px;
    display: flex;
    color: green;
    font-weight: 600;
}