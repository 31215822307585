.sitemap_wrapper {
    margin-left: 50px;
    margin-bottom: 50px;
}
.sitemap_wrapper div {
    margin-bottom: 5px;
}
.sitemap_wrapper a {
    text-decoration: none;
    color: black;
}

.sitemap_wrapper a:hover {
    text-decoration: underline;
}