.page_favorites_non_auth {
    width: 100%;
    height: 100%;
    /*margin-top: 96px;*/
}

.favorites_not_authorized_container {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1400px;
    padding: 0px 40px;
    width: 100%;
    height: 100%;
}
.favorites_not_authorized {
    display: flex;
    align-items: flex-start;
    margin-top: 70px;
    cursor: pointer;
}
.favorites_container_svg {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    /*padding-left: 30px;*/
}
.favorites_text_usercabinet {
    width: 210px;
    height: 49px;
    left: 40px;
    top: 164px;
    background: #1A1A1A;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
}
.favorites_text_usercabinet:hover {
    background: #ffffff;
    color: #000000;
    border: 1px solid black;
    transition: background 0.4s ease 0s, color 0.4s ease 0s;
}
.favorites_not_user_block {
    width: 25px;
    height: 24px;
    margin-right: 10px;
}

.favorites_not_user_block svg {
    cursor: pointer;
    height: 100%;
    width: 100%;

}
.product_favorites_not_auth {
    display: flex;
    max-width: 940px;
    width: 100%;
    flex-wrap: wrap;
}