.modalMaps_child {
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    box-shadow: 0px 0px 40px rgb(0 0 0 / 50%);
    background: #FFFFFF;

}
.modalMaps {
    position: fixed;
    max-width: 850px;
    width: 100%;
    top: 50%;
    z-index: -1;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.0s ease 0s;
    left: -100%;
    padding: 10px;
}
.modalMaps.active {
    z-index: 15;
    opacity: 1;
    transition: opacity 0.6s ease 0.4s;
    left: 50%;
}
.container_maps_modal {

}
.maps_modal_close_parents {
    display: flex;
    justify-content: space-between;
    position: relative;
    padding: 5px;
}
.city_modal_maps {
    font-size: 18px;
    font-weight: 700;
}
.maps_modal_close {
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.maps_modal_close::before {
    content: " ";
    position: absolute;
    width: 25px;
    height: 2px;
    transform: rotate(-45deg);
    background-color: #000000;
    top: 15px;
    right: 2px;
}
.maps_modal_close::after {
    content: " ";
    position: absolute;
    width: 25px;
    height: 2px;
    transform: rotate(45deg);
    background-color: #000000;
    top: 15px;
    right: 2px;
}
.modal_maps_iframe {
    height: 500px;
    padding: 10px;
}