.city_changer_wrapper {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    margin: 0 auto;
    padding: 10px;
}

.search_city_field_changer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #c9cccf;
    font-weight: 600;
    font-size: 12px;
    padding: 5px 5px;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.one_city_change_item {
    margin-top: 5px;
    display: flex;
    align-items: center;
    font-weight: 500;
    cursor: pointer;
    padding-left: 10px;
    font-size: 14px;
    height: 30px;
    min-height: 30px;
}
.one_city_change_item:hover {
    background-color: lightgrey;
}

.city_list {

    margin-top: 20px;
    border: 1px solid #c9cccf;
    font-weight: 600;
    font-size: 12px;
    padding: 5px 5px;
    border-radius: 5px;


}
.city_changer_scroll_container {
    height: 300px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
}

.target_city_vacancy {
    font-weight: 500;
    font-size: 14px;
}