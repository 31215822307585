.other_container_for_images {
    display: flex;
    width: 100%;
    height: 100%;
    border: 1px solid #c9cccf;
    border-radius: 5px;
    position: relative;
    min-height: 300px;
    max-width: 1108px;
}

.file_multiple_uploader {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 0;
    cursor: pointer;
    padding: 5px;

}

.image_multiple_container {
    display: flex;
    height: 100%;
    width: 100%;
    padding: 15px;
    cursor: move;
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
}

.one_image_multiple {
    margin-left: 20px;
    height: 200px;
    width: 100%;
    max-width: 140px;
    display: flex;
    justify-content: center;
    z-index: 55;
    position: relative;
    margin-top: 20px;
}
.file_upload_text {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 35px;
    font-weight: 600;
    letter-spacing: 0.1em;
    color: rgba(211, 211, 211, 0.7);
    text-align: center;

}
.other_container_for_images:hover .file_upload_text {
    background-color: rgba(211, 211, 211, 0.21);

}
.one_image_multiple img {

    width: 100%;
    height: 100%;
}
.delete_one_image_in_multiple {
    display: flex;
    width: 100%;
    position: absolute;
    text-align: center;
    justify-content: center;
    align-items: center;
    background-color: rgba(230, 145, 145, 0.75);
    z-index: 10;
    cursor: pointer;
    height: 35px;
}
.delete_one_image_in_multiple:hover {
    background-color: black;
    color: white;
}