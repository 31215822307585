.stock-trends__slider-header {
	position: static;
	display:flex;
	justify-content: space-between;
	align-items: center;
	margin-top:1.5rem;
	margin-bottom:1.5rem;
}
.stock-trends__slider-title {
	font-size:30px;
}