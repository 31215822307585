
.one_size_wrapper {
    padding: 20px;
    border: 1px solid lightgrey;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 20px 15px;
    width: 100%;
    display: flex;
}
.one_size_admin {
    display: flex;
    width: 60px;
    height: 60px;
    border: 2px solid gray;
    /*margin-left: 25px;*/
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    text-align: center;
    font-weight: 600;
}

.delete_size_button {
    margin-left: 30px;
    padding: 10px 30px;
    border: 1px solid #CE3F3F;
    color: #CE3F3F;
    font-weight: 600;
    cursor: pointer;
    user-select: none;
}
.delete_size_button:hover {
    background-color:#df7272;
    color: black;
}
.size_delete_response_server {
    display: none;
    margin-left: 30px;
}

.size_delete_response_server.active {
    display: flex;
}

.link_size_go_product {
    display: flex;
    padding: 0px 10px;
    height: 30px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: black;
    font-weight: 600;
    border: 1px solid #c9cccf;
    border-radius: 5px;
    margin-left: 20px;
    margin-top: 10px;

}
.products_size_go {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}
.one_size_position_field_container {
     display: flex;
     flex-direction: column;
    margin-left: 20px;
    height: 100%;
}
.size_field_pos {
    border: 1px solid black;
    height: 30px;
    margin-top: 15px;
    padding-left: 10px;
    font-weight: 600;
}
.label_position_field {
    font-weight: 600;
    font-size: 12px;
}

.update_information_this_size {
    display: flex;
    margin-left: 20px;
    align-items: center;
}

.update_one_size_button {
    display: flex;
    padding: 10px 30px;
    margin-left: 40px;
    border: 1px solid black;
    align-items: center;
    cursor: pointer;
    font-weight: 600;
}

.update_one_size_button:hover {
    background-color: black;
    color: white;
}