.vacancy_admin_page {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 30px;
    flex-direction: column;

}
.vacancy_page_title {
    font-weight: 600;
    font-size: 22px;
}

.vacancy_content_wrapper_adm {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-top: 20px;
    background-color: #fff;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    padding: 10px;
}

.vacancies_controller {
    display: flex;
    width: 100%;
    height: 50px;
    margin-top: 20px;
    background: whitesmoke;
    justify-content: space-between;
    align-items: center;
}
.add_new_vacancy_btn {
    display: flex;
    padding: 5px 10px;
    border: 1px solid black;
    font-size: 14px;
    margin-right: 20px;
    background: white;
    font-weight: 600;
    cursor: pointer;
    user-select: none;
}
.add_new_vacancy_btn:hover {
    color:white;
    background: black;
}
.vacancy_wrapper_content {
    display: flex;
    flex-wrap: wrap;
}

.one_vacancy_adm {
    display: flex;
    margin-top: 20px;
    width: 100%;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    padding: 10px;
    position: relative;
    flex-direction: column;
}
.fields_vacancy_wrapper {
    display: flex;
    height: 100%;
    width: 100%;
}
.fields_container_vacancy {
    display: flex;
    flex-direction: column;
    max-width: 50%;
    width: 100%;
    height: 100%;

}

.vacancy_field_s {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #c9cccf;
    font-weight: 600;
    font-size: 12px;
    padding: 5px 5px;
    border-radius: 5px;
    margin-top: 5px;
}
.one_field_vacancy_wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}
.one_field_name_label {
    font-weight: 600;
    font-size: 12px;
}

.top_line_vacancy {
    display: flex;
    height: 50px;
    align-items: center;
    justify-content: space-between;
}

.btn_vacancy {
    padding: 5px 10px;
    border: 1px solid black;
    cursor: pointer;
    font-weight: 600;
    font-size: 12px;
    margin-right: 20px;
}
.btn_vacancy.trig {
    animation: trigerSaveVacancy 2s ease infinite;
}

@keyframes trigerSaveVacancy{
    0% {
        background: white;
        color: black;
    }
    50% {
        background: black;
        color: white;
    }
    100% {
        background: white;
        color: black;
    }

}

.city_changer_wrapper {
    display: flex;
    /*background-color: #df7272;*/
    width: 100%;
    height: 100%;
}

.vacancy_content_fields {
    display: flex;
    margin-top: 10px;
    width: 100%;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    padding: 10px;
    min-height: 200px;
}

.vacancy_fields_content {
    max-width: 50%;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.vacancy_content_image_field {
    max-width: 250px;
    width: 100%;
    height: 175px;
    display: flex;

}
.vacancy_horizontal_wrap_container {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: flex-end;
    align-items: center;
}
.save_content_vacancy {
    display: flex;
    height: 40px;
    width: 500px;
    margin: 0 auto;
    margin-top: 20px;
    background: white;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    font-weight: 600;
    cursor: pointer;
}
.save_content_vacancy:hover {
    box-shadow: 0px 0px 4px 0px rgb(34 60 80 / 80%);
}

.save_content_vacancy.trig {
    animation: trigerSaveVacancy 2s ease infinite;
}