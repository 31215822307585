.footer_contact_us {
    max-width: 300px;
    display: flex;
    flex-direction: column;
    /*margin-right: 40px;*/
    margin-left: 60px;
}
.social_contact_us {
    display: flex;
    flex-direction: column;
    width: max-content;
}

.store_dowload img{
    width: 75px;
}
.footer_mobile_download{
    width: 210px;
    text-align: center;
    margin-left: 0;
}
.qr_image{
    width: 135px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.social_contact_us_seti_text {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    /*text-transform: uppercase;*/
    color: #1A1A1A;
}

.contact_us_link_wrapper {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
}

.mobile_store_list{
    width: fit-content;
    margin: auto;
    gap: 10px;
}
.social_link_us {
    margin-right: 10px;
    margin-top: 10px;
    width: 30px;
    height: 30px;
    display: flex;
}
.social_link_us:last-child {
    margin-right: 0px;
}


.social_link_us svg{
    width: 30px;
    height: 30px;
}
.social_link_us:hover svg {
    box-shadow: 0px 0px 20px rgb(0 0 0 / 15%);
    border-radius: 10px;
    border: 3px solid black;
    transition: border 0.1s ease 0.2s;
}
.social_link_us:hover rect{
    fill: white;
}
.social_link_us:hover path{
    fill: black;
}
.social_link_us:hover path.path_icon{
    fill: white;
}


.mesend_and_tel_contact_us {
    margin-top: 30px;
    display: flex;
    flex-direction: column;

}
.mesenj_telephone_text {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    /*text-transform: uppercase;*/
    color: #1A1A1A;
}
.mesenj_icons_wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 10px;
}
.number_contact_us {
    display: flex;
    height: 35px;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    color: #1A1A1A;
    margin-top: 10px;
    margin-right: 20px;
    text-decoration: none;
}
.number_contact_us:last-child {
    margin-right: 0px;
}
.number_contact_hover {
    border-bottom: 1px solid transparent;
    transition: border-bottom 0.8s ease 0s;
}
.number_contact_hover:hover {
    border-bottom: 1px solid #1A1A1A;
    transition: border-bottom 0.8s ease 0s;
}



@media (max-width: 1400px) {
    .footer_contact_us {
        /* margin-top: 30px; */
        margin-left: 10px;
    }
}
@media (max-width: 1024px) {
    .footer_contact_us {
        margin-top: 40px;
        flex-direction: unset;
        max-width: 542px;
        /*margin-left: 95px;*/
        margin-left: 0px;
        grid-column: span 3;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .mesend_and_tel_contact_us {
        margin-top: 0px;
        margin-left: 0;

    }
    .mesenj_icons_wrapper {
        max-width: 300px;
    }

}
@media (max-width: 850px) {
    /*.footer_contact_us {*/
    /*    margin-left: 50px;*/
    /*}*/
    /*.mesend_and_tel_contact_us {*/
    /*    margin-left: 50px;*/
    /*}*/
}
@media (max-width: 767px) {
    .footer_contact_us {
        margin-left: 0px;
        margin-top: 40px;
        flex-direction: column;
        display: flex;
        align-items: flex-start;
    }
    .qr_image{
        display: none;
    }
    .footer_mobile_download{
        text-align: left;
    }
    .store_dowload img {
        width: 110px;
    }
    .mobile_store_list{
        margin-top: 15px;
    }
    .mesend_and_tel_contact_us {
        margin-left: 0px;
        margin-top: 40px;
    }
    .social_contact_us_seti_text {
        font-size: 14px;
    }
    .mesenj_telephone_text {
        font-size: 14px;
    }
    .social_link_us {
        margin-right: 35px;
    }
    .number_contact_us {
        margin-right: 35px;
    }
    .contact_us_link_wrapper {
        justify-content: center;
    }
    .mesenj_icons_wrapper {
        justify-content: center;
    }
}

@media (max-width: 428px) {
    .footer_contact_us {
        margin-top: 35px;
    }
    .mesend_and_tel_contact_us {
        margin-top: 35px;
    }
   .social_link_us {
       margin-right: 20px;
   }
   .number_contact_us {
       margin-right: 20px;
   }
    .contact_us_link_wrapper {
        justify-content: flex-start;
    }
    .mesenj_icons_wrapper {
        justify-content: flex-start;
    }
}