.home_OneAndFour {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;

}
.home_OneAnd_Four_wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
}
.OneAndFour {
    width: calc(50% - 5px);
    min-height: 798px;

}

.OneAndFour.left {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;

}
.OneAndFour.right img {
    width: 100%;
    height: 100%;
}
.smallBlockFour {
    width: calc(100% / 2 - 5px);
    height: calc(100% / 2 - 5px);
}
.smallBlockFour.top {
    align-self: flex-start;
}
.smallBlockFour.bottom {
    align-self: flex-end;
}



.goToPublish {
    position: absolute;
    left: 50%;
    top: 50%;
    display: flex;
    max-width: 174px;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: bold;
    transform: translate(-50%, -50%);
    text-align: center;
    color: transparent;
    transition: color 0.4s ease 0s;
    pointer-events: none;
}
.home_OneAndFour a {
    position: relative;
}
.home_OneAndFour a img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.home_OneAndFour a:hover .goToPublish {
    color: #FFFFFF;
    transition: color 0.4s ease 0s;
}


@media (max-width: 1024px) {
    .home_OneAndFour {
        margin-top: 10px;
    }
    .home_OneAnd_Four_wrap {
        flex-direction: column;
    }
    .OneAndFour {
        width: 100%;
        min-height: 100%;
    }
    .smallBlockFour.bottom {
        margin-top: 10px;
    }
    .OneAndFour.right {
        margin-top: 10px;
    }
}
@media (max-width: 768px) {
    .goToPublish {
        font-size: 16px;
    }
}
@media (max-width: 428px) {

}
