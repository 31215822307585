.adm_page_home {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 30px;
    flex-direction: column;
}
.title_adm_home {
    font-weight: 600;
    font-size: 22px;
}
.content_wrapper_home_adm {

}

.seo_content_home{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    background-color: #fff;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    padding: 10px;
}
.container_banner_home {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    background-color: #fff;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    padding: 10px;
}
.img_home_wrap  {
    /*width: 100%;*/
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    align-items: center;
}
.name_banner{
    font-weight: 600;
    font-size: 22px;
    padding: 20px;
    text-align: center;
}
.switch_and_arrows_home {
    display: flex;
    flex-direction: column;
}
.one_banner_wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1.2fr 1.8fr);
    width: 100%;
    padding-bottom: 20px;
    border-bottom: 1px solid black;
}
.adm_page_home_switch {
    display: flex;
    gap: 10px;
    max-width: max-content;
    justify-content: space-between;
}
.switch_blocks {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #757575;
    cursor: pointer;
}
.switch_blocks.active {
    font-weight: 500;
    color: #1A1A1A;
    padding-bottom: 10px;
    border-bottom: 2px solid black;
}
.switch_blocks.trends {
    margin-left: 30px;
}
.fields_banner_admhome {
    display: flex;
    flex-direction: column;
    width: 90%;
}
.banner_fields_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
}
.hex_container {
    display: flex;
    flex-direction: row;
    width: 100%;
}
.banner_hex_preview {
    display: flex;
    width: 25px;
    min-width: 25px;
    margin-top: 5px;
    margin-left: 10px;
    height: 25px;
    border-radius: 50%;
}
.banner_fields_container textarea {
    height: 150px;
    resize: none;
}
.home_field_label_name {
    font-weight: 600;
    font-size: 12px;
}
.home_adm_field {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #c9cccf;
    font-weight: 600;
    font-size: 12px;
    padding: 5px 5px;
    border-radius: 5px;
    margin-top: 5px;
}

.home_adm_field.hex{
    width: 50%;
}

.img_banner_admhome {
    display: flex;
    width: 100%;
}
.one_img_block {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 200px;
    align-items: center;
    margin-top: 20px;
}
.one_img_block .preview_container_img {
    max-width: 250px;
}
.photo_resolution {
    font-size: 12px;
}

.activation_unit {
    display: flex;
    align-items: flex-end;
    width: 100%;
    padding: 10px;
}
.activation_wrapper {
    display: flex;
    margin-left: 20px;
    margin-top: 10px;
    align-items: center;
    width: 100%;
}
.inclusion_new_products {

}
.text_block_home {
    display: flex;
    justify-content: space-between;
}
.left_block_text {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    width: 49%;
}
.add_button_home {
    display: flex;
    padding: 5px 10px;
    border: 1px solid black;
    border-radius: 5px;
    max-width: 200px;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
    cursor: pointer;
    transition: color 0.4s ease 0s, background 0.4s ease 0s;
    margin-top: 10px;
}
.sale_and_trend {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.one_block_content {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px 10px;
}
.wrap_scroll_trends_sale {
    margin-top: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.title_sale_trends {
    max-width: 400px;
    width: 100%;
}
.one_trading_block {
    display: flex;
}



.text_field_block {
    width: 100%;
}
.text_field_wrapper {
    display: flex;
}
.del_block_home {
    margin-top: 10px;
    cursor: pointer;
}
.icons_block_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
}
.one_icons_block {
    display: flex;
    flex-direction: column;
    max-width: 200px;
}
.icons_fields {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}



.save_button_home_content {
    display: flex;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    height: 42px;
    background-color: #fff;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    margin-top: 30px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: 600;
    text-transform: uppercase;
}

.save_button_home_content:hover {
    color: white;
    background-color: black;
    transition: color 0.8s ease 0s, background-color 0.8s ease 0s;
}
.save_button_home_content.active {
    animation: trigerHomeManager 2s ease infinite;
}


@keyframes trigerHomeManager {
    0% {
        background: white;
        color: black;
    }
    50% {
        background: black;
        color: white;
    }
    100% {
        background: white;
        color: black;
    }

}
