.category_wrapper_page {
    padding: 30px;
    display: flex;
    width: 100%;
    height: 100%;
}

.page_container_category {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
}

.first_category_controller {
    background-color: #fff;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    width: 100%;
    display: flex;
    min-height: 200px;
    padding: 20px;
    flex-direction: column;
}

.catalog_manager_field_wrap {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.category_field_label {
    display: flex;
    font-weight: 500;
    font-size: 12px;
}

.category_fields_vac {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #c9cccf;
    font-weight: 600;
    max-width: 100%;
    width: 100%;
    font-size: 12px;
    padding: 5px 5px;
    border-radius: 5px;
    margin-top: 5px;
}

.left_wrapper_category_controller {
    max-width: 50%;
    width: 100%;
}

.add_new_category_button {
    display: flex;
    padding: 5px 10px;
    border: 1px solid black;
    width: 150px;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
    cursor: pointer;
}

.add_new_category_button:hover {
    background: black;
    color: white;
}

.save_and_error {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.error_category_message {
    display: flex;
    margin-left: 20px;
    font-weight: 500;
}

.cheme_vision_category {
    background-color: #fff;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    width: 100%;
    padding: 10px;
    display: flex;
    min-height: 200px;
    padding: 20px;
    flex-direction: column;
    margin-top: 30px;
}

.heading_page_category {
    letter-spacing: 0.04em;
    font-size: 16px;
    font-weight: 600;
}
.ct_manager_pam {
    margin-top: 10px;
}
.cheme_wrapper_category {
    display: flex;
    width: 100%;
}

.all_category_redaction_wrapper {
    background-color: #fff;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    width: 100%;
    padding: 10px;
    display: flex;
    min-height: 200px;
    padding: 20px;
    flex-direction: column;
    margin-top: 30px;
}

.one_category_redaction_wrapper {
    margin-top: 20px;
    background: white;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 2px;
    width: 100%;
    padding: 30px 10px;
    align-items: center;
    flex-wrap: wrap;
}

.one_category_wrap_field {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}

.one_category_wrap_field .is_secret_checkbox {
    display: flex;
    flex-direction: row;
}

.is_secret_checkbox .field_info {
    display: block;
}

.one_category_wrap_field.id {
    width: 30px;
    justify-content: center;
    align-items: center;
}

.one_category_heading_item {
    font-size: 13px;
    font-weight: 600;
}

.one_category_list_input {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #c9cccf;
    font-weight: 600;
    max-width: 100%;
    width: 100%;
    font-size: 12px;
    padding: 5px 5px;
    border-radius: 5px;
    margin-top: 5px;
}

.one_category_redaction_wrapper {
    display: flex;
    position: relative;
}

.one_category_heading_item {
    display: flex;
    color: #484747;
}

.id_category_one_item {
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    margin-top: 7px;
}

.save_category_button {
    margin-left: 40px;
    padding: 7px 13px;
    border: 1px solid black;
    font-weight: 600;
    cursor: pointer;
    border-radius: 10px;
}

.save_category_button.active {
    animation: trigerCategoryManager 2s ease infinite;
}

.save_category_button:hover {
    background-color: black;
    color: white;
}

.error_category_text {
    font-weight: 600;
    margin-left: 30px;
}

.delete_category_button {
    margin-left: 40px;
    padding: 7px 13px;
    font-weight: 600;
    cursor: pointer;
    border: 1px solid #CE3F3F;
    color: #CE3F3F;
    border-radius: 10px;
}

.delete_category_button:hover {
    background-color: black;
    color: white;
    border: 1px solid black;
}


.cheme_vision_category {

}
.cheme_vision_category ul {
    margin-left: 30px;
    margin-top: 10px;
}

.title_and_description_category_wrapper {
    display: flex;
    width: 100%;
    margin-top: 40px;
    flex-direction: column;
}

.description_and_image_category_wrapper {
    display: flex;
    width: 100%;
    margin: 40px;
    flex-direction: row;
}
.one_img_category_block {
    display: flex;
    flex-direction: column;
    min-width: 200px;
    min-height: 250px;
    align-items: center;
    margin: 40px;
}
.one_img_category_block .preview_container_img {
    max-width: 250px;
}
textarea.one_category_list_input {
    width: 100%;
    height: 150px;
}

@keyframes trigerCategoryManager {
    0% {
        background: white;
        color: black;
    }
    50% {
        background: black;
        color: white;
    }
    100% {
        background: white;
        color: black;
    }

}