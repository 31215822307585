.adm_refund_page {
    width: 100%;
    height: 100%;
    padding: 30px;
}
.title_and_button {
    display: flex;
    justify-content: space-between;
}
.title_refund_page {
    font-weight: 600;
    font-size: 16px;
    color: #000000;
}
.download_list_refund {
    padding: 10px;
    border: 1px solid black;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #050505;
    text-decoration:none;
    cursor: pointer;
}
.download_list_refund:hover {
    background: black;
    color: white;
    transition: background 0.4s ease 0s,  color 0.4s ease 0s;
}
.list_refund_wrapper {
    background: #FFFFFF;
    border: 1px solid #EBEBEB;
    border-radius: 20px;
    padding-bottom: 40px;
}
.returns-list {
    overflow-x: auto;
    padding: 22px 30px;
    display: flex;
    justify-content: space-between;
}
.returns-list_child {
    display: flex;
    align-items: center;
    width: 100%;
}
.returns-list-item__field {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}


.returns-list-item.returns-list-item--head:hover {
    background-color: #fff;
}

.returns-list-item--head .returns-list-item__field {
    height: 100%;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sort_returns {
    font-weight: 500;
    font-size: 12px;
    color: #000000;
    position: relative;
    cursor: pointer;
}
.sort_returns span {
    padding-right: 25px;
}
