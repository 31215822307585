.wrapper_fio_inputs {
    display: flex;
    width: 100%;
    /*height: 60px;*/
    flex-direction: column;
    margin-top: 18px;
}
.wrapper_fio_inputs:first-child {
    margin-top: 40px;
}
.title_input_lk {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #1A1A1A;
}
.required_field {
    position: relative;
}
.required_field:after {
    content:" *";
    color: #CE3F3F;
    top: -5px;
    position: absolute;
}
.fio_fields {
    width: 100%;
    height: 44px;
    padding: 5px;
    display: flex;
    align-items: center;
    border: 1px solid #D7D6D6;
    font-size: 17px;
    letter-spacing: 0.03em;
    font-weight: 500;
    margin-top: 7px;
}
.fio_fields.error {
    border: 1px solid #CE3F3F;
}

.error_block_text {
    height: 0px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #CE3F3F;
    font-size: 11px;
    /*text-transform: uppercase;*/
    font-weight: 600;
    letter-spacing: 0.03em;
    opacity: 0;
}
.error_block_text.error {
    opacity: 1;
    transition: opacity 0.4s ease 0s;
    height: auto;
}
.fio_fields:focus-visible {
    outline: none;
}

.fio_fields::placeholder {
    font-weight: 500;
    font-size: 16px;
    color: #757575;
}