
.wrapper_for_mini_count_controller_wp_component {
    display: flex;
    height: 38px;
    width: 137px;
    margin-left: 20px;
    background: #FFFFFF;
    border: 1px solid #C9C9C9;
    border-radius: 5px;
}
.wrapper_for_mini_count_controller_component {
    display: flex;
    height: 100%;
    width: 33.33%;
    flex-grow: 1;
    cursor: pointer;
    transition: background-color 0.4s ease 0s;
    position: relative;
    justify-content: center;
    align-items: center;
}
.wrapper_for_mini_count_controller_component.min {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.wrapper_for_mini_count_controller_component.min:before {
    content: "";
    position: absolute;
    width: 2px;
    height: 13px;
    background-color: #8d8d8d;
    right: 20px;
    top: 16px;
    border-radius: 2px;
    transform: rotate(-45deg);
}
.wrapper_for_mini_count_controller_component.min:after {
    content: "";
    position: absolute;
    width: 2px;
    height: 13px;
    background-color: #8d8d8d;
    right: 20px;
    top: 8px;
    border-radius: 2px;
    transform: rotate(45deg);
}
.wrapper_for_mini_count_controller_component.center {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    -webkit-user-select: none;
    user-select: none;
}



.wrapper_for_mini_count_controller_component.max {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.wrapper_for_mini_count_controller_component.max:before {
    content: "";
    position: absolute;
    width: 2px;
    height: 13px;
    background-color: #8d8d8d;
    left: 20px;
    top: 16px;
    border-radius: 2px;
    transform: rotate(-135deg);
}
.wrapper_for_mini_count_controller_component.max:after {
    content: "";
    position: absolute;
    width: 2px;
    height: 13px;
    background-color: #8d8d8d;
    left: 20px;
    top: 8px;
    border-radius: 2px;
    transform: rotate(135deg);
}

.wrapper_for_mini_count_controller_component:hover {
    background-color: black;
    color: white;
}
.wrapper_for_mini_count_controller_component.center:hover {
    background-color: white;
    color: black;
}
.wrapper_for_mini_count_controller_component:hover:after{
    background-color: white;
}
.wrapper_for_mini_count_controller_component:hover:before{
    background-color: white;
}