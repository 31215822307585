.vacancies_page {
    height: 100%;
    width: 100%;
}
.vacancies_content {
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    align-items: center;
    /*height: 100%;*/
}
.vacancies_banner {
    width: 100%;
}
.banner_vacancies_image {
    width: 100%;
    object-fit: cover;
    height: 100%;
}

.vacancies_abs_div_content {
    max-width: 1620px;
    width: 100%;
    display: flex;
    padding: 0px 10px;
}

.vacancies_static_div_content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 40px;
}
.title_page_vacancies {
    font-weight: 500;
    font-size: 30px;
    color: #000000;
}
.vacancies_cities_container {
    max-width: 1116px;
    min-height: 121px;
    width: 100%;
    height: 100%;
}
.vacancies_city {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
}
.vacancies_title {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 100px;
    padding-bottom: 65px;
}
.vacancies_cards_content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
.vacancies_city_mobile {
    margin-top: 20px;
    display: flex;
    position: relative;
}
.container_city_active {
    padding-right: 25px;
    position: relative;
    cursor: pointer;
    margin-left: 5px;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
    border-bottom: 1px solid transparent;
}
.container_city_active:hover {
    border-bottom: 1px solid black;
    transition: border-bottom 0.6s ease 0s;
}

.container_city_active.open::before {
    position: absolute;
    content: "";
    height: 1px;
    width: 6px;
    right: 10px;
    top: 10px;
    background-color: #000000;
    transform: rotate(45deg);
}
.container_city_active.open::after {
    position: absolute;
    content: "";
    height: 1px;
    width: 6px;
    right: 6px;
    top: 10px;
    background-color: #000000;
    transform: rotate(-45deg);
}

.container_city_active.close::before {
    position: absolute;
    content: "";
    width: 0px;
    height: 0px;
}
.container_city_active.close::after {
    position: absolute;
    content: "";
    width: 0px;
    height: 0px;
}
.mob_title_city {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    cursor: pointer;
}
.mob_list_city {
    position: absolute;
    left: 55px;
    top: 0;
    border: 2px solid black;
    background: white;
    opacity: 0;
}
.mob_list_city.active {
    opacity: 1;
    transition: opacity 0.2s ease 0s;
}




@media (max-width: 1440px) {
    .vacancies_content {
        max-width: 1176px;
        margin: 0 auto;
    }

}

@media (max-width: 1024px) {
    .vacancies_abs_div_content {
        padding: 0px 40px;
    }
    .title_page_vacancies {
        font-size: 24px;
    }
    .vacancies_city_mobile {
        font-size: 14px;
    }
    .vacancies_page {
        height: auto;
    }

}

@media (max-width: 768px) {
    .vacancies_abs_div_content {
        padding: 0px 20px;
    }
    .title_page_vacancies {
        font-size: 18px;
    }
    .vacancies_city_mobile {

    }
    .mob_list_city {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }


}
