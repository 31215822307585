.root {
    border: 1px solid #000;
    background: #FFF;
    max-width: 599px;
    width: 100%;
    margin-left: auto;
    position: relative;
}

.close {
    padding: 0;
    margin: 0;
    border: 0;
    background: none;

    position: absolute;
    display: flex;
    right: 18px;
    top: 18px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.close svg {
    display: block;
    width: 100%;
    height: 100%;
}

@media all and (max-width: 1024px) {
    .root {
        margin: auto;
    }

    .close {
        width: 14px;
        height: 14px;
        top: 20px;
        right: 20px;
    }
}