.instashop_homepage {
    width: 100%;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: background 0.4s ease 0s;
    margin-top: 20px;
}
.inst_heading {
    font-size: 58px;
    color: #000000;
    font-weight: 700;
    text-transform: uppercase;
}

.inst_text {
    margin-top: 40px;
    font-size: 28px;
    color: #000000;
    font-weight: 600;
}

.instashop_homepage:hover {
    cursor: pointer;
    background: rgba(0,0,0, 30%);
    transition: background 0.4s ease 0s;
}

.dev_info {
    transition: color 0.4s ease 0s;
}

.instashop_homepage:hover .dev_info {
    color: white;
    transition: color 0.4s ease 0s;
}


@media (max-width: 768px) {
    .instashop_homepage {
        margin-top: 10px;
        min-height: 250px
    }
    .inst_heading {
        font-size: 32px;
    }
    .inst_text {
        font-size: 16px;
    }
}
@media (max-width: 4288px) {
    .instashop_homepage {
        min-height: 200px;
        text-align: center;
    }
    .inst_text {
        margin-top: 20px;
    }

}



