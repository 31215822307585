.fly_basket_step_modal {
    display: flex;
    /*padding:0px 30px 10px 30px;*/
    /*height: 100%;*/
    width: 100%;
    flex-direction: column;
    margin-top: 70px;
}
.fly_basket_heading {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #1A1A1A;
    max-width: 275px;
}

.count_items_and_clear_basket_wrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 20px;
    /*background-color: red;*/
    padding: 0px 18px 0px 40px;
}

.count_items_line {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
}
.count_items_line span {
    display: flex;
    align-items: center;
}
.counter_weight {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
}
.delete_all_products {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
    opacity: 0.5;
    text-align: right;
    display: flex;
    position: relative;
    width: auto;
    cursor: pointer;
}

.delete_all_products:after {
    content: "";
    position: absolute;
    left: 0px;
    bottom: -1px;
    height: 1px;
    width: 100%;
    opacity: 0.5;
    background-color: #000000;

}

.delete_all_products:hover:after {
    opacity: 1;
    transition: opacity 0.4s ease 0s;
}

.delete_all_products:hover {
    color: #000000;
    opacity: 1;
    transition: color 0.4s ease 0s;
}

.fly_basket_items_container {
    display: flex;
    min-height: 100px;
    height: 100%;
    width: 100%;
    padding: 12px 10px 12px 40px;
    margin-top: 18px;
    border-top: 1px solid #000000;
    /*border-bottom: 1px solid #000000;*/

}
.for_items_wrapper_non {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 30px;
    height: 100%;
}
.for_items_wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 30px;
    /*height: 100px;*/
    overflow-y: scroll;
    /*max-height: 650px;*/
}

/* SCROLLBAR */
/* Let's get this party started */
.for_items_wrapper::-webkit-scrollbar {
    width: 3px;
}
 /*Track*/
.for_items_wrapper::-webkit-scrollbar-track {
    background: transparent;
}
/* Handle */
.for_items_wrapper::-webkit-scrollbar-thumb {
    background: rgb(25,25,25);
    border: 1px solid rgb(0, 0, 0);
}

.one_fly_basket_item {
    display: flex;
    /*min-height: 242px;*/
    min-height: max-content;
    width: 100%;
    padding-bottom: 20px;
    margin-top: 20px;
    border-bottom: 1px solid #000000;
}
.one_fly_basket_item:first-child {
    margin-top: 0px;
}
.one_fly_basket_item:last-child {
    border-bottom: unset;
}

.fly_basket_image_wrapper {
    display: flex;
    max-width: 154px;
    width: 100%;
    height: 100%;
    /*background-color: #E69191;*/
}
.fly_basket_image_wrapper img {
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.fly_basket_content_container_one {
    display: flex;
    height: 100%;
    flex-grow: 1;
    margin-left: 21px;
    flex-direction: column;
}

.fly_basket_one_container_heading {
    /*min-height: 44px;*/
    min-height: max-content;
    display: flex;
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #1A1A1A;
}
.fly_basket_content_artikul {
    margin-top: 18px;
    display: flex;
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #757575;
}

.fly_basket_content_line_size {
    display: flex;
    margin-top: 18px;
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
}
.props_line_fly_basket_color {
    margin-left: 40px;
}
.props_line_fly_basket {
    margin-left: 20px;
    display: flex;
}
.fly_basket_content_line_size.counter {
    margin-top: 17px;
    align-items: center;
}
.count_one_product_controller {
    display: flex;
    height: 25px;
    width: 91px;
    margin-left: 20px;
    margin-right: 10px;
    background: #FFFFFF;
    border: 1px solid #C9C9C9;

    /*border-radius: 2px;*/
}
.count_one_item_controller {
    display: flex;
    height: 100%;
    width: 33.33%;
    flex-grow: 1;
    cursor: pointer;
    transition: background-color 0.4s ease 0s;
    position: relative;
}
.count_one_item_controller.min:after {
    content: "";
    position: absolute;
    width: 2px;
    height: 9px;
    background-color: #8d8d8d;
    right: 15px;
    top: 5px;
    border-radius: 2px;
    transform: rotate(45deg);
}
.count_one_item_controller.min:before {
    content: "";
    position: absolute;
    width: 2px;
    height: 9px;
    background-color: #8d8d8d;
    right: 15px;
    top: 10px;
    border-radius: 2px;
    transform: rotate(-45deg);
}

.count_one_item_controller.max:after {
    content: "";
    position: absolute;
    width: 2px;
    height: 9px;
    background-color: #8d8d8d;

    left: 15px;
    top: 5px;
    border-radius: 2px;
    transform: rotate(135deg);
}
.count_one_item_controller.max:before {
    content: "";
    position: absolute;
    width: 2px;
    height: 9px;
    background-color: #8d8d8d;
    left: 15px;
    top: 10px;
    border-radius: 2px;
    transform: rotate(-135deg);
}


.count_one_item_controller:hover:after {
    background-color: white;
    transition: background-color 0.4s ease 0s;
}
.count_one_item_controller:hover:before {
    background-color: white;
    transition: background-color 0.4s ease 0s;
}

.count_one_item_controller.count {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
    height: 100%;
    font-weight: 700;
    font-size: 10.6277px;
    line-height: 13px;
    user-select: none;
}
.count_one_item_controller.count:hover {
    background-color: white;
}


.count_one_item_controller:hover {
    background-color: black;
    transition: background-color 0.4s ease 0s;
}
.fly_basket_count_price {
    display: flex;
    align-items: center;
    margin-top: 18px;
}
.count_one_item_fly_basket {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #1A1A1A;
}
.fly_basket_multipler {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-left: 5px;
}
.fly_basket_price_one {
    display: flex;
    margin-left: 7px;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #1A1A1A;
}
.fly_basket_price_one.old_price {
    margin-left: 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-decoration-line: line-through;
    color: #757575;
}

.fly_basket_sell_price {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #1A1A1A;
}
.wrap_promo_and_price {
    display: flex;
    flex-direction: column;
    padding: 0px 40px 40px 40px;
    border-top: 1px solid #000000;
    background-color: white;
    z-index: 99;
}
.fly_basket_non_sell_price {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.04em;
    text-decoration-line: line-through;
    color: #757575;
}
.result_price_fly_basket {
    display: flex;
    /*height: 44px;*/
    width: 100%;
    margin-top: 15px;
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: column;
}
.fly_basket_final_price {
    margin-top: 8px;
}
.fly_basket_result_text {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #757575;
}
.fly_basket_result_price {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.04em;
    color: #000000;
    margin-left: 18px;
}

.fly_basket_place_an_order {
    display: flex;
    width: 100%;
    height: 50px;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    background-color: black;
    margin-top: 18px;
    cursor: pointer;
    font-style: normal;
}
.fly_basket_trash_wrapper {
    margin-left: auto;
    /*height: 22px;*/
    /*width: 19px;*/
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    cursor: pointer;
    position: relative;
}
.fly_basket_trash_wrapper:hover {
    color: #000000;
    opacity: 0.5;
    transition: color 0.4s ease 0s;
}
.fly_basket_trash_wrapper:after {
    content: "";
    position: absolute;
    left: 0px;
    bottom: -1px;
    height: 1px;
    width: 100%;
    background-color: #000000;
}
.fly_basket_trash_wrapper:hover:after {
    background-color: #000000;
    opacity: 0.5;
    transition: background-color 0.4s ease 0s;
}


@media (max-width: 1600px) {
    .fly_basket_items_container {
        min-height: 100px;
    }
    .wrap_promo_and_price {
        padding: 0px 40px 20px 40px;
    }
}


@media (max-width: 1024px) {

    .count_items_and_clear_basket_wrap {
        margin-top: 40px;
    }
    .fly_basket_step_modal {
        margin-top: 35px;
    }
    .delete_all_products {
        font-size: 14px;
    }
    .counter_weight {
        margin-left: 10px;
    }
    .fly_basket_items_container {
        padding: 12px 10px 12px 40px;
        min-height: 100px;
    }

    /*.count_one_item_controller.count:hover {*/
    /*    background-color: unset;*/
    /*}*/
    .count_one_item_controller.max:hover:after {
        background: #8d8d8d;
    }
    .count_one_item_controller.max:hover:before {
        background: #8d8d8d;
    }
    .count_one_item_controller.min:hover:after {
        background: #8d8d8d;
    }
    .count_one_item_controller.min:hover:before {
        background: #8d8d8d;
    }

    .count_one_item_controller:hover:after {
        background-color: unset;
    }
    .count_one_item_controller:hover:before {
        background-color: unset;
    }
    .count_one_item_controller:hover {
        background-color: unset;
    }

}

@media (max-width: 768px) {
    .count_items_and_clear_basket_wrap {
        margin-top: 15px;
    }
    .count_items_and_clear_basket_wrap {
        padding: 0px 10px;
    }
    .count_items_line {
        font-size: 12px;
    }
    .counter_weight {
        font-size: 12px;
        line-height: 16px;
    }
    .delete_all_products:after {
        bottom: 2px;
    }
    .fly_basket_image_wrapper {
        max-width: 90px;
    }
    .fly_basket_price_one {
        margin-left: 0px;
    }
    .fly_basket_items_container {
        margin-top: 20px;
        padding: 20px 10px;
    }
    .for_items_wrapper {
        padding-right: 10px;
    }
    .fly_basket_content_container_one {
        margin-left: 10px;
    }
    .fly_basket_one_container_heading {
        font-size: 14px;
        line-height: 18px;
    }
    .fly_basket_content_artikul {
        margin-top: 0px;
        line-height: 16px;
    }
    .fly_basket_count_price {
        margin-top: 10px;
    }
    .fly_basket_sell_price {
        font-size: 14px;
    }
    .fly_basket_price_one {
        font-size: 14px;
    }
    .fly_basket_price_one.old_price {
        font-size: 12px;
        margin-left: 25px;
    }
    .fly_basket_content_line_size {
        margin-top: 10px;
        font-size: 12px;
    }
    .fly_basket_content_line_size.counter {
        margin-top: 10px;
    }
    .count_one_product_controller {
        margin-left: 10px;
        width: 81px;
    }
    .wrapper_trash_basket {
        font-size: 10px;
    }
    .result_price_fly_basket {
        margin-top: 0px;
    }
    .fly_basket_non_sell_price {
        font-size: 12px;
    }
    .fly_basket_final_price {
        margin-top: 10px;
    }

    .wrap_promo_and_price {
        padding: 20px 10px;
    }
    .fly_basket_place_an_order {
        margin-top: 20px;
    }
    .for_items_wrapper_non {
        padding-right: 5px;
    }

}
