.fly_basket_notification {
    display: flex;
    max-width: 293px;
    width: 100%;
    min-height: 101px;
    padding: 17px 10px;
    background-color: black;
    position: fixed;
    top: 133px;
    right: -1030px;
    /*right: 30px;*/

    opacity: 0.6;
    /*opacity: 1;*/
    cursor: pointer;
    transition: right 0.8s ease 0.2s, opacity 0.8s ease 0s;
}

.fly_basket_notification.active {
    right: 30px;
    opacity: 1;
    transition: right 0.8s ease 0s, opacity 0.8s ease 0.4s;
}


.fly_basket_notification:hover {
    box-shadow: 0px 5px 10px 2px rgba(156, 162, 167, 0.2);
}

.notification_all_wrapper_content {
    display: flex;
    height: 100%;
    width: 100%;
}

.notification_image_container {
    display: flex;
    width: 50px;
    height: 67px;
    background-color: white;
}
.notification_image_container img {
    width: 50px;
    height: 67px;
    display: flex;
    object-fit: cover;
}

.notification_content_area {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-left: 33px;
}
.notification_heading_line {
    color: white;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    width: 100%;
    padding-bottom: 5px;
}
.notification_counter_container {
    display: flex;
    margin-top: 7px;
    font-size: 10px;
    line-height: 12px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.9);
}
.notification_prefix {
    margin-left: 5px;
}
.notification_size_and_color_container {
    display: flex;
    margin-top: 7px;
    width: 100%;
    min-height: 12px;
    /*background-color: red;*/
}
.notification_size {
    font-weight: 400;
    display: flex;
    align-items: center;
    font-size: 10px;
    line-height: 12px;
    color: #FFFFFF;
    padding-right: 12px;
    border-right: 1px solid white;
}
.notification_color {
    padding-left: 12px;
    display: flex;
    min-height: 12px;
}

.notification_colorpicker {
    display: flex;
    height: 12px;
    width: 12px;
    border: 1px solid white;
    border-radius: 50%;
    background-position: center;
    background-size: 100%;
}

.notification_sizename {
    margin-left: 4px;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #FFFFFF;
    opacity: 0.9;
}

@media (max-width: 768px) {
    .fly_basket_notification.active {
        right: 0;
    }
    .fly_basket_notification {
        padding: 12px 5px;
        align-items: center;
        width: 250px;
        min-height: 80px;
        height: max-content;
    }
    .notification_all_wrapper_content {
        align-items: center;
    }
    .notification_content_area {
        justify-content: space-between;
        margin-left: 15px;
    }
    .notification_heading_line {
        font-size: 11px;
        line-height: 13px;
    }
    .notification_counter_container {
        margin-top: 5px;
    }
    .notification_size_and_color_container {
        margin-top: 5px;
    }
}