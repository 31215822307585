
.my_offers_container.hidden {
    display: none;
}
.my_offers_container {
    display: flex;
    flex-direction: column;
}
.your_offers_title {
    font-weight: 500;
    font-size: 30px;
    line-height: 39px;
    color: #1A1A1A;
}
.my_offers_for_items_container {
    max-width: 764px;
    width: 100%;
    display: flex;
    flex-direction: column;
    /*padding-right: 30px;*/
    /*margin-top: 40px;*/

}


.border_user_lc_one {
    padding-bottom: 40px;
    border-bottom: 2px solid #000000;
    margin-top: 40px;
}

.user_lc_one_order_wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    /*border: 1px solid transparent;*/
    /*border-top: 1px solid #CFCFCF;*/
    box-shadow: 1px 0 0 0 #cfcfcf, 0 1px 0 0 #cfcfcf, 1px 1px 0 0 #cfcfcf, 1px 0 0 0 #cfcfcf inset, 0 1px 0 0 #cfcfcf inset;
}

.user_lc_one_order_wrapper.active {
    background: #FFFFFF;
    /*border: 1px solid #E0E0E0;*/
    /*box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);*/
    box-shadow: 1px 0 0 0 #000, 0 1px 0 0 #000, 1px 1px 0 0 #000, 1px 0 0 0 #000 inset, 0 1px 0 0 #000 inset;
    transition: box-shadow 0.2s ease 0s;
}

.user_lc_detail_hidden_zone {
    display: none;
    flex-direction: column;
    height: 0px;
    opacity: 0;
}

.user_lc_one_order_wrapper.active .user_lc_detail_hidden_zone{
    height: unset;
    opacity: 1;
    /*padding: 0px 40px;*/
    transition: opacity 0.4s ease 0s;
    display: flex;
    /*margin-bottom: 40px;*/
}




.user_lc_one_order_wrapper:first-child {
    margin-top: 0px;
}

/*.user_lc_one_order_wrapper:hover {*/
/*    background: #FFFFFF;*/
/*    border: 2px solid #000000;*/
/*    !*box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);*!*/
/*    transition: border 0.4s ease 0s;*/
/*}*/

.user_lc_button_change_hidden_zone {
    display: flex;
    align-items: center;
    justify-content: center;
    /*text-transform: uppercase;*/
    width: 100%;
    padding: 14px;
    color: white;
    border: 1px solid #1A1A1A;
    background: #1A1A1A;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    min-height: 50px;
}

.user_lc_button_change_hidden_zone:hover {
    color: #1A1A1A;
    background: white;
    transition: color 0.4s ease 0s, background 0.4s ease 0s;
}
.user_lc_default_vision_zone {
    display: flex;
    padding: 40px;
    flex-direction: column;
    justify-content: space-between;
    min-height: 180px;
}

.user_lc_default_vision_zone_top_line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.user_lc_order_number_and_price {
    font-weight: 500;
    font-size: 30px;
    line-height: 39px;
    color: #1A1A1A;
}
.user_lc_order_number_and_price.price {
    font-weight: 500;
    font-size: 24px;
    line-height: 31px;
    text-align: right;
    color: #1A1A1A;
}
.user_lc_gray_span {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
    opacity: 0.3;
}

.user_lc_data_span {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
    margin-left: 29px;
}
.user_lc_for_data_container {
    display: flex;
    justify-content: space-between;
}
.container_counter_order {
    display: flex;
}
.user_lc_item_counter {
    margin-left: auto;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
}
.user_lc_count_span {
    margin-left: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
}
.user_lc_one_order_item {
    display: flex;
    /*padding-top: 40px;*/
    /*margin-top: 30px;*/
    /*border-top: 1px solid #CFCFCF;*/
    width: 100%;
    padding: 18px 40px;
    border-bottom: 1.5px solid black;
}
.user_lc_one_order_item:last-child {
    margin-bottom: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid #CFCFCF;;
}
.user_lc_one_order_image_container {
    display: flex;
    max-width: 223px;
    /*min-height: 334px;*/
    /*height: 100%;*/
    width: 100%;
}

.user_lc_one_order_image_container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.info_product_and_price_lc {
    margin-left: 35px;
    display: flex;
    flex-direction: column;
    width: 100%;
    /*justify-content: space-between;*/
    justify-content: center;
}
.user_lc_one_order_content_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.user_lc_one_order_item_name {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #1A1A1A;
    margin-bottom: auto;
}
.user_lc_one_order_item_atricul {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #757575;
    margin-top: 18px;
}
.user_lc_one_order_item_color {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    margin-top: 18px;
}
.user_lc_one_order_item_size {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    margin-top: 18px;
}
.user_lc_one_order_price_container {
    display: flex;
    flex-grow: 1;
    /*flex-direction: column;*/
    /*justify-content: flex-end;*/
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: flex-end;
}

.user_lc_one_order_price_container_act_price {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #1A1A1A;
}
.user_lc_one_order_price_counter {
    margin-top: 18px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}
.user_lc_one_order_price_container_act_price .user_lc_one_order_price_counter {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    /*margin-right: 5px;*/
}
.user_lc_one_order_price_container_old_price {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-decoration-line: line-through;
    color: #757575;
    margin-top: 7px;
    margin-right: 18px;
}
.user_lc_itg_block {
    display: flex;
    flex-direction: column;
    padding: 18px 40px;
}
.user_lc_itg_block_first_line {
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    font-size: 30px;
    line-height: 39px;
    color: #757575;
    margin-top: 18px;
    /*border-top: 1px solid #CFCFCF;*/
}
.price_color {
    font-weight: 500;
    color: #000000;
}

.user_lc_itg_block_counter_block {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    margin-top: 40px;
}
.user_lc_itg_block_no_sell {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #757575;
    margin-top: 18px;
    display: flex;
    justify-content: space-between;
}
.user_lc_itg_block_no_sell:first-child{
    margin-top: 0px;
}
.user_lc_itg_block_no_sell_special {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #757575;
}
.user_lc_no_my_offers {
    display: flex;
    width: 100%;
    font-weight: 600;
    font-size: 18px;
}

@media (max-width: 1024px) {
    .your_offers_title {
        font-size: 24px;
        line-height: 31px;
        letter-spacing: 0.04em;
    }
    .user_lc_title {
        display: flex;
        flex-direction: column;
    }
    .user_lc_order_number_and_price {
        font-size: 24px;
    }
    .user_lc_order_number_and_price.price {
        font-size: 16px;
        line-height: 21px;
    }
    .user_lc_gray_span {
        font-size: 12px;
        line-height: 16px;
    }
    .user_lc_data_span {
        font-size: 12px;
        line-height: 16px;
        margin-left: 0px;
    }
    .user_lc_for_data_container {
        flex-direction: row;
        justify-content: flex-start;
    }
    .container_counter_order {
        flex-direction: column;
        justify-content: center;
        margin-left: 18px;
    }
    .user_lc_item_counter {
        margin-left: 0;
        justify-content: flex-start;
        font-size: 12px;
        line-height: 16px;
        opacity: 0.3;
        margin-top: 16px;
    }
    .user_lc_count_span {
        font-size: 12px;
        line-height: 16px;
        margin-top: 16px;
        margin-left: 0px;
    }

}

@media (max-width: 768px)  {
    .your_offers_title {
        font-size: 18px;
        line-height: 23px;
        letter-spacing: 0.04em;
    }
    .border_user_lc_one {
        margin-top: 20px;
        padding-bottom: 20px;
    }
    .container_counter_order {
        margin-left: 10px;
    }
    .user_lc_default_vision_zone {
        padding: 20px;
    }
    .user_lc_order_number_and_price {
        font-size: 18px;
        line-height: 23px;
    }
    .user_lc_default_vision_zone {
        min-height: 115px;
    }
    .user_lc_for_data_container {
        margin-top: 10px;
    }
    .user_lc_one_order_item {
        padding: 0px 10px 20px 10px;
    }
    .info_product_and_price_lc {
        margin-left: 10px;
    }
    .user_lc_one_order_item_name {
        font-size: 14px;
        line-height: 18px;
    }
    .user_lc_one_order_item_atricul {
        margin-top: 0px;
        font-size: 12px;
        line-height: 16px;
    }
    .user_lc_one_order_price_container {
        margin-top: 10px;
    }
    .user_lc_one_order_price_container_act_price {
        font-size: 14px;
        line-height: 18px;
    }
    .user_lc_one_order_price_container_old_price {
        font-size: 12px;
        line-height: 16px;
    }
    .user_lc_one_order_price_counter {
        margin-top: 10px;
        font-size: 12px;
        line-height: 16px;
    }
    .user_lc_one_order_item_color {
        margin-top: 10px;
        font-size: 12px;
        line-height: 16px;
    }
    .user_lc_one_order_item_size {
        margin-top: 10px;
        font-size: 12px;
        line-height: 16px;
    }
    .user_lc_one_order_image_container {
        max-width: 90px;
        height: 162px;
    }
    .user_lc_one_order_image_container img {
        object-fit: cover;
    }
    .user_lc_itg_block {
        padding: 20px 10px;
    }
    .user_lc_itg_block_no_sell {
        font-size: 14px;
        line-height: 18px;
    }
    .user_lc_itg_block_no_sell_special {
        font-size: 14px;
        line-height: 18px;
    }
    .user_lc_itg_block_first_line {
        font-size: 28px;
        line-height: 39px;
    }
    .price_color {

    }

}
/*@media (max-width: 380px) {*/
/*    .user_lc_one_order_item {*/
/*        flex-direction: column;*/
/*        align-items: center;*/
/*    }*/

/*    .info_product_and_price_lc {*/
/*        margin-left: 0px;*/
/*        margin-top: 10px;*/
/*    }*/

/*    .user_lc_one_order_image_container {*/
/*        max-width: 200px;*/
/*    }*/
/*}*/

