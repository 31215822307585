.refund_request_page {
    width: 100%;
    height: 100%;
    padding: 0px 40px 100px 40px;
}
.application_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1120px;
    margin: 0 auto;
}
.application_title_page {
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    color: #000000;
}
.application_fields_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.one_field_refund_wrapper{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    width: 100%;
    padding: 5px;
}
.one_field_refund {
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    position: relative;
}
.refund_field {
    width: 100%;
    display: flex;
    align-items: center;
    border: 1px solid #D7D6D6;
    font-weight: 600;
    font-size: 14px;
    padding: 5px 5px;
    margin-top: 7px;
    height: 39px;
}
.refund_field.textarea {
    resize: none;
    height: 120px;
}
.refund_field .phoneInput {
    margin-left: 10px;
    font-size: 14px;
}
.one_field_refund_wrapper .email_input_wrapper {
    margin-top: 0px;
}
.one_field_refund_wrapper .user_email_component {
    height: 39px;
    font-weight: 600;
    font-size: 14px;
}
.one_field_refund_wrapper .title_email_lk {
    font-weight: 400;
    font-size: 16px;
}
.one_field_refund.mandatory:after {
    content: " *";
    color: #CE3F3F;
    top: -5px;
    position: absolute;
}
.refund_field.error_refound {
    border-color: #CE3F3F;
    transition: border-color 0.4s ease 0s;
}
.refund_field.error_refound {
    border-color: #CE3F3F;
    transition: border-color 0.4s ease 0s;
}
.err_refund {
    min-height: 15px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #CE3F3F;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 0.03em;
}
.gorizontal_fields_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.article_wrapper {
    border: 1px solid #D7D6D6;
    margin-bottom: 10px;
    border-radius: 10px;
    width: 100%;
}

.gorizontal_fields_wrapper__article {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.delete_article:after {
    position: absolute;
    content: "";
    top:50%;
    left: 50%;
    height: 2px;
    width: 15px;
    background-color: black;
    transform: translate(-50%,-50%) rotate(45deg);
}
.delete_article:before {
    position: absolute;
    content: "";
    top:50%;
    left: 50%;
    height: 2px;
    width: 15px;
    background-color: black;
    transform: translate(-50%,-50%) rotate(-45deg);
}

.article_chose_ct {
    display: flex;
    width: 20px;
    height: 100%;
    position: relative;
    margin: 5px 5px 5px auto;
}
.article_chose_ct:hover {
    background-color: lightgrey;
    cursor: pointer;
}
.article_delete_wrapper {
    width: 100%;
    height: 20px;
}

.subtitle_return_form{
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: #000000;
    max-width: 460px;
    margin-top: 50px;
}
.subtitle_return_form.no_restrictions {
    max-width: 100%;
}
.types_of_payment {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.one_type_payment {
    display: flex;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;
    margin-top: 20px;
}
.selection_checkbox {
    width: 20px;
    height: 20px;
    border: 1px solid black;
    border-radius: 50%;
    margin-right: 10px;
    position: relative;
}
.selection_checkbox.active {
    background: black;
}
.selection_checkbox.active:after {
    position: absolute;
    content: "";
    width: 10px;
    height: 2px;
    border-radius: 10px;
    background: #ffffff;
    transform: rotate(-45deg);
    top: 8px;
    right: 2px;
}
.selection_checkbox.active:before {
    position: absolute;
    content: "";
    width: 6px;
    height: 2px;
    border-radius: 10px;
    background: #ffffff;
    transform: rotate(45deg);
    top: 10px;
    right: 9px;
}
.name_type_payment {
    font-weight: 400;
    font-size: 14px;
    color: #000000;
}
.card_data_wrapper {
    margin-top: 20px;
    display: flex;
    box-shadow: 1px 0 0 0 #D7D6D6, 0 1px 0 0 #D7D6D6, 1px 1px 0 0 #D7D6D6, 1px 0 0 0 #D7D6D6 inset, 0 1px 0 0 #D7D6D6 inset;
}
.description_field_card {
    background: rgba(215, 214, 214, 0.2);
    display: flex;
    flex-direction: column;
    max-width: 425px;
    width: 100%;
    height: 100%;
}
.one_field_card{
    padding: 9px 10px;
    box-shadow: 1px 0 0 0 #D7D6D6, 0 1px 0 0 #D7D6D6, 1px 1px 0 0 #D7D6D6, 1px 0 0 0 #D7D6D6 inset, 0 1px 0 0 #D7D6D6 inset;
    width: 100%;
    display: flex;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
}

.card_value_wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.card_value_child {
    display: flex;
}
.one_value_card {
    width: 100%;
    display: flex;
    padding: 9px 10px;
    box-shadow: 1px 0 0 0 #D7D6D6, 0 1px 0 0 #D7D6D6, 1px 1px 0 0 #D7D6D6, 1px 0 0 0 #D7D6D6 inset, 0 1px 0 0 #D7D6D6 inset;
    cursor: pointer;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
}
.err_return_terminal {
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    color: #000000;
    max-width: 640px;
    width: 100%;
    margin: 0 auto;
    margin-top: 30px;
}
.application_submission_button {
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #FFFFFF;
    padding: 10px;
    width: 100%;
    margin-top: 15px;
    cursor: pointer;
    background: black;
    transition: background 0.4s ease 0s;
}
.application_submission_button.unactive {
    background: #757575;
    -webkit-user-select: none;
    user-select: none;
    pointer-events: none;
}
.add_article_wrapper {
    width: 100%;
    display: flex;
    align-items: flex-start;
}

.add_article_button {
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #FFFFFF;
    padding: 10px;
    width: 250px;
    cursor: pointer;
    background: black;
    transition: background 0.4s ease 0s;
}

.soglasie_return {
    display: flex;
    margin-top: 30px;
    max-width: 474px;
}
.checkbox_return{
    width: 16px;
    height: 16px;
    border: 1px solid #000;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 10px;
}
.checkbox_return_child {
    width: 16px;
    height: 16px;
    background: #1a1a1a;
    opacity: 0;
    transition: opacity .4s ease 0s;
    position: relative;
}
.checkbox_return.active .checkbox_return_child {
    opacity: 1;
    transition: opacity 0.4s ease 0s;
}
.checkbox_return_child:before {
    position: absolute;
    content: "";
    width: 6px;
    height: 2px;
    background: #fff;
    transform: rotate(45deg);
    top: 9px;
    left: 2px;
}
.checkbox_return_child:after {
    position: absolute;
    content: "";
    width: 8px;
    height: 2px;
    background: #fff;
    transform: rotate(-45deg);
    top: 8px;
    left: 5px;
}
.return_politic {
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    color: #7C7C7C;
}
.link_politic_return {
    color: #000000;
    border-bottom: 1px solid transparent;
    text-decoration: none;
}
.link_politic_return:hover {
    border-bottom: 1px solid black;
    transition: border-bottom 0.4s ease 0s;
}
.self-refund {
    display: flex;
    flex-direction: column;
}
.delivery_services_wrapper {
    display: flex;
    flex-direction: column;
    padding: 45px 25px 30px 25px;
    border: 1px solid black;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}
.pvz_variant {
    display: flex;
    max-width: 300px;
    justify-content: space-between;
}
.one_pvz {
    display: flex;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;
    padding: 5px;
}
.treck_number_wrap {
    max-width: 300px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 18px;
}
.warning_message {
    margin-top: 18px;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: #000000;
}
.hint_message {
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    color: #000000;
    margin-top: 30px;
}
.refund_option {
    display: flex;
    flex-wrap: wrap;
}

@media (max-width: 1440px) {
    .application_wrapper {
        max-width: 650px;
    }
}
@media (max-width: 768px) {
    .refund_request_page {
        padding: 0px 10px 40px 10px;
        height: auto;
    }
    .application_title_page {
        font-size: 22px;
    }
    .one_field_refund_wrapper {
        margin-top: 0px;
    }
    .one_field_refund {
        font-size: 14px;
    }
    .one_field_refund_wrapper .title_email_lk {
        font-size: 14px;
    }
    .refund_field {
        margin-top: 0px;
    }
    .gorizontal_fields_wrapper {
        flex-direction: column;
    }
    .gorizontal_fields_wrapper__article {
        flex-direction: column;
    }
    .subtitle_return_form {
        margin-top: 10px;
    }
    .name_type_payment {
        font-size: 12px;
    }
    .selection_checkbox {
        margin-right: 5px;
    }
    .err_return_terminal {
        margin-top: 10px;
        font-size: 18px;
    }
    .soglasie_return {
        margin-top: 15px;
    }
    .one_field_card {
        padding: 5px;
        height: 100%;
        font-size: 12px;
    }
    .one_value_card {
        padding: 5px;
        height: 100%;
        font-size: 12px;
    }
    .card_value_child input, .card_value_wrapper input{
        font-size: 12px;
    }
    .return_politic {
        font-size: 12px;
    }
    .subtitle_return_form {
        font-size: 16px;
    }
    .delivery_services_wrapper {
        margin-top: 10px;
        padding: 10px;
    }
    .warning_message {
        margin-top: 5px;
        font-size: 12px;
    }
    .hint_message {
        margin-top: 10px;
        font-size: 12px;
    }
}