.one_shop_adm {
    display: flex;
    margin: 10px;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    padding: 10px;
    position: relative;
    flex-direction: column;
    background: white;
    width:100%;
}
.delete_cars_shop_relative {
    position: relative;
    display: flex;
    justify-content: flex-end;
}
.delete_cars_shop {
    width: 24px;
    height: 24px;
    cursor: pointer;
}
.delete_cars_shop::before {
    content: " ";
    position: absolute;
    width: 24px;
    height: 2px;
    transform: rotate(-45deg);
    background-color: #000000;
    top: 12px;
    right: 0;
}

.delete_cars_shop::after {
    content: " ";
    position: absolute;
    width: 24px;
    height: 2px;
    transform: rotate(45deg);
    background-color: #000000;
    top: 12px;
    right: 0;
}

.input_field_shop {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #c9cccf;
    font-weight: 600;
    font-size: 12px;
    padding: 5px 5px;
    border-radius: 5px;
    margin-top: 5px;
}
.input_field_shop.textarea {
    resize: none;
    height: 100px;
    margin-right: 10px;
    white-space: pre-line;
}


.wrap_btn_one_shop {
    display: flex;
    justify-content: flex-end;
    margin: 10px;
}

.save_btn_one_shop {
    width: 150px;
    padding: 5px 10px;
    border: 1px solid black;
    cursor: pointer;
    font-weight: 600;
    font-size: 12px;
    margin-right: 20px;
    text-align: center;
}
.save_btn_one_shop.trig {
    animation: trigerSaveOneShop 2s ease infinite;
}

@keyframes trigerSaveOneShop{
    0% {
        background: white;
        color: black;
    }
    50% {
        background: black;
        color: white;
    }
    100% {
        background: white;
        color: black;
    }

}