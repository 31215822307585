.active_icons_social {
    margin: 5px;
}
.active_block_padding {
    padding: 10px;
    max-width: 60px;
    width: 100%;
    max-height: 60px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}