.reuse_double_block {
    /*margin-top: 20px;*/
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.double_block_st {
    /*width: calc(50% - 10px);*/
    width: calc(50%);
    height: 100vh;
    cursor: pointer;
    position: relative;
    text-decoration: none;
    display: flex;
}
.double_block_st img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.double_block_st .shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 5;
    background: rgba(0,0,0, 0%);
    transition: background 0.4s ease 0s;
}
.double_block_st .shadow.light {
    background: rgba(0,0,0, 30%);
    transition: background 0.4s ease 0s;
}

.labelInfo {
    position: absolute;
    left: 0px;
    /*bottom: 0px;*/
    top: 0;
    width: 100%;
    min-height: 85px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    z-index: 6;
}
.supportLabelWrap {
    position: relative;
    width: 100%;
    padding: 40px;
    display: flex;
    flex-direction: column;
}
.supportLabelWrap svg {
    margin-top: 8px;
}
.supportLabelWrap:after {
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    width: 0px;
    height: 100%;
    background: black;
    z-index: -1;
    transition: width 0.4s ease 0s;
    opacity: 0.9;
}
.double_block_st:hover .shadow {
    background: rgba(0,0,0, 60%);
    transition: background 0.4s ease 0s;
}
.double_block_st:hover  .supportLabelWrap:after {
    width: 100%;
    transition: width 0.4s ease 0s;
}

.labelTitleBlock {
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 39px;
    color: white;
    z-index: 8;
}


@media (max-width: 1024px) {
    .reuse_double_block {
        /*margin-top: 10px;*/
        flex-direction: column;
    }
    .double_block_st {
        width: 100%;
    }
    .supportLabelWrap {
        padding: 23px ;
    }
    .labelTitleBlock {
        font-size: 18px;
        line-height: 23px;
    }

}
@media (max-width: 768px) {
    .labelInfo {
        min-height: 50px;
    }
    .supportLabelWrap {
        padding: 20px;
    }
}
@media (max-width: 428px) {
    .labelInfo {
        min-height: 40px;
    }
}
