
.horizontal_upload_image {
    position: relative;
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: column;
}
.for_manager_horizontal_info {
    position: absolute;
    z-index: 2;
    display: flex;
    top: 0px;
    background-color: rgba(255, 255, 255, 0.94);
    text-align: center;
    width: 100%;
    justify-content: center;
    font-weight: 600;
    font-size: 13px;
    height: 20px;
}
.image_container_horizontal {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: relative;
}
.horizontal_uploader {
    position: absolute;
    top: 0px;
    height: 100%;
    width: 100%;
    opacity: 0;
    cursor: pointer;
}

.horizontal_image_child_product {
    max-height: 100%;
    height: 100%;
    width: 100%;
    height: 100%;
}
.remove_horizontal_image {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: rgba(208, 9, 9, 0.33);
    z-index: 20;
    font-weight: 600;
    font-size: 13px;
    position: absolute;
    height: 30px;
    cursor: pointer;
    top: 20px;
    left: 0px;
}

.remove_horizontal_image:hover {
    background-color: black;
    color: white;
}
