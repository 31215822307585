@function random_range($min, $max) {
    $rand: random();
    $random_range: $min + floor($rand * (($max - $min) + 1));
    @return $random_range;
}

.root {
    position: fixed;
    pointer-events: none;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99999999999999;
}

.noodle {
    display: inline-block;
    position: absolute;
    $total: 216;
    
    @for $i from 1 through $total {
        $random-x: random_range(-35, 135) * 1vw;
        $random-offset: random_range(-100000, 100000) * 0.0001vw;
        $random-x-end: $random-x + $random-offset;
        $random-x-end-yoyo: $random-x + $random-offset / 2;
        $random-yoyo-time: random_range(30000, 80000) / 100000;
        $random-yoyo-y: $random-yoyo-time * 100vh;
        $random-scale: random_range(85, 100) * 0.01;
        $random-scale-tablet: random_range(30, 40) * 0.01;
        $random-scale-mobile: random_range(10, 30) * 0.01;
        $fall-duration: random_range(250000, 350000) * 0.00001s;
        $fall-delay: random(500000) * 0.00001s;
        
        &:nth-child(#{$i}) {
            opacity: random_range(20, 100) * 0.01;
            transform: translate($random-x, -100%) scale($random-scale);
            animation: fall-#{$i} $fall-duration $fall-delay linear 1;
            
            @media all and (max-width: 1024px) {
                transform: translate($random-x, -100%) scale($random-scale-tablet);
            }
            
            @media all and (max-width: 767px) {
                transform: translate($random-x, -100%) scale($random-scale-tablet);
            }
        }
        
        @keyframes fall-#{$i} {
            #{percentage($random-yoyo-time)} {
                transform: translate($random-x-end, $random-yoyo-y) scale($random-scale);
                
                @media all and (max-width: 1024px) {
                    transform: translate($random-x-end, $random-yoyo-y) scale($random-scale-tablet);
                }
                
                @media all and (max-width: 767px) {
                    transform: translate($random-x-end, $random-yoyo-y) scale($random-scale-mobile);
                }
            }
            
            to {
                transform: translate($random-x-end-yoyo, 100vh) scale($random-scale);
                
                @media all and (max-width: 1024px) {
                    transform: translate($random-x-end-yoyo, 100vh) scale($random-scale-tablet);
                }
                
                @media all and (max-width: 767px) {
                    transform: translate($random-x-end-yoyo, 100vh) scale($random-scale-mobile);
                }
            }
        }
    }
}