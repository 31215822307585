.page_refound {
    margin-top: 40px;
}

.content_refound {
    /*max-width: 1600px;*/
    /*width: 100%;*/
    /*margin: 0 auto;*/
    /*position: relative;*/
    /*min-height: 664px;*/
}
/*.wrap_relative {*/
/*    position: relative;*/
/*    width: 100%;*/
/*}*/
.refound_absolute {
    /*position: absolute;*/
    /*top: -251px;*/
    /*width: 100%;*/
    /*display: flex;*/
    /*justify-content: center;*/
    /*padding-bottom: 90px;*/
}
.wrap_content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}
.conteiner_cards_refound {
    /*padding: 0px 15px;*/
    margin-left: 47px;
    width: 100%;
}
.conteiner_cards_refound:first-child {
    margin-left: 0px;
}
.child_cards {
    border-bottom: 2px solid #000000;
}
.background_ref {
    background-color: #FFFFFF;
    border: 2px solid #000000;
    width: 100%;
    min-height: 643px;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.refund_content_wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}
.style_title {
    padding: 18px 40px;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
}
.style_content {
    padding: 18px 40px;
}
.subline_text {
    margin-top: 18px;
}
.subline_text:first-child{
    margin-top: 0px;
}
.subline_refound {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
}
.text_content {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #757575;
    margin-top: 10px;
}
.cnopochka {
    display: none;
}
.cnopochka.active {
    display: flex;
    cursor: pointer;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: rgba(117, 117, 117, 1);
    margin-top: 10px;
}
.cnopochka.active:hover{
    text-decoration-line: underline;
    color: black;
    transition: color 0.4s ease 0s, text-decoration-line 0.4s ease 0s;
}

.border_style {
    max-width: 919px;
    width: 100%;
    border: 1px solid #E0E0E0;
    bottom: 0px;
    position: absolute;
}
.conteinet_text_below {
    max-width: 1365px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 40px;
}
.title_text {
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    color: #000000;
}

/*.one_paragraph, .two_paragraph {*/
/*    display: flex;*/
/*    align-items: center;*/
/*    flex-direction: column;*/
/*}*/
.additional_text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
    margin-top: 18px;
    white-space: pre-line;
}
.additional_text.bold {
    font-weight: 700;
}
/*.two_paragraph {*/
/*    margin-top: 74px;*/
/*}*/
.additional_grey {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
    opacity: 0.3;
    margin-top: 40px;
    white-space: pre-line;
}
.refound_open_dop_text {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
    cursor: pointer;
    border-bottom: 1px solid transparent;
    transition: border-bottom 0.4s ease 0s;
    margin-top: 10px;
}
.refound_open_dop_text:hover {
    border-bottom: 1px solid black;
    transition: border-bottom 0.4s ease 0s;
}
.return_button_wrapper {
    padding: 30px 40px 60px 40px;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.return_button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px 33px;
    max-width: 360px;
    background: #1A1A1A;
    border: 1px solid black;
    transition: color 0.4s ease 0s, background 0.4s ease 0s;
    text-decoration: none;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 10px;
}
.return_button:hover {
    background: #ffffff;
    border: 1px solid black;
    color: #000000;
    transition: color 0.4s ease 0s, background 0.4s ease 0s, border 0.4s ease 0s;
}
.return_button svg {
    margin-left: 10px;
}
.return_button:hover.return_button svg path{
    stroke: #000000;
    transition: stroke 0.4s ease 0s;
}
.mini_block_return_button {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    text-decoration: none;
    color: #1A1A1A;
    cursor: pointer;
    margin-top: 10px;
    border-bottom: 1px solid transparent;
    display: flex;
}
.mini_block_return_button:hover {
    border-bottom: 1px solid black;
    transition:  border-bottom 0.4s ease 0s;
}

@media (max-width: 1440px) {
    .conteinet_text_below {
        max-width: 969px;
    }
}

@media (max-width: 1024px) {

    .wrap_content {
        justify-content: initial;
        flex-direction: column;
    }
    /* SCROLLBAR */
    .wrap_content::-webkit-scrollbar {
        height: 3px;
    }
    /*Track*/
    .wrap_content::-webkit-scrollbar-track {
        background: #757575;
        border: 1px solid transparent;
        background-clip: content-box;
    }
    /* Handle */
    .wrap_content::-webkit-scrollbar-thumb {
        background: rgb(0, 0, 0);
        border: 1px solid rgb(0, 0, 0);
    }
    .conteiner_cards_refound {
        margin-left: 0;
    }
    .conteiner_cards_refound .background_ref {
        border-bottom-width: 0;
    }
    .conteiner_cards_refound:last-child .background_ref {
        border-bottom-width: 2px;
    }
    .background_ref {
        min-height: auto;
    }
    .text_content {
        margin-top: 0px;
        font-size: 16px;
    }
    .additional_text.bold {
        margin-top: 40px;
    }
    .additional_grey {
        margin-top: 20px;
    }
    .child_cards {
        cursor: pointer;
        position: relative;
        border-bottom-width: 0;
    }
    .child_cards::after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='11' viewBox='0 0 18 11' fill='none'%3E%3Cpath d='M17.2129 1.21484L8.99887 9.42887L0.784844 1.21484' stroke='black' stroke-width='1.5'/%3E%3C/svg%3E");
        position: absolute;
        content: '';
        right: 19px;
        top: 50%;
        transform: translateY(-50%);
        transition: .3s;
        width: 18px;
        height: 11px;
    }
    .child_cards._active {
        background: #1A1A1A;
        color: #FFFFFF;
        border-bottom-width: 2px;
    }
    .child_cards._active .refound_arrows_mob {
        color: #FFFFFF;
    }
    .child_cards._active::after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='11' viewBox='0 0 18 11' fill='none'%3E%3Cpath d='M0.785156 9.78516L8.99918 1.57113L17.2132 9.78516' stroke='white' stroke-width='1.5'/%3E%3C/svg%3E");
    }
    .style_title {
        padding-right: 40px;
    }
    .return_button_wrapper {
        padding: 18px 40px 40px;
    }
    .return_button {
        margin-top: 0;
    }
}

@media (max-width: 768px) {
    .return_button_wrapper {
        padding-right: 20px;
        padding-left: 20px;
    }
    .style_title {
        padding: 20px 40px 20px 20px;
    }
    .style_content {
        padding: 20px;
    }
    .text_content {
        margin-top: 10px;
    }
    .title_text {
        font-size: 16px;
        line-height: 21px;
    }
    .additional_text {
        margin-top: 20px;
        font-size: 12px;
    }
    .additional_text.bold {
        font-size: 16px;
    }
    .additional_grey {
        font-size: 12px;
    }

    /*.refound_absolute {*/
    /*    top: -95px;*/
    /*}*/
    /*.conteiner_cards_refound {*/
    /*    padding: 0px 15px;*/
    /*}*/
    /*.refound_arrows_mob {*/
    /*    padding-right: 20px;*/
    /*    width: 100%;*/
    /*}*/
    /*.style_title {*/
    /*    padding: 15px;*/
    /*    font-size: 16px;*/
    /*    cursor: pointer;*/
    /*    position: relative;*/
    /*    display: flex;*/
    /*}*/
    /*.style_title::after {*/
    /*    position: absolute;*/
    /*    content: "";*/
    /*    width: 10px;*/
    /*    height: 2px;*/
    /*    right: 21px;*/
    /*    top: 50%;*/
    /*    background-color: black;*/
    /*    border-radius: 10px;*/
    /*    transform: rotate(45deg);*/
    /*}*/
    /*.style_title::before {*/
    /*    position: absolute;*/
    /*    content: "";*/
    /*    width: 10px;*/
    /*    height: 2px;*/
    /*    right: 15px;*/
    /*    top: 50%;*/
    /*    background-color: black;*/
    /*    border-radius: 10px;*/
    /*    transform: rotate(-45deg);*/
    /*}*/
    /*.style_title.close::after {*/
    /*    position: absolute;*/
    /*    content: "";*/
    /*    width: 10px;*/
    /*    height: 2px;*/
    /*    right: 21px;*/
    /*    top: 50%;*/
    /*    background-color: black;*/
    /*    border-radius: 10px;*/
    /*    transform: rotate(-45deg);*/
    /*}*/
    /*.style_title.close::before {*/
    /*    position: absolute;*/
    /*    content: "";*/
    /*    width: 10px;*/
    /*    height: 2px;*/
    /*    right: 15px;*/
    /*    top: 50%;*/
    /*    background-color: black;*/
    /*    border-radius: 10px;*/
    /*    transform: rotate(45deg);*/
    /*}*/
    /*.style_content {*/
    /*    display: none;*/
    /*}*/
    /*.style_content.active {*/
    /*    display: flex;*/
    /*    flex-direction: column;*/
    /*}*/
    /*.child_cards.none {*/
    /*    border-bottom: 1px solid transparent;*/
    /*}*/
    /*.subline_refound {*/
    /*    font-size: 14px;*/
    /*}*/
    /*.text_content {*/
    /*    font-size: 14px;*/
    /*}*/
    /*.cnopochka.active {*/
    /*    font-size: 14px;*/
    /*}*/
    /*.conteinet_text_below {*/
    /*    padding: 0px 15px;*/
    /*}*/
    /*.title_text {*/
    /*    font-size: 16px;*/
    /*}*/
    /*.additional_text {*/
    /*    margin-top: 15px;*/
    /*    font-size: 14px;*/
    /*}*/
    /*.refound_open_dop_text {*/
    /*    font-size: 14px;*/
    /*}*/
    /*.additional_grey {*/
    /*    margin-top: 15px;*/
    /*    font-size: 14px;*/
    /*}*/
}
