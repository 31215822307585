.notification_modal_for_new_order {
    display: flex;
    width: 100%;
    max-width: 559px;
    min-height: 368px;
    background-color: white;
    position: fixed;
    opacity: 0;
    z-index: -10;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border: 1px solid black;
}

.notification_modal_for_new_order.active {
    z-index: 10;
    opacity: 1;
    transition: opacity 1s ease 0s;
    box-shadow: 0 10px 25px rgb(0 0 0 / 15%);
}

.notification_modal_wrapper_for_content {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    border: 1px solid black;
}
.notification_modal_wrapper_for_content.non_border {
    border: 1px solid transparent;
}
.notification_modal_heading_line_and_exit {
    display: flex;
    padding: 18px 18px 18px 40px;
}

.notification_modal_heading_text {
    display: flex;
    flex-grow: 1;
    /*height: 30px;*/
    text-align: center;
    justify-content: flex-start;
    align-items: center;
    font-weight: 500;
    font-size: 30px;
    line-height: 39px;
    letter-spacing: 0.04em;

    color: #1A1A1A;
}
.notification_modal_exit_div {
    width: 25px;
    height: 25px;
    position: relative;
    cursor: pointer;
    margin-left: auto;
}
.notification_controll_line {
    display: flex;
    width: 100%;
    margin-top: 20px;
}

.notification_modal_exit_div:after {
    content: "";
    width: 25px;
    height: 2px;
    position: absolute;
    right: -3px;
    top: 12px;
    transform: rotate(45deg);
    border-radius: 10px;
    background-color: black;
}

.notification_modal_exit_div:before {
    content: "";
    width: 25px;
    height: 2px;
    position: absolute;
    right: -3px;
    top: 12px;
    transform: rotate(-45deg);
    border-radius: 10px;
    background-color: black;
}

.relative_container_for_notification {
    display: flex;
    position: relative;
    height: 100%;
    width: 100%;
    border-top: 1px solid black;
    padding: 40px;
    justify-content: center;
}
.relative_container_for_notification.non_border {
    border-top: 1px solid transparent;
}

.modal_content_notification {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    /*padding: 20px 35px;*/
}

.modal_content_notification_heading_line {
    text-decoration: none;
    display: flex;
    width: 100%;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #757575;
}



.modal_content_notification_wrapper_for_row {
    display: flex;
    width: 100%;
    /*height: 100%;*/
    margin-top: 30px;
    /*max-height: 200px;*/
}

.modal_content_notification_img_container {
    display: flex;
    width: 140px;
    height: 200px;
}

.modal_content_notification_img_container img {
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.modal_content_notification_wrapper_for_column_content {
    display: flex;
    margin-left: 35px;
    flex-direction: column;
    justify-content: center;
    /*height: 200px;*/
}

.modal_content_notification_name_line {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
}

.modal_content_notification_color_wrapper {
    display: flex;
    width: 27px;
    height: 27px;
    border-radius: 50%;
    margin-left: 20px;

}
.modal_content_notification_color_circle {
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.modal_content_notification_color_name {
    display: flex;
    margin-top: 15px;
    align-items: center;

}

.modal_content_notification_color_name:last-child{
    margin-top:  auto;
}
.modal_content_notification_color_name  span{
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
}
.modal_content_notification_color_name .standard_price.old_price {
    font-size: 12px;
    margin-left: 18px;
}

.modal_content_notification_color_name  .modal_content_notification_black_span {
    font-weight: 400;
    font-size: 14px;
    margin-right: 15px;
}
.wrapper_for_mini_count_controller_wp {
    display: flex;
    height: 30px;
    width: 95px;
    margin-left: 20px;
    background: #FFFFFF;
    border: 1px solid #C9C9C9;
    /*border-radius: 5px;*/
}
.wrapper_for_mini_count_controller {
    display: flex;
    height: 100%;
    width: 33.33%;
    flex-grow: 1;
    cursor: pointer;
    transition: background-color 0.4s ease 0s;
    position: relative;
    justify-content: center;
    align-items: center;
}
.wrapper_for_mini_count_controller.min {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.wrapper_for_mini_count_controller.min:before {
    content: "";
    position: absolute;
    width: 2px;
    height: 10px;
    background-color: #8d8d8d;
    right: 18px;
    top: 12px;
    /*border-radius: 2px;*/
    transform: rotate(-45deg);
}
.wrapper_for_mini_count_controller.min:after {
    content: "";
    position: absolute;
    width: 2px;
    height: 10px;
    background-color: #8d8d8d;
    right: 18px;
    top: 5px;
    border-radius: 2px;
    transform: rotate(45deg);
}
.wrapper_for_mini_count_controller.center {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    -webkit-user-select: none;
    user-select: none;
}



.wrapper_for_mini_count_controller.max {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.wrapper_for_mini_count_controller.max:before {
    content: "";
    position: absolute;
    width: 2px;
    height: 10px;
    background-color: #8d8d8d;
    left: 18px;
    top: 12px;
    border-radius: 2px;
    transform: rotate(-135deg);
}
.wrapper_for_mini_count_controller.max:after {
    content: "";
    position: absolute;
    width: 2px;
    height: 10px;
    background-color: #8d8d8d;
    left: 18px;
    top: 5px;
    border-radius: 2px;
    transform: rotate(135deg);
}

.wrapper_for_mini_count_controller:hover {
    background-color: black;
    color: white;
}
.wrapper_for_mini_count_controller.center:hover {
    background-color: white;
    color: black;
}
.wrapper_for_mini_count_controller:hover:after{
    background-color: white;
}
.wrapper_for_mini_count_controller:hover:before{
    background-color: white;
}

.notification_fields_modal {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.notification_fields_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 15px;
}

.notification_fields_wrapper span{
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
}
.notification_fields_wrapper input {
    display: flex;
    width: 100%;
    height: 30px;
    border-bottom: 1px solid #000000;
    color: black;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
}

.wrapper_for_counter {
    display: flex;
    align-items: center;
}

.notification_success {
    display: flex;
    height: 100%;
    max-width: 384px;
}


.notification_finally_button {
    display: flex;
    height: 50px;
    font-weight: 500;
    font-size: 13px;
    color: white;
    background-color: black;
    border: 1px solid black;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.notification_finally_button.disable {
    opacity: 0.7;
    transition: opacity 0.4s ease 0s;
    -webkit-user-select: none;
    user-select: none;
    cursor: default;
    pointer-events: none;
}

.notification_finally_button:not([disable]):hover {
    background-color: white;
    color: black;
    transition: color 0.4s ease 0s, background-color 0.4s ease 0s;
}

.notification_finally_valid_text {
    display: flex;
    height: 40px;
    width: 100%;
    align-items: center;
    color: #CE3F3F;
    font-weight: 500;
}
.wrapper_notificator {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.thank_you_notification {
    font-weight: 500;
    font-size: 30px;
    line-height: 39px;
    text-align: center;
    letter-spacing: 0.04em;
    color: #1A1A1A;
}
.thank_text_notification {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #1A1A1A;
    margin-top: 25px;
}

.notification_finally_button.close {
    margin-top: 45px;
    width: 100%;
}

@media (max-width: 768px) {
    .notification_modal_heading_text {
        font-size: 18px;
        line-height: 21px;
    }
}
@media (max-width: 428px) {
    .notification_modal_for_new_order {
        width: 95%;
        min-height: 290px;
    }
    .notification_modal_heading_line_and_exit{
        padding: 20px;
    }
    .notification_modal_heading_text {
        font-size: 16px;
        line-height: 21px;
    }
    .relative_container_for_notification {
        padding: 10px;
    }
    .modal_content_notification_heading_line {
        padding: 0px 10px;
    }
    .modal_content_notification_wrapper_for_row {
        margin-top: 20px;
    }
    .modal_content_notification_img_container {
        width: 90px;
        height: 162px;
    }
    .modal_content_notification_wrapper_for_column_content{
        margin-left: 10px;
    }
    .modal_content_notification_name_line {
        font-size: 14px;
        line-height: 18px;
    }
    .modal_content_notification_color_name .modal_content_notification_black_span {
        font-size: 12px;
    }
    .modal_content_notification_color_name span {
        font-size: 12px;
    }
    .modal_content_notification_color_name .standard_price {
        font-size: 14px;
    }
    .notification_fields_wrapper {
        margin-top: 10px;
    }
    .notification_finally_valid_text{
        font-size: 12px;
    }
    .thank_you_notification {
        font-size: 18px;
        line-height: 23px;
    }
    .thank_text_child {
        font-size: 14px;
        line-height: 18px;
    }
    .notification_finally_button.close {
        margin-top: 35px;
        max-width: 162px;
    }
}