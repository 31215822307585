.shares {
	background: #000000;
}

.shares img {
	display: block;
}

.shares__container {
	padding: 0 40px;
	margin: 0 auto;
	max-width: 1440px;
}

.shares__main {
	padding: 50px 0;
	color: #FFFFFF;
}

.shares__main p {
	color: #FFFFFF;
}

.shares__arrow {
	margin-bottom: 16px;
	fill: #FFFFFF;
	display: block;
}

.shares__arrow._black {
	fill: #000000;
}

.shares__title {
	font-weight: 500;
	font-size: 48px;
	line-height: 62px;
	margin-bottom: 80px;
}

.shares__text {
	font-weight: 300;
	font-size: 24px;
	line-height: 31px;
}

.shares__mainWrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.shares__mainImage {
	flex-basis: 738px;
	margin-right: 32px;
	flex-grow: 1;
}

.shares__mainImage img {
	width: 100%;
	height: 100%;
}

.shares__mainContent {
	flex-basis: 555px;
	flex-grow: 1;
}

.shares__mainTitle {
	font-weight: 600;
	font-size: 48px;
	line-height: 62px;
	color: #FFFFFF;
	margin-top: 87px;
	margin-bottom: 50px;
}

.shares__working {
	padding: 100px 0;
	background-color: #FFFFFF;
}

.shares__workingWrapper {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 20px;
}

.shares__workingNumber {
	width: 44px;
	height: 44px;
	border-radius: 50%;
	border: 2px solid #000000;
	margin-bottom: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 500;
	font-size: 24px;
	line-height: 1;
}

.shares__stages {
	padding: 84px 0 81px;
	color: #FFFFFF;
	background-image: url('./costume.png');
	background-position: center 30px;
	background-repeat: no-repeat;
}

.shares__stages p {
	color: #FFFFFF;
}

.shares__stagesList {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	min-height: 794px;
}

.shares__stagesItem {
	padding: 38px 23px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;

	border-left: 1px solid #FFFFFF;
	border-right: 1px solid transparent;
}

.shares__stagesItem:last-child {
	border-right-color: #FFFFFF;
}

.shares__stagesHead {
	margin-bottom: 40px;
	font-weight: 500;
	font-size: 24px;
	line-height: 1;
}

.shares__stagesIcon {
	margin-bottom: 12px;
}

.shares__stagesText {
	margin-top: 30px;
	opacity: 0.5;
}

.shares__stagesTitle {
	min-height: 93px;
}

.shares__faq {
	padding: 80px 0 71px;
	color: #FFFFFF;
}

.shares__faq p {
	color: #FFFFFF;
}

.shares__faqList {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 50px 21px;
	margin-bottom: 70px;
	grid-auto-flow: column;
	grid-template-rows: repeat(3, 1fr);
}

.shares__faqItemTitle {
	margin-bottom: 24px;
	font-weight: 500;
	font-size: 24px;
	line-height: 31px;
	letter-spacing: -0.075em;
}

.shares__faqText {
	font-size: 16px;
	line-height: 21px;
}

@media only screen and (max-width: 1024px) {
	.shares__collaboration {
		width: 238px;
		height: 28px;
	}

	.shares__mainTitle {
		font-size: 28px;
		line-height: 36px;
		margin-bottom: 24px;
		margin-top: 53px;
	}

	.shares__mainImage {
		margin-right: 20px;
		flex-basis: 432px;
	}

	.shares__mainContent {
		flex-basis: 302px;
	}

	.shares__arrow {
		margin-bottom: 10px;
	}

	.shares__text {
		font-size: 18px;
		line-height: 23px;
	}

	.shares__main {
		padding: 0 0 36px;
	}

	.shares__working {
		padding: 70px 0;
	}

	.shares__title {
		margin-bottom: 40px;
		font-size: 28px;
		line-height: 36px;
	}

	.shares__workingWrapper {
		grid-template-columns: 1fr 1fr;
		gap: 30px 21px;
	}

	.shares__workingNumber {
		margin-bottom: 16px;
	}

	.shares__stages {
		padding: 38px 0 45px;
		background-position-y: 11px;
		background-size: 89%;
	}

	.shares__stagesList {
		min-height: 530px;
	}

	.shares__stagesItem {
		padding: 20px 7px 20px 11px;
	}

	.shares__stagesIcon {
		margin-bottom: 10px;
	}

	.shares__stagesHead {
		margin-bottom: 24px;
		font-size: 18px;
	}

	.shares__stagesText {
		margin-top: 24px;
	}

	.shares__faq {
		padding: 70px 0 60px;
	}

	.shares__faqList {
		gap: 40px 20px;
		margin-bottom: 60px;
	}

	.shares__faqItemTitle {
		font-size: 18px;
		line-height: 23px;
	}

	.shares__faqText {
		font-size: 16px;
		line-height: 21px;
	}
}

@media only screen and (max-width: 768px) {
	.shares__container {
		padding: 0 20px;
	}

	.shares__main {
		padding-bottom: 107px;
	}

	.shares__mainWrapper {
		display: block;
	}

	.shares__arrow {
		margin-bottom: 16px;
	}
	.shares__mainImage {
		margin-right: 0;
	}
	.shares__mainTitle {
		margin: 55px 0 40px;
		font-size: 32px;
		line-height: 42px;
	}

	.shares__title {
		margin-bottom: 70px;
		font-size: 32px;
	}

	.shares__working {
		margin: 0 8px;
	}

	.shares__workingWrapper {
		grid-template-columns: 1fr;
		gap: 50px;
	}

	.shares__stages {
		padding: 60px 0 0;
		background-position: 23% 106px;
		background-size: 1058px 943px;
	}

	.shares__stagesList {
		min-height: auto;
		grid-template-columns: 1fr;
	}

	.shares__stagesItem {
		border: 0;
		border-top: 1px solid #FFFFFF;
		border-bottom: 1px solid transparent;
		padding: 30px 0 39px;
	}

	.shares__stagesItem:last-child {
		border-bottom-color: #FFFFFF;
	}

	.shares__stagesTitle {
		min-height: auto;
	}

	.shares__faq {
		padding-bottom: 50px;
	}

	.shares__faqList {
		grid-template-columns: 1fr;
		gap: 50px;
		margin-bottom: 70px;
		grid-auto-flow: row;
		grid-template-rows: auto;
	}

	.shares__collaboration {
		width: 244px;
		height: 29px;
	}
}
