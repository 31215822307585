.modal_popup_wrapper {
    padding: 10px;
    position: fixed;
    /*background: #d78300;*/
    top: 50%;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.0s ease 0s;
    left: -100%;
    transform: translate(-50%, -50%);
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    max-width: 677px;
}


.modal_popup_wrapper.active {
    z-index: 15;
    opacity: 1;
    transition: opacity 0.6s ease 0.4s;
    left: 50%;
}

.popup_child_wrap {
    border: 1px solid black;
    position: relative;
    background: white;
    width: 100%;
}
.popup_child_wrap.new_variant {
    max-width: 600px;
}
.close_popup {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 20px;
    right: 20px;
    /*background: #6eb8d4;*/
    cursor: pointer;
    z-index: 1;
}
.close_popup:before {
    content: "";
    height: 2px;
    width: 18px;
    background: #ffffff;
    position: absolute;
    right: 1px;
    top: 9px;
    transform: rotate(-45deg);
}

.close_popup:after {
    content: "";
    height: 2px;
    width: 18px;
    background: #ffffff;
    position: absolute;
    right: 1px;
    top: 9px;
    transform: rotate(45deg);
}
.popup_content_wrap {
    display: flex;
    flex-direction: column;
}
.container_banner_popup {
    position: relative;
    padding: 40px;
    width: 100%;
    /*height: 100%;*/
    min-height: 370px;
    /*background: antiquewhite;*/
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
}
.container_banner_popup.two_variant {
    padding: 55px;
    align-items: flex-start;
}
.container_banner_popup.three_variant {
    padding: 60px 55px;
    align-items: flex-start;
    min-height: 420px;
}
.container_banner_popup.three_variant .info_text_banner_popup {
    max-width: 210px;
}
.container_banner_popup.two_variant .info_text_banner_popup {
    max-width: 180px;
}
.container_banner_popup.two_variant .text_banner_bold_line {
    color: black;
}
.container_banner_popup.two_variant .text_banner_one_line {
    color: black;
}
.container_banner_popup.two_variant .popup_promocod {
    color: black;
}
.container_banner_popup.two_variant .button_copy_promocod {
    color: rgb(0, 0, 0);
}
.container_banner_popup.two_variant .button_copy_promocod:hover {
    color: rgba(255, 255, 255, 0.5);
}
.banner_popup {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.info_text_banner_popup {
    /*display: flex;*/
    /*flex-direction: column;*/
    position: relative;
    z-index: 1;
}
.text_banner_bold_line {
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 120%;
    color: #FFFFFF;
}
.text_banner_one_line {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 31px;
    color: #FFFFFF;
    margin-top: 18px;
}
.text_promo_and_copy {
    display: flex;
    align-items: center;
    margin-top: 18px;
}
.popup_promocod {
    font-weight: 500;
    font-size: 30px;
    line-height: 39px;
    text-transform: uppercase;
    color: #FFFFFF;
}
.button_copy_promocod {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
    margin-left: 18px;
}
.button_copy_promocod:hover {
    color: #ffffff;
}
.container_fields_popup {
    padding: 40px;
    display: flex;
    /*align-items: center;*/
    justify-content: center;
    width: 100%;
    /*min-height: 657px;*/
    min-height: 500px;
}
.container_fields_popup.new_disain {
    min-height: 438px;
}
.child_fields_info {
    max-width: 483px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.title_popup_fields {
    font-weight: 500;
    font-size: 30px;
    line-height: 39px;
    color: #1A1A1A;
}
.fields_and_sending {

}
.container_email_fields {
    display: flex;
    flex-direction: column;
    margin-top: 18px;
    font-weight: 500;
    font-size: 16px;
    color: #1A1A1A;
    position: relative;
}
.error_massage_popup {
    font-size: 14px;
    color: #CE3F3F;
    margin-top: 5px;
    font-weight: 500;
    line-height: 16px;
    height: 30px;
}
.email_fields_input_popup {
    height: 44px;
    padding-bottom: 4px;
    padding-left: 5px;
    width: 100%;
    border: 1px solid #D7D6D6;
    margin-top: 7px;
}
.container_button_modal {
    display: flex;
}
.container_button_modal .button_close_popup {
    margin-left: 22px;
    max-width: 135px;
}
.button_sending_popup {
    width: 100%;
    background: black;
    padding: 15px;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 18px;
    user-select: auto;
    cursor: pointer;
    transition: background 0.4s ease 0s;
}
.button_sending_popup.unactive {
    background: #757575;
    transition: background 0.4s ease 0s;
    user-select: none;
}

.button_close_popup {
    width: 100%;
    background: #ffffff;
    padding: 15px;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #000000;
    border: 1px solid black;
    margin-top: 18px;
    cursor: pointer;
}
.button_close_popup.mobile {
    text-decoration-line: underline;
    border: 1px solid transparent;
}
.confirm_selector_popup {
    width: 15px;
    height: 14px;
    border: 1px solid #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 3px;
}
.child_confirm_selector_popup {
    width: 8px;
    height: 8px;
    background: #1A1A1A;
    /*border-radius: 50%;*/
    opacity: 0;
    transition: opacity 0.4s ease 0s;
    position: relative;
}
/*.child_confirm_selector_popup:before {*/
/*    position: absolute;*/
/*    content: "";*/
/*    width: 6px;*/
/*    height: 2px;*/
/*    background: #ffffff;*/
/*    transform: rotate(45deg);*/
/*    top: 12px;*/
/*    left: 6px;*/
/*}*/
/*.child_confirm_selector_popup:after {*/
/*    position: absolute;*/
/*    content: "";*/
/*    width: 9px;*/
/*    height: 2px;*/
/*    background: #ffffff;*/
/*    transform: rotate(-45deg);*/
/*    top: 11px;*/
/*    left: 9px;*/
/*}*/
.confirm_selector_popup.active .child_confirm_selector_popup {
    opacity: 1;
    transition: opacity 0.4s ease 0s;
}
.politic_popup_wrapper {
    display: flex;
    flex-direction: column;
}
.link_politic_popup {
    margin-top: 18px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    /*text-align: center;*/
    color: #C7C7C7;
    display: flex;
    align-items: flex-start;
}
.link_politic_popup span{
    color: #C7C7C7;
    margin-left: 15px;
    width: 100%;
}
.link_politic_popup a{
    color: black;
    text-decoration: none;
}
.link_politic_popup a:hover{
    border-bottom: 1px solid black ;
}



@media (max-width: 1024px) {
    .container_fields_popup {
        min-height: 530px;
    }
    .title_popup_fields {
        font-size: 24px;
    }
}


@media (max-width: 768px) {
    .container_banner_popup {
        min-height: 250px;
        padding: 25px 20px;
    }
    .text_banner_bold_line {
        font-size: 19px;
    }
    .text_banner_one_line {
        font-size: 18px;
        line-height: 23px;
        margin-top: 10px;
    }
    .text_promo_and_copy {
        margin-top: 10px;
    }
    .button_copy_promocod {
        margin-left: 10px;
    }
    .popup_promocod {
        font-size: 24px;
        line-height: 31px;
    }
    .container_fields_popup {
        padding: 20px;
        min-height: 400px;
    }
    .title_popup_fields {
        font-size: 18px;
        line-height: 23px;
    }
    .error_massage_popup {
        font-size: 12px;
        height: 15px;
    }


    .container_banner_popup.two_variant {
        padding: 40px 15px;
    }
    .container_fields_popup.new_disain {
        min-height: 380px;
    }

    .container_banner_popup.three_variant {
        padding: 40px 20px;
        min-height: 299px;
    }

    .close_popup {
        top: 10px;
        right: 10px;
    }


    .email_fields_input_popup {
        height: 40px;
    }
    .button_sending_popup {
        margin-top: 15px;
    }
    .button_close_popup {
        margin-top: 5px;
    }

}

@media (max-width: 360px) {
    .container_banner_popup {
        min-height: 195px;
    }
    .text_banner_one_line {
        font-size: 16px;
    }
    .popup_promocod {
        font-size: 20px;
    }
    .button_copy_promocod {
        font-size: 10px;
    }
    .container_fields_popup {
        padding: 10px;
        min-height: 345px;
    }
    .container_email_fields {
        margin-top: 10px;
        font-size: 14px;
    }
    .email_fields_input_popup {
        height: 35px;
    }
    .button_sending_popup  {
        padding: 10px;
        margin-top: 10px;
    }
    .button_close_popup {
        padding: 10px;
        margin-top: 10px;
    }
    .link_politic_popup {
        font-size: 11px;
    }
    .container_fields_popup.new_disain {
        min-height: 200px;
    }

    .container_banner_popup.three_variant {
        padding: 40px 20px;
        min-height: 200px;
    }

}