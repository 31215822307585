.stock_parent {
    max-width: 950px;
    width: 100%;
    height: 475px;
    padding: 10px;
}
.stock_child {
    width: 100%;
    height: 100%;
    position: relative;
}
.stock_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.stock_absolute {
   position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 1;
}

.stock_child:hover   .stock_absolute:after {
    width: 100%;
    transition: width 0.4s ease 0s;
}

.stock_absolute:after {
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    width: 0px;
    height: 100%;
    background: black;
    z-index: -1;
    transition: width 0.4s ease 0s;
}

.stock_black_back {
    padding: 20px 30px;
    cursor: pointer;
}

.stock_subline_sale {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
}


@media (max-width: 1024px) {
    .stock_parent {
        height: 384px;
        padding: 10px 0px;
    }
}
@media (max-width: 767px) {
    .stock_parent {
        height: 360px;
        padding: 5px 0px;
    }
    .stock_black_back {
        text-align: center;
    }
}
@media (max-width: 427px) {
    .stock_parent {
        height: 270px;
    }
    .stock_black_back {
        padding: 15px 20px;
    }
    .stock_subline_sale {
        font-size: 16px;
    }
}
