.social_icons_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    opacity: 0.5;
}
.social_icons_wrapper.active {
    opacity: 1;
}

.name_social_icons {
    margin-top: 5px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.name_checkbox_wrapper {

}
.social_icons_name {
    margin-top: 5px;
    font-weight: 600;
    font-size: 12px;
}
