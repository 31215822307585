
.parent_cards {
    display: flex;
    /*height: 475px;*/
    margin: 0 auto;
    justify-content: center;
    margin-top: 40px;
}

.parent_relative {
    width: 100%;
    display: flex;
}

/*.parent_absolut {*/
/*position: absolute;*/
/*top: -224px;*/
/*width: 100%;*/
/*}*/
.parent_conteiner {
    display: flex;
    justify-content: center;
}

.cards_title_delivery {
    font-weight: 500;
    padding: 18px 40px;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
}

.shipping_methods {
    /*max-width: 450px;*/
    min-height: 442px;
    width: 100%;
    background-color: white;
    border: 2px solid #000000;
    /*box-shadow: 0px 0px 20px rgb(0 0 0 / 15%);*/
    display: flex;
    flex-direction: column;
    margin-left: 47px;

}

.shipping_methods:first-child {
    margin-left: 0px;
}

.child_shipping_methods {
    height: 100%;
    flex-direction: column;
    padding: 18px 40px;
    /*display: none;*/
    justify-content: space-between;
    display: flex;
    border-top: 2px solid #000000;
}

/*.child_shipping_methods.active {*/
/*    display: flex;*/
/*}*/


.child_shipping_btn {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #000000;
    max-width: 143px;
    padding: 14px;
    border: 1px solid #1A1A1A;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    /*margin-top: 134px;*/
    justify-content: center;
    margin-bottom: 30px;
}

.child_shipping_btn:hover {
    color: white;
    background-color: black;
    transition: background-color 0.4s ease 0s, color 0.4s ease 0s;
}


/*.border_shipping {*/
/*    width: 100%;*/
/*    height: 1px;*/
/*    background-color: #000000;*/
/*    display: none;*/
/*    justify-content: space-between;*/
/*}*/
/*.border_shipping.active {*/
/*    display: flex;*/
/*}*/
.subtitle_conteiner {
    display: flex;
    flex-direction: column;
    margin-top: 18px;
}

.subtitle_conteiner:first-child {
    margin-top: 0px;
}


.subtitle_delivery {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #1A1A1A;
    padding-bottom: 10px;
}

.text_delivery {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #757575;
}

.two_parents_cards {
    margin-top: 40px;
}

.parent_two_cards {
    position: relative;
    margin: 0 auto;
    /*border:  1px solid #000000;*/
    /*box-shadow: 0px 0px 20px rgb(0 0 0 / 15%);*/
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 40px;
}

.conteiner_msc {
    width: 100%;
    /*height: 100%;*/
}

.conteiner_msc.express {
    width: 50%;
}

.child_msc {
    display: flex;
    flex-direction: column;
    background: white;
    height: 100%;
}

.child_msc.express {
    /*border-left: 1px solid #000000;*/
}

.content_moscow {
    display: flex;
    /*border-top: 1px solid #000000;*/
    width: 100%;
    height: 100%;
}

/*.content_moscow.active {*/
/*    display: flex;*/
/*}*/
.conteiner_moscow.express {
    background-color: #FAFAFA;
    height: 100%;
}

.title_moscow {
    font-weight: 500;
    font-size: 24px;
    line-height: 31px;
    color: #000000;
    padding: 18px 40px;
    position: relative;
    /*border-right: 1px solid #000000;*/
    box-shadow: 2px 0 0 0 #000, 0 2px 0 0 #000, 2px 2px 0 0 #000, 2px 0 0 0 #000 inset, 0 2px 0 0 #000 inset;
}

.title_moscow.express {
    /*color: #248F5C;*/

}

.child_moscow {
    display: flex;
    width: 100%;
    /*height: 100%;*/
}

/*.child_moscow.border:last-child {*/
/*    border-left: 1px solid #000000;*/
/*}*/

.conteiner_subline_child {
    padding: 18px 40px;
    /*background-color: #F2F2F2;*/
    border-bottom: 2px solid #000000;
}

.mkad_subtitle {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
}

.cards_moscow {
    padding: 18px 40px;
    display: flex;
    /*height: 100%;*/
}

.wrap_moscow {
    width: 100%;
    height: 100%;
}

.subline_moscow {
    width: 100%;
    height: 100%;
    box-shadow: 2px 0 0 0 #000, 0 2px 0 0 #000, 2px 2px 0 0 #000, 2px 0 0 0 #000 inset, 0 2px 0 0 #000 inset;
}


@media (max-width: 1024px) {
    .title_moscow.express {
        padding: 0;
        border: 0;
        box-shadow: none;
        margin-bottom: 20px;
    }
    .mkad_subtitle {
        transition: .3s;
    }
    .conteiner_subline_child {
        cursor: pointer;
        position: relative;
        border-bottom-width: 0;
    }
    .conteiner_subline_child::after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='11' viewBox='0 0 18 11' fill='none'%3E%3Cpath d='M17.2129 1.21484L8.99887 9.42887L0.784844 1.21484' stroke='black' stroke-width='1.5'/%3E%3C/svg%3E");
        position: absolute;
        content: '';
        right: 19px;
        top: 50%;
        transform: translateY(-50%);
        transition: .3s;
        width: 18px;
        height: 11px;
    }

    .conteiner_subline_child._active {
        border-bottom-width: 2px;
        background: #1A1A1A;
        color: #FFFFFF;
    }
    .conteiner_subline_child._active .mkad_subtitle {
        color: #FFFFFF;
    }
    .conteiner_subline_child._active::after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='11' viewBox='0 0 18 11' fill='none'%3E%3Cpath d='M0.785156 9.78516L8.99918 1.57113L17.2132 9.78516' stroke='white' stroke-width='1.5'/%3E%3C/svg%3E");
    }
    .content_moscow {
        flex-direction: column;
    }

    .child_moscow {

    }

    .title_delivery {
        font-weight: 500;
        font-size: 24px;
        line-height: 31px;
        color: #000000;
    }

    .child_shipping_button {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .parent_cards {
        padding-bottom: 0;
    }

    /* SCROLLBAR */
    .parent_cards::-webkit-scrollbar {
        height: 3px;
    }

    /*Track*/
    .parent_cards::-webkit-scrollbar-track {
        background: #757575;
        border: 1px solid transparent;
        background-clip: content-box;
    }

    /* Handle */
    .parent_cards::-webkit-scrollbar-thumb {
        background: rgb(0, 0, 0);
        border: 1px solid rgb(0, 0, 0);
    }

    .parent_conteiner {
        width: 100%;
        flex-direction: column;
    }

    .shipping_methods {
        margin-left: 0;
        border-bottom-width: 0;
        min-height: auto;
    }

    .shipping_methods:last-child {
        border-bottom-width: 2px;
    }

    .text_delivery {
        line-height: 18px;
    }

    .parent_two_cards {
        border: 1px solid transparent;
    }

    .child_msc {

    }

    .conteiner_msc.express {
        width: 100%;
        margin-top: 40px;
    }

    .wrap_moscow {

    }

    .wrap_moscow.delivery_express {
        max-width: 750px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    .wrap_moscow.delivery_express .subtitle_conteiner {
        margin-top: 0px;
        margin-right: 130px;
        padding-bottom: 20px;
        max-width: 160px;
        width: 100%;
    }

    .cards_title_delivery {
        cursor: pointer;
        position: relative;
    }

    .cards_title_delivery::after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='11' viewBox='0 0 18 11' fill='none'%3E%3Cpath d='M17.2129 1.21484L8.99887 9.42887L0.784844 1.21484' stroke='black' stroke-width='1.5'/%3E%3C/svg%3E");
        position: absolute;
        content: '';
        right: 19px;
        top: 50%;
        transform: translateY(-50%);
        transition: .3s;
        width: 18px;
        height: 11px;
    }

    .cards_title_delivery._active {
        background: #1A1A1A;
        color: #FFFFFF;
    }

    .cards_title_delivery._active::after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='11' viewBox='0 0 18 11' fill='none'%3E%3Cpath d='M0.785156 9.78516L8.99918 1.57113L17.2132 9.78516' stroke='white' stroke-width='1.5'/%3E%3C/svg%3E");
    }
}

@media (max-width: 768px) {


    .title_delivery {
        font-size: 26px;
        line-height: 34px;
    }

    .cards_title_delivery {
        padding: 18px 20px;
    }

    .child_shipping_methods {
        padding: 18px 20px;
    }

    .parent_cards {
    }

    .shipping_methods {
    }

    .title_moscow {
        box-shadow: unset;
        padding: 0px;
    }

    .content_moscow {
    }

    .content_moscow.scroll_container {
    }

    /* SCROLLBAR */
    .content_moscow::-webkit-scrollbar {
        height: 3px;
    }

    /*Track*/
    .content_moscow::-webkit-scrollbar-track {
        background: #757575;
        border: 1px solid transparent;
        background-clip: content-box;
    }

    /* Handle */
    .content_moscow::-webkit-scrollbar-thumb {
        background: rgb(0, 0, 0);
        border: 1px solid rgb(0, 0, 0);
    }

    .subline_moscow {
        box-shadow: 2px 0 0 0 #000, 0 2px 0 0 #000, 2px 2px 0 0 #000, 2px 0 0 0 #000 inset, 0 2px 0 0 #000 inset;
    }

    .subline_moscow.content_scroll {
    }

    .conteiner_subline_child {
        padding: 18px 20px;
    }

    .child_moscow.content_scroll {
        margin-right: 20px;
    }

    .cards_moscow {
        padding: 20px;
    }

    .text_delivery {
        font-size: 16px;
    }

    .subtitle_delivery {
        padding-bottom: 0px;
    }
    .title_msc_arrows {
        font-size: 24px;
        line-height: 31px;
    }

    /*.parent_absolut {*/
    /*    top: -95px;*/
    /*}*/
    /*.parent_two_cards {*/
    /*    border: unset;*/
    /*    box-shadow: unset;*/
    /*}*/
    /*.conteiner_msc {*/
    /*    border: 1px solid #E0E0E0;*/
    /*    box-shadow: 0px 0px 20px rgb(0 0 0 / 15%);*/
    /*    margin: 10px 0px;*/
    /*}*/
    /*.conteiner_msc.express {*/
    /*    border: 1px solid #E0E0E0;*/
    /*    box-shadow: 0px 0px 20px rgb(0 0 0 / 15%);*/
    /*}*/
    /*.cards_title_delivery {*/
    /*    position: relative;*/
    /*    cursor: pointer;*/
    /*    padding: 25px 15px;*/
    /*    font-size: 16px;*/
    /*}*/
    /*.cards_title_delivery::after {*/
    /*    position: absolute;*/
    /*    content: "";*/
    /*    width: 10px;*/
    /*    height: 2px;*/
    /*    right: 21px;*/
    /*    top: 50%;*/
    /*    background-color: black;*/
    /*    border-radius: 10px;*/
    /*    transform: rotate(45deg);*/
    /*}*/
    /*.cards_title_delivery::before {*/
    /*    position: absolute;*/
    /*    content: "";*/
    /*    width: 10px;*/
    /*    height: 2px;*/
    /*    right: 15px;*/
    /*    top: 50%;*/
    /*    background-color: black;*/
    /*    border-radius: 10px;*/
    /*    transform: rotate(-45deg);*/
    /*}*/
    /*.cards_title_delivery.close::after {*/
    /*    position: absolute;*/
    /*    content: "";*/
    /*    width: 10px;*/
    /*    height: 2px;*/
    /*    right: 21px;*/
    /*    top: 50%;*/
    /*    background-color: black;*/
    /*    border-radius: 10px;*/
    /*    transform: rotate(-45deg);*/
    /*}*/
    /*.cards_title_delivery.close::before {*/
    /*    position: absolute;*/
    /*    content: "";*/
    /*    width: 10px;*/
    /*    height: 2px;*/
    /*    right: 15px;*/
    /*    top: 50%;*/
    /*    background-color: black;*/
    /*    border-radius: 10px;*/
    /*    transform: rotate(45deg);*/
    /*}*/
    /*.title_moscow {*/
    /*    padding: 25px 15px;*/
    /*    font-size: 16px;*/
    /*}*/
    /*.title_msc_arrows {*/
    /*    cursor: pointer;*/
    /*    position: relative;*/
    /*    display: flex;*/
    /*    padding-right: 20px;*/
    /*}*/
    /*.title_msc_arrows::after {*/
    /*    position: absolute;*/
    /*    content: "";*/
    /*    width: 10px;*/
    /*    height: 2px;*/
    /*    right: 6px;*/
    /*    top: 50%;*/
    /*    background-color: black;*/
    /*    border-radius: 10px;*/
    /*    transform: rotate(45deg);*/
    /*}*/
    /*.title_msc_arrows::before {*/
    /*    position: absolute;*/
    /*    content: "";*/
    /*    width: 10px;*/
    /*    height: 2px;*/
    /*    right: 0px;*/
    /*    top: 50%;*/
    /*    background-color: black;*/
    /*    border-radius: 10px;*/
    /*    transform: rotate(-45deg);*/
    /*}*/
    /*.title_msc_arrows.close::after {*/
    /*    position: absolute;*/
    /*    content: "";*/
    /*    width: 10px;*/
    /*    height: 2px;*/
    /*    right: 6px;*/
    /*    top: 50%;*/
    /*    background-color: black;*/
    /*    border-radius: 10px;*/
    /*    transform: rotate(-45deg);*/
    /*}*/
    /*.title_msc_arrows.close::before {*/
    /*    position: absolute;*/
    /*    content: "";*/
    /*    width: 10px;*/
    /*    height: 2px;*/
    /*    right: 0px;*/
    /*    top: 50%;*/
    /*    background-color: black;*/
    /*    border-radius: 10px;*/
    /*    transform: rotate(45deg);*/
    /*}*/
    /*.delivery_arrows_mob {*/
    /*    padding-right: 20px;*/
    /*    width: 100%;*/
    /*}*/
    /*.content_moscow {*/
    /*    display: none;*/
    /*}*/
    /*.content_moscow.active {*/
    /*    display: flex;*/
    /*}*/
    /*.two_parents_cards {*/
    /*    margin-top: 0px;*/
    /*}*/
    /*.text_delivery {*/
    /*    font-size: 14px;*/
    /*}*/
    /*.parent_cards {*/
    /*    margin-top: 20px;*/
    /*}*/
    /*.shipping_methods {*/
    /*    width: 330px;*/
    /*}*/
    /*.cards_title_delivery {*/
    /*    padding: 18px 20px;*/
    /*}*/
}

