.breadcrumbs_for_url_params {
    display: flex;
    width: 100%;
    /*height: 91px;*/
    padding: 30px 40px;
    align-items: center;
    flex-wrap: wrap;
}
.url_breadcrumbs_parent {
    display: flex;
}
.url_breadcrumbs_element {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    /*text-transform: uppercase;*/
    color: #000000;
    opacity: 0.8;
    padding-right: 25px;
    position: relative;
    text-decoration: underline;
}

/*.url_breadcrumbs_element::before{*/
/*    content: "";*/
/*    height: 1px;*/
/*    width: 7px;*/
/*    background: black;*/
/*    position: absolute;*/
/*    right: 15px;*/
/*    top: 10px;*/
/*    transform: rotate(-45deg);*/
/*}*/
.url_breadcrumbs_element::after{
    content: "";
    height: 1px;
    width: 11px;
    background: #6C757D;
    opacity: 0.7;
    position: absolute;
    right: 7px;
    top: 6px;
    transform: rotate(-70deg);
}


.url_breadcrumbs_element_wrapper:last-child .url_breadcrumbs_element {
    color: #000000;
    pointer-events: none;
    padding-right: 0px;
    text-decoration-line: none;
}
.url_breadcrumbs_element_wrapper:last-child .url_breadcrumbs_element::after {
    display: none;
}
.url_breadcrumbs_element:last-child::before {
    display: none;
}


@media (max-width: 1024px) {
    .breadcrumbs_for_url_params {
        padding: 20px;
    }
}
