

.landrys_item_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.one_landry {
    display: flex;
    width: 350px;
    height: 270px;
    border: 1px solid #c9cccf;
    margin: 10px;
    margin-top: 20px;
    flex-direction: column;
    position: relative;
}
.one_landry.del {
    opacity: 0;
    filter: blur(4px);
    transition: opacity 0.7s ease 0s, filter 0.6s ease 0s;
}

.id_landry {
    height: 30px;
    width: 30px;
    background-color: whitesmoke;
    display: flex;
    justify-content: center;
    color: black;
    align-items: center;
    font-weight: 600;
}
.one_landry_dashboard {
    display: flex;
    height: 30px;
    align-items: center;
    width: 100%;
}
.span_delete_landry {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-weight: 600;
    cursor: pointer;
    background-color: rgba(208, 9, 9, 0.23);
    text-transform: uppercase;
    font-size: 12px;
}

.span_delete_landry:hover {
    background-color: black;
    color: white;
}
.wrapper_field_op {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
    padding:0px 10px;
}
.name_field_op {
    font-size: 14px;
    /*text-transform: uppercase;*/
    font-weight: 600;

}

.field_op {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #c9cccf;
    font-weight: 600;
    font-size: 12px;
    padding: 5px 5px;
    border-radius: 5px;
    margin-top: 10px;
}
.images_container_landry {
    display: flex;
    margin-top: 10px;
    width: 100%;
    padding: 0px 10px;
}
.content_img_cont {
    border: 1px solid #c9cccf;
    display: flex;
    min-height: 100px;
    width: 100%;
    padding: 10px;
    justify-content: center;
}

.one_img_container_landr {
    width: 100%;
    height: 100%;
    display: flex;
    border: 1px solid #c9cccf;
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;
    font-weight: 600;
    font-size: 12px;
}

.drop_area_landry {
    position: absolute;
    display: flex;
    opacity: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
}

.save_landry_btn {
    max-width: 328px;
    margin: 0 auto;
    display: flex;
    border: 1px solid #c9cccf;
    width: 100%;
    margin-top: 10px;
    height: 40px;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    cursor: pointer;
}

.save_landry_btn.trig {
    animation: trigSave 2s ease infinite;
}

@keyframes trigSave {
    0% {
        background-color: white;
        color: black;
    }
    50% {
        background-color: black;
        color: white;
    }
    100% {
        background-color: white;
        color: black;
    }
}