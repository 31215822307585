.title_favorites {
    font-weight: 700;
    font-size: 36px;
    color: #1A1A1A;
}
.favorites_product_container {
    display:flex;
    flex-wrap: wrap;
    margin-top: 50px;
}
