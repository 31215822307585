.ShadowOverlay {
    position: fixed;
    /*background: #000000;*/
    /*opacity: 0;*/
    height: 150vh;
    width: 100%;
    z-index: 9;
    right: -100%;
    top: 0px;
    transition: right 0.4s ease 0s;
    cursor: pointer;
}
.ShadowOverlay.active {
    right: 0px;
    /*opacity: 0.6;*/
    /*transition: right 0s ease 0s,opacity 0.8s ease 0s;*/
    transition: right 0s ease 0s,background 0.8s ease 0s;
    /*background: url("/files/images/shadowOverlay.png");*/
}

@media (max-width: 1300px) {
    .ShadowOverlay.active.nonclick {
        z-index: 11;
    }
}
