.stocks-trends {
    position: relative;
    width: 100%;
    height: 100%;
}
.st_bread {
    padding-bottom: 100px;
}
.st_content {
    max-width: 1920px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.st_title_page {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    text-align: center;
    color: #1A1A1A;
}
.stocks_wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 120px;
}
.paginaciya {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    margin: 40px 0px;
}
.click_page {
    width: 30px;
    height: 30px;
    background-color: black;
    color: white;
    margin-left: 10px;
    border: 2px solid transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
    border-radius: 50%;
}
.click_page:first-child {
    margin-left: 0px;
}
.click_page.active {
    background-color: white;
    border: 2px solid black;
    color: black;
    transition: 0.4s ease 0s;
}
.click_page:hover {
    background-color: white;
    border: 2px solid black;
    color: black;
    transition: 0.4s ease 0s;
}
.total_page {
    margin-left: 10px;
}


@media (max-width: 1024px)  {
    .stocks-trends {
        height: auto;
    }
    .st_content {
        margin-top: 45px;
    }
    .st_title_page{
        font-size: 48px;
    }
    .stocks_wrap {
        margin-top: 50px;
    }
    .paginaciya {
        margin: 30px 0px;
    }
}
@media (max-width: 767px) {
    .st_content {
        margin-top: 35px;
    }
    .st_title_page {
        font-size: 34px;
    }
    .stocks_wrap {
        margin-top: 30px;
    }
}
@media (max-width: 427px) {
    .st_content {
        margin-top: 30px;
    }
    .st_title_page {
        font-size: 26px;
    }
}