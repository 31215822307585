.one_page_stocks {
    width: 100%;
    /*height: 100%;*/
    margin: 0 auto;
}
.ops_content_wrap {
    position: relative;
}
.ops_img_text {
    display: flex;
}

.ops_banner_wrap {
    width: 60%;
    z-index: -1;
    top: -165px;
    position: relative;
}
.ops_banner {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.ops_text_wrap {

}
.ops_text {
    display: flex;
    flex-direction: column;
    max-width: 606px;
    min-height: 627px;
    width: 100%;
    padding: 0px 20px;
}
.ops_title {
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    color: #1A1A1A;
}
.ops_check {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    margin-top: 30px;
}
.ops_check.active {
    height: 550px;
    overflow: scroll;
    padding-right: 30px;
    padding-bottom: 0px;
}
.ops_check.active::-webkit-scrollbar {
    background-color: transparent;
    width: 5px;
}
.ops_check.active::-webkit-scrollbar-thumb {
    background-color: black;
}
.ops_style_text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #000000;
    margin-top: 10px;
}
.ops_style_text:first-child {
    margin-top: 0px;
}
.ops_text_child {
    width: 100%;
    height: 100%;
}
.ops_button {
    margin-top: 124px;
    display: flex;
}

.ops_style_button {
    padding: 21px 78px;
    max-width: 225px;
    width: 100%;
    border: 1px solid black;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    color: #1A1A1A;
    cursor: pointer;
    margin-left: 30px;
}

.ops_style_button.left {
    padding: 21px 86px;
}
.ops_style_button:first-child {
    margin-left: 0px;
}
.ops_style_button:hover {
    color: white;
    background-color: black;
    transition: background-color 0.4s ease 0s, color 0.4s ease 0s;
}

.ops_content_img {
    max-width: 1424px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding-bottom: 204px;

}
.ops_dop_photo {
    width: 50%;
    height: 100%;
    object-fit: cover;
    padding: 20px;
}



@media (max-width: 1450px) {
    .ops_banner_wrap {
        top: 0px;
    }

}
@media (max-width: 1024px) {
    .one_page_stocks {
        height: auto;
    }
    .ops_content_img {
        display: none;
        padding-bottom: 80px;
    }
    .ops_img_text {
        flex-direction: column;
    }
    .ops_banner_wrap {
        width: 100%;
        height: 460px;
    }
    .ops_text_wrap {
        margin-top: 35px;
    }
    .ops_text {
        max-width: unset;
        min-height: unset;
        margin-bottom: 80px;
    }
    .ops_title {
        font-size: 36px;
    }
    .ops_check {
        margin-top: 20px;
    }
    .ops_button {
        margin-top: 40px;
    }
}
@media (max-width: 768px) {
    .ops_content_img {
        padding-bottom: 50px;
    }
    .ops_text_wrap {
        margin-top: 25px;
    }
    .ops_title {
        font-size: 18px;
    }
    .ops_check {
        margin-top: 12px;
    }
    .ops_style_text {
        font-size: 14px;
        line-height: 22px;
    }
    .ops_button {
        margin-top: 30px;
    }
    .ops_style_button.left, .ops_style_button {
        padding: 15px 30px;
        font-size: 12px;
    }
    .ops_text {
        margin-bottom: 50px;
    }

}