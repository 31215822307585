.like_wrapper {
    display: flex;
    /*background: #E69191;*/
}
.like_wrapper.active  {
    /*background: #00ff25;*/
}
/*.like_wrapper svg path {*/
/*    fill: white;*/
/*}*/
/*.like_wrapper:hover svg path {*/
/*    fill: black;*/
/*}*/
.like_wrapper svg path {
    fill: transparent;
}
.like_wrapper.active svg path {
    fill: black;
}



@media (max-width: 1024px) {
    .like_wrapper {
        max-width: 25px;
        max-height: 22px;
    }
    /*.like_wrapper:hover svg path {*/
    /*    fill: transparent;*/
    /*}*/

}