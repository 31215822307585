

.page_order_hm {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 40px 20px;
}

.wrapper_page_order {
    display: flex;
    height: 100%;
    width: 100%;
    position: relative;
    justify-content: center;
    align-items: center;
}
.order_notificator {
    display: flex;
    max-width: 320px;
    width: 100%;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
}
.circle_success {
    display: flex;
    width: 100px;
    height: 100px;
    border: 3px solid black;
    border-radius: 50%;
    position: relative;
}

.circle_success:after {
    content: "";
    display: flex;
    height: 45px;
    width: 4px;
    background-color: black;
    position: absolute;
    top: 28px;
    left: 55px;
    transform: rotate(45deg);
    border-radius: 10px;
}

.circle_success:before {
    content: "";
    display: flex;
    height: 23px;
    width: 4px;
    background-color: black;
    position: absolute;
    top: 47px;
    left: 33px;
    transform: rotate(-45deg);
    border-radius: 10px;
}
.thank_you_for_order {
    font-weight: 700;
    font-size: 33px;
    line-height: 40px;
    text-align: center;
    color: #1A1A1A;
    margin-top: 40px;
}
.thank_text_container {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: 40px;
    padding: 0px 5px;
    margin-bottom: 20px;
}
.thank_text_child {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000000;
}

.order_page_go_catalog {
    text-decoration: none;
    display: flex;
    width: 100%;
    height: 50px;
    flex-direction: column;
    justify-content: center;
    background-color: black;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    cursor: pointer;
    border: 1px solid black;
}
.order_page_go_catalog:hover {
    background-color: white;
    color: black;
    transition: background-color 0.4s ease 0s, color 0.4s ease 0s;
}

.order_page_go_catalog.invers {
    text-decoration: none;
    display: flex;
    width: 100%;
    height: 50px;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: black;
    cursor: pointer;
    margin-top: 40px;
    border: 1px solid black;
}
.order_page_go_catalog.invers:hover {
    background-color: black;
    color: white;
    transition: background-color 0.4s ease 0s, color 0.4s ease 0s;
}

.confirm_page_order_pay_notif {
    display: flex;
    /*height: 40px;*/
    justify-content: center;
    align-items: center;
    color: green;
    font-weight: 600;
}

.confirm_page_order_pay_notif_fail {
    display: flex;
    /*height: 40px;*/
    justify-content: center;
    align-items: center;
    color: red;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 600
}

.confirm_page_order_pay_notif.active {
    height: 60px;
    transition: height 0.4s ease 0s;
}

.payment_button_order_page {
    margin-bottom: 20px;
    text-decoration: none;
    display: flex;
    width: 100%;
    height: 50px;
    flex-direction: column;
    justify-content: center;
    background-color: black;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    cursor: pointer;
    margin-top: 40px;
    border: 1px solid black;
}

.payment_button_order_page:hover {
    background-color: white;
    color: black;
    transition: background-color 0.4s ease 0s, color 0.4s ease 0s;
}
.pay_sertificate_modal {
    display: flex;
    position: fixed;
    z-index: 15;
    background-color: white;
    max-width: 911px;
    width: 100%;
    min-height: 322px;
    border: 2px solid black;
    flex-direction: column;
    max-height: 50%;
    overflow-y: auto;
}
.pay_sertificate_button{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    background: #1A1A1A;
    color: white;
    cursor: pointer;
    margin: 20px 40px;
    width: auto;
    min-height: 45px;
}
.pay_sert_close {
    width: 20px;
    height: 20px;
    position: absolute;
    right: -25px;
    top: -25px;
    cursor: pointer;
}
.pay_sert_close_child {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.pay_sert_close_child:after {
    content: " ";
    position: absolute;
    width: 15px;
    height: 2px;
    transform: rotate(45deg);
    background-color: #FFFFFF;
    top: 9px;
    right: 2px;
    border-radius: 10px;
}
.pay_sert_close_child::before {
    content: " ";
    position: absolute;
    width: 15px;
    height: 2px;
    transform: rotate(-45deg);
    background-color: #FFFFFF;
    top: 9px;
    right: 2px;
    border-radius: 10px;
}
.pay_sertificate_modal_head {
    padding: 20px 40px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid black;
}
.pay_sertificate_modal_head span {
    font-weight: 600;
    font-size: 30px;
    line-height: 39px;
    color: #000000;
}
.wrapper_for_sertificate_pay_input {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding-left: 40px;
    padding-right: 40px;
    margin-top: 64px;
}
.support_pay_input {
    display: flex;
    width: 100%;
    justify-content: space-between;

}
.notif_pay_input {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
     color: #000000;
}
.confirm_sertificate_button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 171px;
    height: 44px;
    margin-top: 7px;
    margin-left: 20px;
    background: #1A1A1A;
    color: white;
    cursor: pointer;
}

.confirm_sertificate_button:hover {
    background-color: white;
    color:black;
    border: 1px solid black;
}

.notification_sertif_pay_text {
    margin-top: 10px;
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #EE0202;
}

.certificate_prepay_message {
    margin: 20px 45px 10px 45px;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: green;
}

.success_cert {
    background-color: rgba(1, 165, 1, 0.37);
}

@media (max-width: 1024px) {
    .pay_sertificate_modal {
        width: 90%;
    }
    .pay_sertificate_modal_head span {
        font-size: 24px;
    }
}

@media (max-width: 768px) {
    .page_order_hm {
        height: auto;
        margin-bottom: 40px;
    }
    .order_notificator {
        max-width: 300px;
    }
    .circle_success {
        width: 50px;
        height: 50px;
    }
    .circle_success:after {
        content: "";
        display: flex;
        height: 25px;
        width: 3px;
        background-color: black;
        position: absolute;
        top: 10px;
        left: 26px;
        transform: rotate(45deg);
        border-radius: 10px;
    }

    .circle_success:before {
        content: "";
        display: flex;
        height: 13px;
        width: 3px;
        background-color: black;
        position: absolute;
        top: 19px;
        left: 13px;
        transform: rotate(-45deg);
        border-radius: 10px;
    }
    .thank_you_for_order {
        margin-top: 15px;
        font-size: 26px;
    }
    .thank_text_container {
        margin-top: 10px;
    }
    .thank_text_child {
        font-size: 14px;
    }
    .payment_button_order_page {
        margin-top: 10px;
    }
    .order_page_go_catalog.invers {
        margin-top: 20px;
    }

    .pay_sertificate_modal {
        min-height: 300px;
    }
    .pay_sertificate_modal_head {
        padding: 20px;
    }
    .pay_sertificate_modal_head span {
        font-size: 18px;
    }
    .wrapper_for_sertificate_pay_input {
        margin-top: 25px;
        padding: 0px 20px;
    }
    .support_pay_input {
        flex-direction: column;
    }
    .confirm_sertificate_button {
        margin-left: 0px;
        margin-top: 20px;
        width: 100%;
    }
    .pay_sert_close {
        right: -20px;
        top: -20px;
    }
    .notif_pay_input {
        font-size: 14px;
    }
    .notification_sertif_pay_text {
        font-size: 14px;
    }
    .certificate_prepay_message {
        font-size: 14px;
    }

}