.public_offer {
    width: 100%;
    /*height: 100%;*/
}
.privacy_policy {
    width: 100%;
    /*height: 100%;*/
}
.indent_bread {

}
.public_offer_wrapper {
    position: relative;
}
.tab_switch {
    max-width: 300px;
    min-height: 50px;
    /*background: #6eb8d4;*/
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border: 1px solid black;
    margin-right: 10px;
    color: black;
}
.tab_switch.active {
    font-weight: 600;
    /*border: 2px solid black;*/
    transition: font-weight 0.4s ease 0s;
}
.wrapper_switch{
    display: flex;
}
@media (max-width: 1024px) {
    .public_offer, .privacy_policy {
        height: auto;
    }
    .tab_switch {
        padding: 5px;
        font-size: 14px;
    }
}

@media (max-width: 768px) {

    .tab_switch {
        max-width: 100%;
        min-height: auto;
        font-size: 11px;
        margin-right: 0px;
        margin-top: 10px;
        text-align: center;
    }
}