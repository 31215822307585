.change_number_modal {
    position: fixed;
    max-width: 559px;
    max-height: 605px;
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    top: 50%;
    left: 50%;
    z-index: -1;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.0s ease 0s;
    /*padding: 22px;*/
    border: 2px solid black;
}

.code_limit_error {
    color: #CE3F3F;
    font-weight: 500;
    font-size: 12px;
}

.change_number_modal.active {
    z-index: 15;
    opacity: 1;
    transition: opacity 0.6s ease 0.4s;
}

.fields_wrapper_change {
    /*max-width: 461px;*/
    width: 100%;
    height: 100%;
    /*margin: 0 auto;*/
    display: none;
    flex-direction: column;
}
.fields_wrapper_change.active {
    display: flex;
}
.contain_change_phone_close {
    display: flex;
    justify-content: space-between;
    padding: 18px 18px 18px 40px;
}
.change_phone_wrapper_text {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
}
.change_heading {
    font-weight: 500;
    font-size: 30px;
    line-height: 39px;
    letter-spacing: 0.04em;
    color: #1A1A1A;
    /*margin-top: 50px;*/
}
.close_modal_change_phone {
    position: relative;
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.close_modal_change_phone:before {
    position: absolute;
    content: "";
    width: 20px;
    height: 2px;
    border-radius: 10px;
    background: #1A1A1A;
    transform: rotate(-45deg);
    top: 9px;
    left: 0px;
}
.close_modal_change_phone:after {
    position: absolute;
    content: "";
    width: 20px;
    height: 2px;
    border-radius: 10px;
    background: #1A1A1A;
    transform: rotate(45deg);
    top: 9px;
    left: 0px;
}
.text_change_wrapper {
    display: flex;
    flex-direction: column;
    padding: 18px 40px;
    border-top: 2px solid black;
}

.text_change_container {
    /*max-width: 399px;*/
    width: 100%;
    /*margin-top: 35px;*/
}
.contain_phone_select {
    display: flex;

}
.container_change_mask {
    margin-top: 40px;
}
.title_change_input {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
}
.text_change_p {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #000000;
}
.text_change_p:first-child {
    margin-top: 0px;
}
.phoneInput.change {
    width: 100%;
}
.complete_change_number {
    display: none;
    /*max-width: 461px;*/
    width: 100%;
    /*margin: 0 auto;*/
    flex-direction: column;
}
.complete_change_number.active {
    display: flex;
}


.change_and_check_number {
    width: 100%;
    height: 50px;
    background: #757575;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    font-weight: bold;
    font-size: 13px;
    /*text-transform: uppercase;*/
    color: #FFFFFF;
    cursor: pointer;
    pointer-events: none;
    transition: background 0.4s ease 0s;
    margin-top: 18px;
}

.change_and_check_number.active {
    background: #1A1A1A;
    transition: background 0.4s ease 0s;
    pointer-events: auto;
}

.mesage_error_change_number {
    min-height: 10px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    font-size:10px;
    font-weight: 600;
    color: #CE3F3F;
    /*text-transform: uppercase;*/
}
.go_back_stage {
    display: flex;
    margin-top: 5px;
    font-weight: 600;
    /*text-transform: uppercase;*/
    font-size: 12px;
    border-bottom: 1px solid black;
    width: max-content;
    cursor: pointer;
    color: #9A9A9A;
}
.go_back_stage:hover {
    color: black;
    transition: color 0.4s ease 0s;
}

.modal_change_phone_politic {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #757575;
    padding: 40px;
}
.change_phone_politic_link {
    color: black;
    text-decoration: none;
}
.modal_change_phone_politic:hover .change_phone_politic_link{
    border-bottom: 1px solid black;
    transition: border 0.4s ease 0s;
}

@media (max-width: 768px) {
    .change_number_modal {
        width: 90%;
        height: 95%;
    }
    .contain_change_phone_close {
        padding: 20px;
    }
    .change_heading {
        font-size: 16px;
        line-height: 21px;
    }
    .text_change_wrapper {
        padding: 20px;
    }
    .text_change_p {
        font-size: 14px;
        line-height: 26px;
    }


}