/*p,*/
/*label {*/
/*    font: 1rem 'Fira Sans', sans-serif;*/
/*}*/

/*input {*/
/*    margin: .4rem;*/
/*}*/

.wrapper_control_catalog_bar {
    margin-left: 41px;
}
.slider_view_catalog {
    display: flex;
    align-items: center;
    justify-content: center;
}
.slider_view_catalog input[type=range]  {
    max-width: 117px;
    width: 100%;
    -webkit-appearance: none;
}
.slider_view_catalog input[type=range]:focus {
    outline: none;
}
.slider_view_catalog input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    background: #1A1A1A;
    border: 0px solid #000101;
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
}

.slider_view_catalog input[type=range]::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    border: 0px solid #000000;
    height: 13px;
    width: 13px;
    border-radius: 50%;
    background: #1A1A1A;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -5px;
}
.slider_view_catalog input[type=range]:focus::-webkit-slider-runnable-track {
    background: #1A1A1A;
}

.slider_view_catalog input[type=range]::-moz-range-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    animate: 0.2s;
    background: #1A1A1A;
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    border: 0px solid #000101;
}
.slider_view_catalog input[type=range]::-moz-range-thumb {
    height: 13px;
    width: 13px;
    border-radius: 50%;
    background: #1A1A1A;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -5px;
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    border: 0px solid #000000;
}

.slider_view_catalog input[type=range]::-ms-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
}

.slider_view_catalog input[type=range]::-ms-fill-lower {
    background: #1A1A1A;
    border: 0px solid #000101;
    border-radius: 50px;
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
}
.slider_view_catalog input[type=range]::-ms-fill-upper {
    background: #1A1A1A;
    border: 0px solid #000101;
    border-radius: 50px;
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
}
.slider_view_catalog input[type=range]::-ms-thumb {
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    border: 0px solid #000000;
    height: 13px;
    width: 13px;
    border-radius: 50%;
    background: #1A1A1A;
    cursor: pointer;
}
.slider_view_catalog input[type=range]:focus::-ms-fill-lower {
    background: #1A1A1A;
}
.slider_view_catalog input[type=range]:focus::-ms-fill-upper {
    background: #1A1A1A;
}


@media (max-width: 832px) {
    .wrapper_control_catalog_bar {
        width: 100%;
    }
    .slider_view_catalog input[type=range] {
        max-width: 500px;
    }
}

@media (max-width: 768px) {
    .slider_view_catalog input[type=range] {
        max-width: 100%;
    }
}