.wrapper_content_catalog.style_view_catalog {
    max-width: 1920px;
    width: 100%;
    margin: 0 auto;
    margin-top: 65px;
}

.double_cart {
    max-width: 1254px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.wrapper_one_item_product_small {
    padding: 0px 5px;
    cursor: pointer;
}
.one_item_product_small {
    max-width: 607px;
    width: 100%;

}
.one_cart_item {
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 200px;
}

.one_item_product_img_container {
    width: 100%;
}

.one_item_product_img_container img {
    width: 100%;
    max-height: 809px;
    height: auto;
}
.title_and_price_product_small {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.title_product_small {
    max-width: 450px;
    width: 100%;
    display: flex;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #1A1A1A;
}
.price_one_item_product_small {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #1A1A1A;
    margin-top: 20px;
}
.one_medium_cart_item {
    display: flex;
    width: 100%;
    justify-content: center;
}

.wrapper_one_item_product_medium {
    padding: 0px 5px;
    max-width: 1244px;
    width: 100%;
}

.one_medium_cart_item {
    margin-top: 200px;
    cursor: pointer;
}
.one_item_product_medium {
    max-width: 1234px;
    width: 100%;
}
.one_item_product_medium_img_container {
    width: 100%;
}
.one_item_product_medium_img_container img {
    width: 100%;
    height: auto;
}
.title_and_price_product_medium {
    max-width: 450px;
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #1A1A1A;
}

.price_one_item_product_medium {
    margin-top: 20px;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #1A1A1A;
    margin-top: 20px;
}

.group_small_products {
    max-width: 1880px;
    margin: 0 auto;
    margin-top: 200px;
    display: flex;
    justify-content: center;
}


.one_product_item_small_group {
    max-width: 470px;
    padding: 0px 10px;
    width: 100%;
    cursor: pointer;

}

.one_product_item_small_group_img_container {
    width: 100%;
}
.one_product_item_small_group_img_container img {
    width: 100%;
}

.title_and_price_product_group {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.title_product_group_small {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #1A1A1A;
}
.price_one_item_product_group_small {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #1A1A1A;
    margin-top: 20px;
}

.fullsize_one_item {
    max-width: 1920px;
    margin: 0 auto;
    margin-top: 200px;
}
.one_item_product_fullsize {
    max-width: 1920px;
    width: 100%;
    cursor: pointer;
}
.one_item_image_wrapper_fullsize {
    width: 100%;
}
.image_full_size {
    width: 100%;
    height: auto;
}
.title_and_price_fullsize {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding: 0px 30px;
}

.title_fullsize {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #1A1A1A;
}

.price_one_item_product_group_small {
    margin-top: 20px;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #1A1A1A;
}
.unreverse_container {
    display: flex;
    justify-content: flex-end;
    max-width: 1920px;
    width: 100%;
    margin-top: 200px;
}
.wrapper_one_item_unreverse {
    max-width: 1710px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.unreverse_img_container {
    max-width: 1078px;
    width: 100%;
    max-height: 809px;
    margin-left: 20px;

}
.unreverse_img_container img {
    width: 100%;
    height: 100%;
}

.wrapper_one_item_unreverse {
    cursor: pointer;
}

.reverse_container {
    display: flex;
    justify-content: flex-start;
    max-width: 1920px;
    width: 100%;
    margin-top: 200px;
}

.wrapper_one_item_reverse {
    max-width: 1710px;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    cursor: pointer;
}

.wrapper_one_item_reverse .unreverse_img_container {
    max-width: 1078px;
    width: 100%;
    max-height: 809px;
    margin-right: 20px;

}

