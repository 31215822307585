.one_product_line {
    height: 60px;
    /*background-color: rgba(255, 192, 203, 0.29);*/
    border: 1px solid #c9cccf;
    display: flex;
    width: 100%;
    margin-top: 5px;
    align-items: center;
    transition: opacity 0.8s ease 0s, background-color 0.8s ease 0s;
}
.one_product_line.v {
    opacity: 0;
    filter:blur(3px);
    transition: opacity 0.4s ease 0.3s, filter 0.7s ease 0s;
}
.one_product_line.deactive {
    opacity: 0.5;
    background-color: rgba(211, 211, 211, 0.2);
    transition: opacity 0.8s ease 0s, background-color 0.8s ease 0s;
}
.one_product_line:hover {
    background-color: #e3e5e7;
    transition: background-color 0.8s ease 0s;
}
.id_block_on_pr_line {
    width: 50px;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    text-decoration: none;
    color: #9A9A9A;
}
.look_number_sort {
    display: flex;
    white-space: nowrap;
    font-size: 16px;
    font-weight: 600;
    padding: 0px 10px;
    pointer-events: none;
    margin-right: 30px;
}
.image_product_or_line {
    max-height: 100%;
    max-width: 50px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.image_product_or_line img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.image_product_or_line img.no_image {
  opacity: 0.2;
}
.name_line_product {
    width: 190px;
    display: flex;
    justify-content: flex-start;
    padding: 0px 15px;
    align-items: center;
    font-size: 12px;
    text-transform: none;
    text-decoration: none;
    cursor: pointer;
    color: black;
}

.child_count {
    width: 80px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    text-transform: none;
}

.link_st {
    display: flex;
    height: 100%;
    text-decoration: none;
    color: black;
    cursor: pointer;
    width: 100%;
    max-width: 500px;
    align-items: center;
}

.remove_div {
    display: flex;
    height: 100%;
    width: 40px;
    align-self: flex-end;
    float: right;
    padding: 0px 7px;
    cursor: pointer;
}
.remove_div svg {
    height: 100%;
    width: 100%;
}
.other_controls_tab {
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.articul_product {
    font-size: 11px;
    width: 120px;
    display: flex;

}
.right_line_bar {
    display: flex;
}
.date_updateat_container {
    width: 80px;
    font-size: 12px;
    text-align: center;
    margin-right: 15px;
}
.date_createdat_container {
    width: 80px;
    font-size: 12px;
    text-align: center;
    margin-right: 15px;
}
.last_redactor {
    font-size: 11px;
    display: flex;
    width: 70px;
    text-align: center;
    height: 100%;
    color: #6eb8d4;
}