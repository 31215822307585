.one_reviews_block {
    display: flex;
    width: 100%;

    border: 1px solid #c9cccf;
    border-radius: 5px;
    margin-top: 30px;
    padding: 15px;
    flex-direction: column;
    position: relative;

}

.one_reviews_block.v {
    opacity: 0;
    filter:blur(3px);
    transition: opacity 0.4s ease 0.3s, filter 0.7s ease 0s;
}


.reviews_field_wrapper {
    display: flex;
    flex-direction: column;
}
.reviews_field {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #c9cccf;
    font-weight: 600;
    font-size: 12px;
    padding: 5px 5px;
    border-radius: 5px;
    margin-top: 5px;
}
.reviews_field_label_name {
    font-weight: 600;
    font-size: 12px;
}
.controll_panel_one_reviews {
    display: flex;
    width: 100%;
    height: 60px;
    justify-content: space-between;
    margin-bottom: 30px;
    border-bottom: 1px solid #c9cccf;
    padding-bottom: 20px;
}
.remove_panel {
    display: flex;
    width: 32px;
    height: 32px;
    cursor: pointer;
}
.remove_panel svg {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
}
.remove_panel:hover svg {
  animation: dragTrash 0.8s ease 1;
}

@keyframes dragTrash {
    0% {
    transform: rotate(0deg);
    }
    35% {
        transform: rotate(12deg);
    }
    70% {
    transform: rotate(-12deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

.right_block_reviews {
    display: flex;
    align-items: center;
}
.is_active_reviews {
    display: inline-block;
    width: 72px;
    height: 32px;
    border-radius: 19px;
    background: #bfbfbf;
    z-index: 0;
    margin: 0;
    padding: 0;
    border: none;
    cursor: pointer;
    position: relative;
    transition-duration: 300ms;
    margin-right: 20px;
}
.circle_active_p {
    content: "";
    height: 26px;
    width: 26px;
    border-radius: 17px;
    background: #fff;
    top: 3px;
    left: 3px;
    transition-duration: 300ms;
    position: absolute;
    z-index: 1;
}
.is_active_reviews.on {
    background: black;
}
.is_active_reviews.on .circle_active_p {
    /*background: #118c4e;*/
    left: 43px;
}


.info_reviews_dispet {
    display: flex;
}
.stars_reviews {
    display: flex;
    margin-left: 50px;
    align-items: center;
}
.circle_reviews {
    margin-left: 30px;
    display: flex;
    height: 30px;
    width: 30px;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    border-radius: 50px;
    font-weight: 600;
    cursor: pointer;
}

.circle_reviews.black {
    background-color: black;
    color: white;
    border: 1px solid transparent;
}


.reviews_save {
    border-radius: 5px;
    display: flex;
    height: 32px;
    width: 120px;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    cursor: pointer;
    font-weight: 600;
}

.reviews_save.trigger {
        animation: dople 2s ease infinite;
        font-weight: 600;
        background: black;
}

@keyframes dople {
    0% {
        background: black;
        color: white;
    }
    50% {
        color: black;
        background: white;
    }
    100% {
        background: black;
        color: white;
    }

}

.one_reviews_block textarea {
    resize: none;
    height: 90px;
}