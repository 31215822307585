.header_wrapper {
  position: fixed;
  z-index: 10;
}
header {
  width: 100%;
  /*max-height: 125px;*/
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9;
  transition: 0.2s ease 0s;
  transition-property: background-color;
  /*background: white;*/
}
header._search-active {
  background: white;
}
.header-bg {
  position: absolute;
  background: rgba(0, 0, 0, 0.35);
  left: 0;
  width: 100vw;
  bottom: 0;
  top: 0;
  z-index: 9;
}

header.header.admin {
  top: -200px;
  transition: 1s ease 0s;
}
header.header.scroll {
  background: white;
  transition: background 0.6s ease 0s;
}
header.header.home.scroll {
  background: rgba(255, 255, 255, 0.35);
  transition: background 0.6s ease 0s;
}
header.header.home.scroll:hover {
  background: white;
  transition: 0.2s ease 0s;
}
header:hover {
  background: white;
  transition: 0.2s ease 0s;
}

.main_header_line {
  height: 148px;
  width: 100%;
  padding: 0px 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

/*ICONS BAR*/
.Icons_bar_in_headerLine {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
  height: 100%;
}

.bar_icon_wrapper._search {
  transform: none;
}

.Icons_bar_in_headerLine._active-search {
  flex-grow: 1;
}

.Icons_bar_in_headerLine._active-search .bar_icon_wrapper {
  width: auto;
}

.Icons_bar_in_headerLine._active-search .bar_icon_wrapper._search {
  flex-grow: 1;
}

.Icons_bar_in_headerLine._active-search .search_wrapper {
  width: 100%;
}

.Icons_bar_in_headerLine._active-search .search_input_and_close {
  width: 100%;
}

.Icons_bar_in_headerLine._active-search .input_search_product {
  max-width: initial;
  flex-grow: 1;
}

.bar_icon_wrapper {
  margin-right: 30px;
  /*height: max-content;*/
  cursor: pointer;
  transform: scale(1);
  width: 100%;
  text-decoration: none;
  color: black;
  display: flex;
}
.bar_icon_wrapper.basket {
  position: relative;
}

.counter_basket {
  display: flex;
  position: absolute;
  height: 14px;
  width: 14px;
  background-color: #ce3f3f;
  color: white;
  font-size: 10px;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  bottom: 1px;
  right: 0px;
  border-radius: 50%;
}

.bar_icon_wrapper:last-child {
  margin-right: 0px;
}

.bar_icon_wrapper.stroke {
  display: flex;
  align-items: center;
}
.bar_icon_wrapper svg path {
  fill: black;
}

.bar_icon_wrapper.stroke svg path {
  fill: transparent;
  stroke: black;
}

.bar_icon_wrapper.stroke .favorite_icons_header.active svg path {
  fill: black;
  transition: fill 0.4s ease 0s;
}
.bar_icon_wrapper.favorites {
  position: relative;
  align-items: center;
}
.count_indicator_favorites {
  display: none;
}
.count_indicator_favorites.active {
  display: flex;
  position: absolute;
  height: 14px;
  width: 14px;
  background-color: #ce3f3f;
  color: white;
  font-size: 10px;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  bottom: 3px;
  right: 0px;
  border-radius: 50%;
  z-index: 1;
}

.logotype_wrap {
  display: flex;
  align-items: center;
}
/*.logotype_wrap svg path {*/
/*    fill: black;*/
/*}*/
.logotype_wrap img {
  max-width: 286px;
  width: 100%;
  height: auto;
  object-fit: contain;
}
.bar_icon_wrapper.user.stroke.black {
  position: relative;
}
.bar_icon_wrapper.user.stroke.black:after {
  content: "";
  width: 13px;
  height: 13px;
  background: #ce3f3f;
  border: 3px solid #ce3f3f;
  border-radius: 50%;
  position: absolute;
  bottom: 1px;
  box-sizing: border-box;
  right: -4px;
}

.bar_icon_wrapper:hover svg {
  transform: scale(1.1);
}

.bar_icon_wrapper.stroke.black path {
  display: flex;
  fill: black;
  stroke: black;
}
.nav_bar_desc {
  height: 100%;
  display: flex;
  padding-left: 40px;
  padding-right: 40px;
  align-items: center;
}
.menu_item {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #1a1a1a;
  margin-left: 30px;
  text-align: center;
  /*text-transform: uppercase;*/
  text-decoration: unset;
  position: relative;
  height: max-content;
  padding: 10px 0px;
}
.menu_item.active::before {
  content: "";
  position: absolute;
  height: 5px;
  width: 5px;
  background: black;
  left: 50%;
  bottom: 0px;
  border-radius: 50%;
}
.menu_item.active:hover:after {
  width: 100%;
  background-color: transparent;
}
.menu_item:first-child {
  margin-left: 0px;
}

.menu_item:after {
  content: "";
  position: absolute;
  height: 1px;
  width: 1%;
  left: 0px;
  bottom: 0px;
  background-color: transparent;
}

.menu_item:hover:after {
  width: 100%;
  background-color: #1a1a1a;
}

.hover_menu {
  position: absolute;
  width: 100%;
  min-height: 200px;
  background-color: white;
  left: 0px;
  top: -600%;
  opacity: 0;
  display: flex;
  padding: 20px 30px 42px 30px;
  /*box-shadow: 0px 3px 10px rgb(0 0 0 / 15%);*/
  border-top: 1.5px solid #000000;
  transition: top 0.05s ease 0.1s, opacity 0.15s ease 0s;
  z-index: -1;
}

.hover_menu.active {
  z-index: 1;
  top: 99%;
  opacity: 1;
  transition: top 0.2s ease 0s, opacity 0.2s ease 0.1s;
}

.one_column_menu {
  display: flex;
  flex-direction: column;
  margin-left: 70px;
}
.one_column_menu:first-child {
  margin-left: 0px;
}

.one_column_menu a {
  margin-top: 20px;
  display: flex;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-decoration: none;
  /*text-transform: uppercase;*/
  position: relative;
  color: #000000;
}
.one_column_menu a:first-child {
  font-weight: 700;
  margin-top: 2px;
}

.one_column_menu a:after {
  content: "";
  position: absolute;
  height: 8px;
  width: 3px;
  background-color: #1a1a1a;
  top: 2px;
  left: -15px;
  transform: rotate(-40deg);
  border-radius: 5px;
  opacity: 0;
  transition: opacity 0.2s ease 0s;
}

.one_column_menu a:before {
  content: "";
  position: absolute;
  height: 8px;
  width: 3px;
  background-color: #1a1a1a;
  top: 8px;
  left: -15px;
  transform: rotate(40deg);
  border-radius: 5px;
  opacity: 0;
  transition: opacity 0.2s ease 0s;
}

.one_column_menu a:hover:after,
a:hover::before {
  opacity: 1;
  transition: opacity 0.4s ease 0s;
}

.search_wrapper {
  display: flex;
  margin-left: 30px;
}
.search_input_and_close {
  display: flex;
  border-bottom: 1px solid #b1b1b1;
  margin-left: 18px;
  margin-right: 10px;
}
.search_input_and_close svg {
  margin-right: 12px;
  display: block;
  margin-bottom: 5px;
}
.input_search_product {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  max-width: 240px;
}
.close_search {
  width: 20px;
  height: 20px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  margin-bottom: 6px;
}
.close_search:before {
  position: absolute;
  content: "";
  height: 2px;
  width: 20px;
  right: 0px;
  top: 10px;
  border-radius: 10px;
  background-color: #000000;
  transform: rotate(45deg);
  transition: transform 0.4s ease 0s;
}
.close_search:after {
  position: absolute;
  content: "";
  height: 2px;
  width: 20px;
  right: 0px;
  top: 10px;
  border-radius: 10px;
  background-color: #000000;
  transform: rotate(-45deg);
  transition: transform 0.4s ease 0s;
}

.other_header_elements {
  z-index: 10;
  position: relative;
}

input[type="text"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="text"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

@media (max-width: 1024px) {
  .search_wrapper {
    margin-left: 0;
  }
  .search_input_and_close {
    margin-left: 0;
  }
  .Icons_bar_in_headerLine {
    margin-left: 0;
  }
  .main_header_line {
    justify-content: space-between;
  }
  .main_header_line {
    height: 134px;
    padding: 0px 40px;
    border-bottom: 1px solid transparent;
  }
  .main_header_line.active {
    background: white;
    z-index: 11;
    border-bottom: 1px solid black;
  }
  .logotype_wrap.nonactive {
    /*height: 100%;*/
    /*max-width: 350px;*/
    /*width: 100%;*/
    /*justify-content: flex-end;*/
    padding-left: 0px;
  }
  .logotype_wrap {
    /*width: 100%;*/
    height: 100%;
    justify-content: center;
    padding-left: 150px;
  }
  .logotype_wrap img {
    max-width: 186px;
  }
}

@media (max-width: 768px) {
  .main_header_line {
    height: 75px;
    padding: 0px 20px;
  }
  .logotype_wrap img {
    max-width: 121px;
  }
  .bar_icon_wrapper {
    margin-right: 18px;
  }
  header._search-active .bar_icon_wrapper._search {
    margin-right: 0;
  }
  .bar_icon_wrapper.basket {
    margin-right: 0px;
  }
  .container_lk_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 20px 19px 40px;
    box-shadow: 0px -1px 0px 0px #000000;
    width: 100%;
    text-decoration: none;
  }
  .container_lk_title a {
    text-decoration: none;
  }
  .container_lk_title.open_menu {
    border-top: 1px solid transparent;
    border-bottom: 1px solid black;
  }
  .container_lk_title.favorite_non {
    opacity: 0;
    transition: opacity 0.2s ease 0s;
  }
  .child_container_lk_mobile.open {
    flex-direction: row-reverse;
    max-width: 270px;
  }
  .child_container_lk_mobile {
    display: flex;
    width: 100%;
  }
  .mobile_bar_icons {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    cursor: pointer;
    color: black;
    width: 100%;
  }

  .mobile_lk_arrows {
    max-width: 80px;
    width: 100%;
    height: 20px;
    /*background: #0b4a0b;*/
    position: relative;
  }
  .mobile_lk_arrows:before {
    content: " ";
    position: absolute;
    width: 12px;
    height: 2px;
    transform: rotate(45deg);
    background-color: #000000;
    top: 5px;
    right: 0px;
  }
  .mobile_lk_arrows:after {
    content: " ";
    position: absolute;
    width: 12px;
    height: 2px;
    transform: rotate(-45deg);
    background-color: #000000;
    top: 12px;
    right: 0px;
  }

  .mobile_lk_arrows.active:before {
    content: " ";
    position: absolute;
    width: 12px;
    height: 2px;
    transform: rotate(-45deg);
    background-color: #000000;
    top: 5px;
    left: 0px;
  }
  .mobile_lk_arrows.active:after {
    content: " ";
    position: absolute;
    width: 12px;
    height: 2px;
    transform: rotate(45deg);
    background-color: #000000;
    top: 12px;
    left: 0px;
  }

  .logotype_wrap {
    padding-left: 40px;
  }
  .bar_icon_wrapper:hover svg {
    transform: unset;
  }

  .count_indicator_favorites.active {
    bottom: 20px;
    right: 20px;
  }
}

@media (max-width: 428px) {
  .input_search_product {
    max-width: 130px;
  }
}
@media (max-width: 380px) {
  .container_lk_title:last-child {
    border-bottom: 1px solid black;
  }
}

header.is-black {
  &.header {
    background: #000000 !important;
  }

  &.header:hover {
    background: #000000;
  }

  &.header .logotype_wrap img {
    filter: invert(1);
  }

  &.header .menu_item {
    color: #ffffff;
  }

  &.header .menu_item:hover::after {
    background-color: #ffffff;
  }

  &.header .bar_icon_wrapper svg path {
    fill: #ffffff;
  }

  &.header .bar_icon_wrapper.stroke svg path {
    stroke: #ffffff;
    fill: transparent;
  }

  &.header .hover_menu {
    background-color: #000000;
  }

  &.header .one_column_menu a {
    color: #ffffff;
  }

  &.header .one_column_menu a::before {
    background-color: #ffffff;
  }

  &.header .one_column_menu a::after {
    background-color: #ffffff;
  }

  &.header .bar1 {
    background: #ffffff;
  }

  &.header .bar2 {
    background: #ffffff;
  }

  &.header .bar3 {
    background: #ffffff;
  }

  &.header {
    .main_header_line.active {
      .bar1.active {
        background: #000000;
      }
      .bar2.active {
        background: #000000;
      }
      .bar3.active {
        background: #000000;
      }
      .logotype_wrap img {
        filter: none;
      }
      .bar_icon_wrapper svg path {
        fill: black;
      }
    }
  }

  @media (max-width: 1024px) {
    &.header:hover {
      background: #000000;
    }
  }
}
