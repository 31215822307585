/*716px*/
.basket_order_user_fields_wrapper {
    display: flex;
    /*max-width: 760px;*/
    /*width: 716px;*/
    /*height: 100%;*/
    /*margin-top: 90px;*/
    /*margin-left: 90px;*/
    flex-direction: column;
}
.basket_order_user_fields_heading {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #1A1A1A;
}
.basket_order_user_fields_info_text {
    display: flex;
    margin-top: 45px;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #000000;
}
.first_basket_order_user_fields_wrapper {
    display: flex;
    margin-top: 44px;
}
.first_basket_order_user_fields_wrapper.enf {
    flex-direction: column;
}
.basket_order_user_heading_fields {
    display: flex;
    max-width: 160px;
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
    padding-top: 12px;
}
.basket_order_user_fields_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 54px;
    position: relative;
}
.basket_order_one_field_wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    /*margin-top: 18px;*/
}
.basket_order_one_field_wrapper:first-child {
    margin-top: 0px;
}

.fields_delivery_basket {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #1A1A1A;
    padding-bottom: 5px;
}
.basket_order_one_field_wrapper.error input{
    border: 1px solid #CE3F3F;
}
.basket_order_input_text_info {
    display: flex;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    /*position: absolute;*/
    /*left: 0px;*/
    /*bottom: -24px;*/
    width: 100%;
}

.basket_order_one_field_wrapper input {
    display: flex;
    width: 100%;
    height: 40px;
    border: 1px solid #D7D6D6;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: black;
    padding-left: 5px;
}
.basket_order_one_field_wrapper input::placeholder {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #757575;
    margin-bottom: 20px;
    padding-bottom: 20px;
}

.basket_order_one_field_wrapper .no_margin_first_input {
    margin-top: 0px;
}

.basket_order_subscribe_wrapper {
    display: flex;
    /*height: 40px;*/
    min-height: 40px;
    max-width: 450px;
    width: 100%;
    align-items: center;
    margin-top: 24px;
}

.basket_order_subscribe_text {
    display: flex;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #000000;
    margin-left: 10px;
    flex-grow: 1;
}
.basket_order_subscribe_check_input {
    display: flex;
    min-width: 26px;
    width: 26px;
    height: 26px;
    border: 1px solid #E3E3E3;
    border-radius: 2px;
    cursor: pointer;
    position: relative;
}
.basket_order_subscribe_check_input:hover {
    border-color: black;
    transition: border-color 0.4s ease 0s;
}

.basket_order_subscribe_check_input.active:after {
    display: flex;
    position: absolute;
    content: "";
    top: 11px;
    left: 7px;
    width: 17px;
    height: 2px;
    background-color: black;
    transform: rotate(-45deg);
    border-radius: 5px;
}
.basket_order_subscribe_check_input.active:before {
    display: flex;
    position: absolute;
    content: "";
    top: 13px;
    left: 2px;
    width: 10px;
    height: 2px;
    background-color: black;
    transform: rotate(45deg);
    border-radius: 5px;
}

.basket_order_subscribe_wrapper.subs {
    margin-top: 20px;
}
.basket_order_small_fields_wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: 18px;
}
.basket_order_small_fields_small_container {
    display: flex;
    flex-direction: column;
    width: 50%;
}
.basket_order_small_fields_small_container.left {
    padding-right: 10px;
}
.basket_order_small_fields_small_container.right {
    padding-left: 10px;
}

.basket_order_small_fields_small_container .basket_order_one_field_wrapper:first-child input {
    margin-top: 20px;
}


.basket_order_one_field_support_info {
    font-weight: 500;
    font-size: 14px;
    margin-top: 10px;
    color: #0b4a0b;

    
}
.user_support_fields_order_wrapper {
    display: flex;
    /*margin-top: 10px;*/
}

.basket_order_small_fields_wrapper.disactive  .basket_order_one_field_wrapper input {
    user-select: none;
    pointer-events: none;
    color: lightgrey;
    border-color: lightgrey;
}
.basket_order_small_fields_wrapper.disactive  .basket_order_one_field_wrapper input::placeholder {
    color: lightgrey;

}
.user_address_fields span {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
}
.mini_fields_order_support_wrapper {
    display: flex;
}
.search_street_support_container {
    display: flex;
    background-color: white;
    width: 100%;
    position: absolute;
    top: 60px;
    left: 0px;
    z-index: 1;
    flex-direction: column;
    /*height: 175px;*/
    /*min-height: 175px;*/
    border-left: 1px solid #D7D6D6;
    border-right: 1px solid #D7D6D6;
    border-bottom: 1px solid #D7D6D6;
    padding: 5px 10px;
}
.search_street_support_one_item {
    padding: 5px 0px;
    cursor: pointer;
    font-weight: 500;
    /*width: max-content;*/
    width: 100%;
    border-bottom: 1px solid transparent;
}

.search_street_support_one_item:hover {
    border-color: black;
}

.custom_order_phone {
    position: relative;
}
.sms_agreed{
    cursor: pointer;
}
.sms_agreed:hover{
    opacity: 0.8;
}

.country_container_for_phone {
    position: absolute;
    /*height: 100%;*/
    /*background-color: black;*/
    display: flex;
    right: 0px;
    top: 28px;
    /*bottom: 10px;*/
    /*flex-direction: column;*/
    justify-content: space-between;
    /*align-items: flex-end;*/
    align-items: center;
}

.country_order_flag_container {
    /*height: 30px;*/
    display: flex;
    align-items: center;
    /*margin-bottom: 4px;*/
    margin: 0px 10px;
}
.country_notification_shield {
    display: flex;
    align-items: center;
    /*height: 20px;*/
    /*margin-top: 5px;*/
    /*margin-right: 10px;*/
    cursor: pointer;
    color: black;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
    border-bottom: 1px solid transparent;
}
.country_notification_shield:hover {
    border-color: black;
}
.basket_container_validate_and_info {
    display: flex;
    justify-content: space-between;
}
.order_validator_shield {
    display: flex;
    /*position: absolute;*/
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    /*bottom: 0px;*/
    /*right: 0px;*/
    /*text-transform: uppercase;*/
    color: #CE3F3F;
    height: 18px;
    /*width: calc(100% - 115px);*/
    width: 100%;
    /*margin-top: 10px;*/
    justify-content: flex-end;
}
.custom_styles .order_validator_shield{
    top: -15px;
}


/*box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);*/

/*//*/
/*font-weight: 400;*/
/*font-size: 12px;*/
/*line-height: 20px;*/
/*!* identical to box height, or 167% *!*/


/*color: #000000;*/


@media (max-width: 1024px) {
    .basket_order_user_fields_info_text{
        margin-top: 40px;
    }
    .first_basket_order_user_fields_wrapper {
        margin-top: 40px;
    }
    .basket_order_user_heading_fields {
        padding-top: 0px;
    }

}

@media (max-width: 768px)  {

    .basket_order_user_fields_container {
        margin-left: 0px;
    }
    .basket_order_one_field_wrapper {
        margin-top: 18px;
    }
    .basket_order_one_field_wrapper:first-child {
        margin-top: 20px;
    }
    .order_validator_shield {
        height: auto;
    }
    .basket_container_validate_and_info {
        flex-direction: column;
    }
    .country_container_for_phone {
        bottom: 0px;
        flex-direction: column;
    }
    .country_notification_shield {
        font-size: 10px;
    }
    .country_order_flag_container{
        margin: 10px;
    }
    .basket_order_subscribe_wrapper.subs {
        margin-top: 10px;
    }
    .user_support_fields_order_wrapper {
        flex-direction: column;
    }
    .search_street_support_container {
        top: 75px;
        font-size: 12px;
    }
    /*.basket_order_one_field_wrapper input {*/
    /*    font-size: 12px;*/
    /*}*/
    /*.basket_order_one_field_wrapper input::placeholder {*/
    /*    font-size: 12px;*/
    /*}*/
    .basket_order_one_field_support_info {
        font-size: 12px;
    }
    .basket_order_small_fields_wrapper {
        margin-top: 5px;
    }
    .country_container_for_phone {
        top: 5px;
        justify-content:normal;
    }

}
@media (max-width: 428px) {


}