.slick_wrapper {
    /*padding: 1px;*/
    width: 100%;
    margin: 0 auto;
    margin-top: 18px;
    border: 1px solid black;
}
.wrapper_arrows_slider {
    min-height: 64px;
    border-bottom: 1px solid black;
}
.one_cards_slider {
    position: relative;
}
.add_product_favorite {
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 2;
    cursor: pointer;
    padding: 10px;
}
.one_cards_product {
    width: 100%;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 1px;
    border-right: 1px solid black;
}




.slick-slide img {
    max-width: 460px;
    height: 680px;
    width: 100%;
    /*object-fit: contain;*/
    object-fit: cover;
    margin: 0 auto;
}
.slick-slider.slick-initialized {

}
.slick-arrow.slick-next {
    display: block;
    top: -30px;
    right: 50px;
    height: 30px;
    width: 30px;
}
.slick-arrow.slick-prev {
    display: block;
    top: -30px;
    right: 100px;
    left: auto;
    height: 30px;
    width: 30px;
}
.slick-prev, .slick-next {

}
:focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
}

.slick-prev:before, .slick-next:before  {
    font-family: 'IBM Plex Mono'!important;;
    display: flex;
    align-items: center;
    justify-content: center;
    background: black!important;
    opacity: 1!important;
    font-size: 30px!important;
    height: 2px!important;
    width: 20px!important;
    bottom: 20px;
    right: 5px;
    position: absolute;
}
.slick-prev:before {
    content: ' '!important;
    transform: rotate(-45deg);
}
.slick-next:before {
    content: ' '!important;
    transform: rotate(45deg);
}
.slick-prev:after, .slick-next:after  {
    font-family: 'IBM Plex Mono';
    align-items: center;
    justify-content: center;
    background: black;
    opacity: 1;
    font-size: 30px!important;
    height: 2px!important;
    width: 20px!important;
    bottom: 7px;
    right: 5px;
    position: absolute;
}
.slick-prev:after {
    content: ' '!important;
    transform: rotate(45deg);
}
.slick-next:after {
    content: ' '!important;
    transform: rotate(-45deg);
}

.price_and_name_slider {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: inherit;
    padding: 10px;
    justify-content: space-between;
}
.name_product_slider {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #1A1A1A;
}
.price_and_oldprice{
    display: flex;
}
.price_product_slider {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #1A1A1A;
}
.price_product_slider.old_price {
    text-decoration-line: line-through;
    margin-left: 18px;
    color: #D7D7D7;
    font-weight: 300;
    font-size: 14px;
}
.sell_price_prod_slider {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #1A1A1A;
}

.slick-track {
    margin-left: 0 !important;
}

@media (max-width: 1440px) {
    .slick-slide img {
        max-width: 280px;
        height: 420px;
    }
}

@media (max-width: 1024px) {
    .slick-slide img {
        max-width: 183px ;
        height:  275px;
    }
    .name_product_slider {
        font-size: 12px;
    }
    .price_product_slider {
        font-size: 14px;
    }
    .price_product_slider.old_price {
        font-size: 12px;
        margin-left: 10px;
    }
    .sell_price_prod_slider {
        font-size: 14px;
    }
}


@media (max-width: 768px) {

    .slick-slide img {
        max-width: 183px ;
        height:  275px;
    }
    .wrapper_arrows_slider {
        min-height: 45px;
    }
    .slick-arrow.slick-prev {
        top: -23px;
        right: 60px;
    }
    .slick-arrow.slick-next {
        top: -23px;
        right: 10px;
    }
    .price_and_name_slider {
        padding: 5px;
    }

    .price_and_oldprice{
        display: flex;
        margin-top: 18px;
    }

}

@media (max-width: 390px) {
    .slick-slide img {
        max-width: 175px ;
        height: 263px;
    }
}
@media (max-width: 380px) {
    .slick-slide img {
        max-width: 315px;
        height: 413px;
    }
    .slick-slide.slick-active {
        box-shadow: unset;
    }
    .wrapper_arrows_slider {
        border-bottom: 1px solid black;
    }
    .one_cards_product {
        border-right: unset;
    }
    .price_and_name_slider {
        min-height: auto;
    }
}