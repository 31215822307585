.reviews_page_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0px 40px;
}
.reviews_page {
    display: flex;
    /*height: 100%;*/
    /*width: 100%;*/
    /*flex-direction: column;*/
    /*align-items: center;*/
    max-width: 1920px;
    width: 100%;
    padding-bottom: 40px;
}
.reviews_banner {
    width: 40%;
    /*width: 100%;*/
    /*height: 831px;*/
    /*position: relative;*/
    display: flex;
    /*justify-content: center;*/
    flex-direction: column;
    background: white;
    height: max-content;
    position: sticky;
    /*top: 0;*/
}
.banner_reviews_image {
    width: 100%;
    object-fit: cover;
    height: 100%;
}
.reviews_abs_div_content {
    /*max-width: 940px;*/
    /*width: 100%;*/
    /*height: 521px;*/
    display: flex;
    /*position: absolute;*/
    /*bottom: 40px;*/
    /*padding: 0px 10px;*/
}
.reviews_static_div_content {
    max-width: 375px;
    /*height: 100%;*/
    /*width: 100%;*/
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
}
.reviews_headlines {
    /*max-width: 438px;*/
    /*width: 100%;*/
    /*margin: 0 auto;*/
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
}
.reviews_page_title {
    font-weight: 500;
    font-size: 30px;
    display: flex;
    /*justify-content: center;*/
}
.reviews_subline {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #1A1A1A;
    margin-top: 40px;
}
.reviews_btn_additions {
    margin-top: 40px;
    max-width: 201px;
    width: 100%;
    min-height: 49px;
}
.reviews_btn_additions.black {
    margin-top: 100px;
    margin-bottom: 100px;
    display: flex;
    justify-content: center;
}
.reviews_btn_style {
    display: flex;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    text-align: center;
    /*text-transform: uppercase;*/
    color: #ffffff;
    padding: 14px;
    border: 1px solid #000000;
    background: black;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    transition: 0.4s ease 0s;
}
.reviews_btn_style:hover {
    background-color: white;
    color: black;
    transition: 0.6s ease 0s;
}
.reviews_check {
    /*width: 100%;*/
    margin-top: 40px;
    /*text-align: center;*/
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    /*align-items: center;*/
    border: 1px solid #000000;
    padding: 40px;
}
.total_number_reviews {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #1A1A1A;
}
.total_number_reviews_conteiner {

}
.total_number_reviews.subline {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #1A1A1A;
    margin-right: 10px;
}
.container_reviews {
    margin-left: 57px;
    width: 60%;
    /*margin-top: 143px;*/
}
.reviews_scale_conteiner {
    width: 100%;
    margin-top: 20px;
}
.reviews_scale {
    max-width: 293px;
    width: 100%;
    height: 5px;
    /*background-color: #C0C0C0;*/
    margin-top: 10px;
}
.reviews_scale_child.positive {
    height: 100%;
    background-color: #1A1A1A;
    transition: width 2s ease 0s;
}
.reviews_scale_child.negative {
    height: 100%;
    background-color: #757575;
    transition: width 2s ease 0s;
    opacity: 0.4;
}
.reviews_indicators {
    /*max-width: 350px;*/
    margin-top: 18px;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.reviews_indicators_child {
    font-weight: 500;
    font-size: 16px;
    color: #1A1A1A;
    display: flex;
}
.reviews_indicators_positive {
    width: 15px;
    height: 15px;
    background-color: #1A1A1A;
    margin-right: 13px;
}
.reviews_indicators_negative {
    width: 15px;
    height: 15px;
    background-color: #757575;
    margin-right: 13px;
}

.reviews_sorting_conteiner {
    max-width: 350px;
    width: 100%;
    display: flex;
    margin: 0 auto;
    padding: 10px 0px;
    font-weight: 400;
    color: #8B8B8B;
    font-size: 14px;
    display: flex;
    text-align: center;
    cursor: pointer;
    border-bottom: 1px solid #E0E0E0;
}
.reviews_sorting {
    padding: 5px 5px;
    border: 1px solid transparent;
}
.reviews_sorting:hover {
    border: 1px solid #E0E0E0;
    box-shadow: 0px 0px 20px rgb(0 0 0 / 15%);
    transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s;
}
.reviews_sorting.active {
    background-color: black;
    color: white;
    transition: 0.4s ease 0s;
    border: 1px solid transparent;
}
.reviews_btn_style.black {
    color: black;
    border: 1px solid black;
}
.reviews_btn_style.black:hover {
    background-color: black;
    color: white;
    transition: 0.8s ease 0s;
}
@media (max-width: 1440px) {
    .reviews_page {
        max-width: 1123px;
    }

}

@media (max-width: 1024px) {
    .reviews_page {
        flex-direction: column;
        margin: unset;
    }
    .reviews_banner{
        width: 100%;
        position: unset;
    }
    .reviews_page_title {
        font-size: 24px;
    }
    .reviews_subline {
        margin-top: 18px;
    }
    .reviews_check {
        max-width: 373px;
    }
    .container_reviews {
        margin-top: 40px;
        width: 100%;
        margin-left: 0px;
    }



}


@media (max-width: 768px) {
    .reviews_page_wrapper {
        padding: 0px 20px;
    }

    .reviews_page_title {
        font-size: 18px;
    }
    .reviews_subline {
        font-size: 14px;
        line-height: 150%;
        margin-top: 10px;
    }
    .reviews_check {
        padding: 20px 37px 20px 20px;
    }


}


