.bonuses_wrapper {
    /* max-width: 440px; */
}

.basket_bonuses_info {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #1A1A1A;
    display: block;
    white-space: nowrap;
}

.input_wrapper {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.input_wrapper_summ {
    display: flex;
    /* justify-content: flex-start; */
    align-items: center;
    margin-top: 20px;
}

.amount { 
    display: flex;
    align-items: center;
}

.manual {
    display: flex;
    align-items: center;
}

.main_manual {
    display: flex;
    align-items: center;
    width: 100%;
}

.basket_tooltip_custom {
    max-width: 400px;
    white-space: pre-wrap;
}

.basket_bonuses_info .small_text {
    font-size: 12px;
    color: #757575;
}

.basket_bonuses_info.change {
    margin-left: 20px;
    font-size: 16px;
    text-decoration: underline;
    cursor: pointer;
    color: #757575;
}

.bonuses_notification {
    display: flex;
    align-items: center;
    margin-left: 20px;
    width: 100%;
    height: 20px;
    font-size: 16px;
    font-weight: 400;
}

.basket_bonuses_info.change:hover {
    color: #75757575;
}

.wrapper_bonuses_input {
    margin-left: 10px;
    display: flex;
    height: 50px;
    border: 1px solid #D7D6D6;
}

.bonuses_input {
    display: flex;
    height: 100%;
    max-width: 80px;
    flex-grow: 1;
    padding: 0px 10px;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    opacity: 1;
    color: #000000;
    text-align: left;
}

.bonuses_button {
    height: 100%;
    width: 50px;
    position: relative;
}

.bonuses_button:hover {
    background-color: black;
    transition: background-color 0.4s ease 0s;
    cursor: pointer;
}

.bonuses_button:after {
    content: "";
    position: absolute;
    width: 2px;
    height: 15px;
    background-color: #8d8d8d;
    left: 50%;
    top: 13px;
    border-radius: 2px;
    transform: rotate(135deg);
}

.bonuses_button:hover:after {
    background-color: white;
    transition: background-color 0.4s ease 0s;
}

.bonuses_button:before {
    content: "";
    position: absolute;
    width: 2px;
    height: 15px;
    background-color: #8d8d8d;
    left: 50%;
    top: 22px;
    border-radius: 2px;
    transform: rotate(-135deg);
}

.bonuses_button:hover:before {
    background-color: white;
    transition: background-color 0.4s ease 0s;
}

@media (max-width: 420px) {

    .basket_bonuses_info {
        white-space: wrap;
        font-size: 14px;
    }

    .basket_bonuses_info.change {
        font-size: 14px;
    }

    .bonuses_notification {
        font-size: 14px;
    }

}

@media (max-width: 1024px) {

    .input_wrapper_summ {
        flex-direction: column;
        align-items: flex-start;
    }

    .basket_bonuses_info.change {
        margin: 20px 0 0 0;
    }

    .main_manual {
        flex-direction: column;
        align-items: flex-start;
    }

    .bonuses_notification {
        display: block;
        text-align: center;
        margin: 10px auto;
    }
  
}