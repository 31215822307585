.product_one_item_container_f {
    flex-direction: column;
    width: 100%;
    height: 100%;
    display: none;
}
.product_one_item_container_f.active {
    display: flex;
}

.product_color_info {
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
}

.div_wrapper_colors {
    display: none;
    max-height: 220px;
    /*background-color: pink;*/
    overflow-y: scroll;
    border: 1px solid #c9cccf;
}
.div_wrapper_colors.active {
     display: flex;
     flex-direction: column;
 }
.one_item_color_colors_selector {
    display: flex;
    padding: 2px 5px;
    width: 100%;
    cursor: pointer;
}
.one_item_color_colors_selector:hover {
    background-color: #BCBCBC;
    transition: background-color 0.4s ease 0s;
}
.wrapper_sizes_container_onprd {
    display: flex;
    margin-top: 20px;
    font-size: 12px;
}
.show_active_sizes {
    margin-top: 20px;
    display: flex;
    font-size: 14px;
    font-weight: 600;
}
.size_one_item_in_all {
    margin-left: 20px;
    max-width: 100px;
    min-height: 20px;
    padding: 5px;
    border: 1px solid lightgray;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
}
.size_one_item_in_all:hover {
    background-color: black;
    color: white;
    cursor: pointer;
}
.current_sizes_wrapper_active {
    display: flex;
    margin-top: 20px;
}
.current_sizes_this_product {
    min-width: 35px;
    height: 35px;
    padding: 5px;
    border-radius: 50%;
    border: 1px solid lightgrey;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c2efd8;
    cursor: pointer;
    position: relative;
}
.current_sizes_this_product.noavailable {
    background-color: white;
    opacity: 0.85;
}



/*.current_sizes_this_product:hover {*/
/*    background-color: #E69191;*/
/*}*/

.show_all_sizes {
    display: flex;
    margin-top: 20px;
    font-weight: 600;
}
.page_imitator {
    display: flex;
    min-height: 500px;
    width: 100%;
    margin-top: 20px;
    border: 1px solid #c9cccf;
    border-radius: 5px;
    flex-direction: column;
}

.wrapper_two_images_t {

    display: flex;
    justify-content: space-between;
    max-width: 700px;
    width: 100%;
    max-height: 250px;
    position: relative;
    padding: 15px;
    border: 1px solid #c9cccf;
    border-radius: 5px;
}

.wrapper_top_images_container {
    display: flex;
    max-width: 800px;
    margin: 0 auto;
    justify-content: center;
    padding: 15px;
    width: 100%;
    height: 310px;
}
.bit_image_child_products {
    min-height: 325px;
    height: 100%;
    display: flex;
    width: 100%;
    border: 1px solid #c9cccf;
    border-radius: 5px;
    padding: 15px;
    margin-top: 20px;
    position: relative;
}



.pre_info_upload_horizontal {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    font-weight: 600;
    font-size: 13px;
    text-align: center;
}

.other_images_container {
    display: flex;
    width: 100%;
    padding: 15px;
    height: auto;

}
.child_category_container {
    display: flex;
    min-height: 160px;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
    border: 1px solid #c9cccf;
    border-radius: 5px;
    padding: 50px;
}
.child_item_category_indicator {
    height: 50px;
    width: 180px;
    border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    margin: 0px 30px 0px 30px;
    cursor: pointer;
    opacity: 0.5;
}
.child_category_heading {
    display: flex;
    width: 100%;
    text-align: center;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
    margin-top: 30px;
}
.child_item_category_indicator.active {
    background-color: black;
    color: white;
    opacity: 1;
}

.child_item_category_indicator:hover {
    box-shadow: 0px 0px 4px 0px rgb(34 60 80 / 80%);
}
.wrapper_one_selected_size {
    position: relative;
    padding: 5px;
}

.choosing_which_field_add {
    display: flex;
    flex-direction: column;
    display: flex;
    width: 100%;
    max-width: 100%;
    min-height: 200px;
    padding:  30px;
}
.choosing_heading {
    display: flex;
    justify-content: center;
    width: 100%;
    font-weight: 600;
    font-size: 16px;

}

.wrapper_choosing_blocks {

    display: flex;
    /* max-width: 700px; */
    width: 100%;
    margin: 0 auto;
    margin-top: 30px;
    height: 200px;
    /*background-color: pink;*/
    /*justify-content: space-between;*/
    justify-content: center;

}


.image_imitator_choosing {
    display: flex;
    height: 100%;
    width: 49%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border: 1px solid lightgrey;
}
.image_imitator_choosing:hover {
    border-color: black;
    cursor: pointer;
}
.one_image_choosing {
    height: 80%;
    width: 80%;
    background-color: #d3d3d366;
}

.two_image_choosing {
    margin: 10px;
    width: 100%;
    height: 80%;
    background-color: #d3d3d366;

}
.image_imitator_choosing:hover .one_image_choosing {
    background-color: lightgrey;
    transition: background-color 0.3s ease 0s;

}
.image_imitator_choosing:hover .two_image_choosing {
    background-color: lightgrey;
    transition: background-color 0.3s ease 0s;
}
.choosing_generate_blocks {
    max-width: 900px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin-top: 20px;
}
.double_image_gen_wrapper {
    display: flex;
    min-height: 300px;
    width: 100%;
    margin-top: 20px;
    border: 1px solid #c9cccf;
    border-radius: 5px;
    padding: 15px;
    justify-content: space-between;
    position: relative;
}
.double_image_gen_wrapper .preview_container_img {
    margin: 0 5px 0 5px;
    height: auto;
    width: 49%;
    max-width: 49%;

}
.atention_text_choosing {
    display: flex;
    margin: 0 auto;
    margin-top: 20px;
    font-size: 12px;
    color: #949393;
    max-width: 300px;
    width: 100%;
    text-align: center;
    font-weight: 600;
}


.section_choose_controller {
    position: absolute;
    right: -100px;
    top: 0px;
    width: 80px;
    height: 200px;
    border: 1px solid #c9cccf;
    border-radius: 5px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.all_chose_ct {
    display: flex;
    width: 100%;
    height: 30%;
    position: relative;
}
.all_chose_ct:hover {
    background-color: lightgrey;
    cursor: pointer;
}

.choose_delete_controller:after {
    position: absolute;
    content: "";
    top:50%;
    left: 50%;
    height: 2px;
    width: 35px;
    background-color: black;
    transform: translate(-50%,-50%) rotate(45deg);
}
.choose_delete_controller:before {
    position: absolute;
    content: "";
    top:50%;
    left: 50%;
    height: 2px;
    width: 35px;
    background-color: black;
    transform: translate(-50%,-50%) rotate(-45deg);
}


.choose_up_controller:after {
    position: absolute;
    content: "";
    top: 50%;
    left: 63%;
    height: 2px;
    width: 24px;
    background-color: black;
    transform: translate(-50%,-50%) rotate(45deg);
    border-radius: 5px;

}
.choose_up_controller:before {
    position: absolute;
    content: "";
    top: 50%;
    left: 39%;
    height: 2px;
    width: 24px;
    background-color: black;
    transform: translate(-50%,-50%) rotate(-45deg);
    border-radius: 5px;
}



.choose_down_controller:after {
    position: absolute;
    content: "";
    top: 50%;
    left: 63%;
    height: 2px;
    width: 24px;
    background-color: black;
    transform: translate(-50%,-50%) rotate(-45deg);
    border-radius: 5px;

}
.choose_down_controller:before {
    position: absolute;
    content: "";
    top: 50%;
    left: 39%;
    height: 2px;
    width: 24px;
    background-color: black;
    transform: translate(-50%,-50%) rotate(45deg);
    border-radius: 5px;
}

.switch_active_size {
    width: 100px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    font-weight: 600;
    background-color: gray;
    color: white;
    cursor: pointer;
    margin-top: 20px;
}

.switch_active_size.active {
    background-color: green;
    color: black;
}

.active_child_product {
     padding: 5px;
    display: flex;
    min-width: 100px;
    width: max-content;
    height: 30px;
    cursor: pointer;
    border: 1px solid black;
    margin-top: 10px;
    margin-bottom: 30px;
    justify-content: center;
    align-items: center;
    background-color: grey;
    color: white;
    font-weight: 600;
}

.update_notificator {
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 40px;
    display: flex;
    color: darkred;
}
.active_child_product.active {
    background-color: green;
    color: black;
}