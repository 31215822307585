.email_input_wrapper {
    /*height: 60px;*/
    display: flex;
    flex-direction: column;
    margin-top: 40px;
}
.title_email_lk {
    font-weight: 500;
    font-size: 16px;
    color: #1A1A1A;
}
.email_input_wrapper.error input {
    border-color: #CE3F3F;
    transition: border-color 0.4s ease 0s;
}

.email_input_err_message {
    min-height: 15px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #CE3F3F;
    font-size: 11px;
    /*text-transform: uppercase;*/
    font-weight: 600;
    letter-spacing: 0.03em;
}
.user_email_component {
    width: 100%;
    height: 44px;
    padding: 5px;
    display: flex;
    align-items: center;
    border: 1px solid #D7D6D6;
    /*margin-top: 0px;*/
    font-size: 17px;
    letter-spacing: 0.03em;
    font-weight: 500;
    margin-top: 7px;
}

.user_email_component:focus-visible {
    outline: none;
}

.user_email_component::placeholder {
    font-weight: 500;
    font-size: 16px;
    color: #757575;
}