.container_text_policy{
    display: flex;
    padding: 30px 0px;
}
.footer_span_info_shop{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-transform: uppercase;
    color: #C7C7C7;
}
.footer_span_policy {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-transform: uppercase;
    color: #C7C7C7;
    margin-left: 60px;
}
.footer_style_policy {
    color: black;
    text-decoration: none;
}
.footer_style_policy:hover {
    border-bottom: 1px solid black;
    transition: border-bottom 0.2s ease 0s;
}


@media (max-width: 1024px) {
    .container_text_policy {
        text-align: center;
        flex-direction: column-reverse;
        padding: 40px 0px;
    }
    .footer_span_policy {
        margin-left: 0px;
    }
    .footer_span_info_shop {
        margin-top: 20px;
    }
}
@media (max-width: 767px) {
    .container_text_policy {
        flex-direction: column;
    }
    .footer_span_info_shop {
        margin-top: 0px;
    }
    .footer_span_policy {
        margin-top: 20px;
    }
}
@media (max-width: 428px) {
    .container_text_policy {
        text-align: start;
    }
}

@media (max-width: 400px) {
    .container_text_policy {
        padding: 35px 0px 24px 0px;
    }
}
