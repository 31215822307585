.block_new {
    width: 100%;
    margin-top: 60px;
}
.title_and_arrows {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 40px;
}
.title_new_block {
    font-weight: 500;
    font-size: 30px;
    display: flex;
    color: #1A1A1A;
}
.title_new_block a {
    text-decoration: none;
    color: inherit;
}

.arrows_new_block {
    display: flex;
    max-width: 60px;
    width: 100%;
    justify-content: space-between;
}
.one_arrows_new {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.product_scroll {
    margin-top: 40px;
    display: flex;
    align-items: center;
    overflow-x: scroll;
    padding-bottom: 25px;
    scroll-behavior: smooth;
}
.product-slider {
    margin-top: 40px;
    display: flex;
    flex-direction: column-reverse;
}
.product-slider .swiper-slide {
    width: max-content;
}
.product-slider .swiper-scrollbar {
    margin-top: 25px;
    position: static;
    background: #cecece;
    height: 3px;
    width: auto;
    border-radius: 0;
}
.product-slider .swiper-scrollbar-drag {
    background: rgb(25,25,25);
    border-radius: 0;
}
.product_scroll::-webkit-scrollbar {
    height: 3px;
}
.product_scroll::-webkit-scrollbar-track {
    background: #cecece;
    border: 1px solid transparent;
    background-clip: content-box;   /* THIS IS IMPORTANT */
}
.product_scroll::-webkit-scrollbar-thumb {
    background: rgb(25,25,25);
    border: 1px solid rgb(0, 0, 0);
}
.link_one_cards {
    display: block;
    width: max-content;
    border: 1px solid transparent;
    transition: border 0.2s ease 0s;
}
.link_one_cards:hover {
    border: 1px solid black;
    transition: border 0.4s ease 0s;
}
.one_img_new_scroll {
    display: flex;
}
.one_img_new_scroll img {
    display: flex;
    width: 265px;
    height: 100%;
    max-height: 353px;
    object-fit: contain;
}

@media (max-width: 1024px) {
    .title_new_block {
        font-size: 18px;
        line-height: 23px;
    }
    .product_scroll {
        margin-top: 20px;
    }
    .product-slider {
        margin-top: 20px;
    }
}


@media (max-width: 768px) {
    .block_new {
        margin-top: 40px;
    }
    .title_and_arrows {
        padding: 0px 20px;
        flex-direction: column;
        align-items: flex-start;
    }
    .arrows_new_block {
        margin-top: 20px;
    }
    .product_scroll {
        margin-top: 18px;
        padding-bottom: 20px;
    }
    .product-slider .swiper-scrollbar {
        margin-top: 20px;
    }
    .product-slider {
        margin-top: 18px;
    }
    .one_img_new_scroll img {
        width: 131px;
    }

}