
.q_conteiner {
    /*max-width: 1233px;*/
    width: 100%;
    /*margin: 0 auto;*/
    /*padding: 20px 0px;*/
    /*margin-top: 18px;*/
    border-bottom: 2px solid black;
}
.q_conteine_child {
    /*background-color: white;*/
    /*border: 1px solid #E0E0E0;*/
    /*box-sizing: border-box;*/
    /*box-shadow: 0px 0px 20px rgb(0 0 0 / 15%);*/

}
.q_title {
    display: flex;
    justify-content: space-between;
    align-items: center;

}
.q_title.open {
    align-items: flex-start;
    transition: align-items 0.8s ease 0s;
}
.q_subline {
    padding: 16px 0px;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #000000;
    display: flex;
    cursor: pointer;

}
.q_wrap_batton {
    padding: 18px;
    position: relative;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #808080;
}
.q_button {
    display: flex;
    width: 24px;
    height: 24px;
    position: relative;
    /*background: #06ff00;*/
    cursor: pointer;
}
.q_dropdown {
    display: flex;
    padding-bottom: 32px;
}

.q_button.hide,.q_button.show, .q_button_hide, .q_button_show {

}

    .q_button::before {
        content: "";
        width: 14px;
        height: 2px;
        background: #000000;
        position: absolute;
        right: 5px;
        transform: rotate(0deg);
        top: 10px;
    }
    .q_button::after {
        content: "";
        width: 14px;
        height: 2px;
        background: #000000;
        position: absolute;
        right: 5px;
        transform: rotate(-90deg);
        top: 10px;
    }

    .q_button.hide::after {
        opacity: 0;
        transition: opacity .4s ease 0s;
    }
    .q_button.hide::before {

    }

.q_line {
    display:flex;
    align-items: center;
    justify-content: space-between;
}
.q_border {
    background-color: #E0E0E0;
    height: 1px;
    width: 100%;
}
    .q_border_text {
        padding: 0px 10px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        text-align: center;
        color: #000000;
        white-space: nowrap;
    }
    .q_border.one {
        max-width: 54px;
    }
    .q_border.two {
        max-width: 1010px;
    }
.q_text {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    /*padding-bottom: 32px;*/
}
.fqa_one_paragrah {
    display: flex;
    flex-direction: column;
}
.fqa_one_paragrah span {
    margin-top: 10px;
    white-space: pre-line;
}

.q_text p:first-child {
    margin-top: 0px;
}
.line_text_fqa_parents {
    padding: 0px 20px;
}
.line_text_fqa {
    width: 1px;
    min-height: 20px;
    height: 100%;
    background: #000000;
}

@media (max-width: 1440px) {

}
@media (max-width: 1024px) {
    .q_subline {
        font-size: 16px;
    }
    .q_text p {
        font-size: 16px;
    }
}
@media (max-width: 768px) {

    .q_subline {
        font-size: 14px;
    }
    .q_wrap_batton {
        padding: 16px;
    }
    .q_text p {
        font-size: 14px;
    }
    .q_dropdown {
        padding-bottom: 20px;
    }
}
