.payment_page {
    display: flex;
    /*height: 100%;*/
    width: 100%;
    flex-direction: column;
    align-items: center;
}
.payment_page_child {
    max-width: 1940px;
    padding: 0px 10px;
}
.payment_banner {
    /*width: 100%;*/
    /*height: 831px;*/
    /*position: relative;*/
}
.banner_content_image {
    width: 100%;
    object-fit: cover;
    height: 100%;
}

.payment_abs_div_content {
    /*width: 100%;*/
    /*display: flex;*/
    /*position: absolute;*/
    /*top: 250px;*/
}
.static_div_content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /*padding: 0px 15px;*/
}

.content_page_name_sp {
    font-weight: 500;
    font-size: 30px;
    line-height: 39px;
    color: #000000;
}
.white_block_steps {
    display: flex;
    /*max-width: 1546px;*/
    width: 100%;
    /*min-height: 475px;*/
    background-color: white;
    /*margin: 0 auto;*/
    margin-top: 40px;
    padding: 40px 0px;
    flex-direction: column;
    border: 1px solid #000000;
    /*box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);*/
}
.white_block_steps_title {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 31px;
    color: #000000;
    display: flex;
    padding-left: 54px;
}
.steps_wrapper_white_block {
    width: 100%;
    height: 100%;
    display: flex;
    margin-top: 40px;
    position: relative;
}
.line_abs_steps_support {
    height: 35px;
    display: flex;
    width: 100%;
    align-items: center;
    position: absolute;
    top: 0px;
}
.line_abs_steps_line {
    width: 100%;
    display: flex;
    height: 2px;
     background-color:  #E0E0E0;
}
.steps_items_wrapper {
    display: flex;
    height: 100%;
    width: 100%;
    padding: 0px 54px;
}
.one_step_item {
    max-width: 186px;
    width: 100%;
    margin-left: 40px;
    display: flex;
    flex-direction: column;
}
.one_step_item:first-child {
    margin-left: 0px;
}

.one_step_counter {
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    border-radius: 50px;
    z-index: 1;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
}
.step_title_one_step {
    display: flex;
    margin-top: 18px;
    font-weight: 700;
    font-size: 16px;
    color: #000000;
}

.one_step_item_text {
    display: flex;
    /*width: 100%;*/
    margin-top: 18px;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #000000;
}

.payment_page_content_aft {
    display: flex;
    flex-direction: column;
    margin-bottom: 54px;
    /*align-items: center;*/
    margin-top: 40px;
}
/*.content_aft_line {*/
/*    max-width: 919px;*/
/*    width: 100%;*/
/*    margin: 0 auto;*/
/*    height: 1px;*/
/*    background: #E0E0E0;*/
/*    margin-top: 82px;*/
/*}*/
.aft_heading_text {
    display: flex;
    max-width: 770px;
    width: 100%;
    font-weight: 500;
    font-size: 24px;
    line-height: 31px;
    color: #000000;
}
.payment_page_methods {
    display: flex;
    width: 100%;
    /*justify-content: center;*/
    flex-wrap: wrap;
    margin-top: 40px;
}

.payment_page_methods_item_wrapper {
    padding: 3px;
}
/*.payment_page_methods_item_wrapper:first-child {*/
/*    padding-left: 0px;*/
/*}*/
.payment_page_methods_item {
    max-width: 186px;
    min-height: 158px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    padding: 15px;
    /*box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);*/
}
.img_method_wrapper {
    max-width: 120px;
    max-height: 51px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

}
.img_method_wrapper img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}
.text_method_wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    /*padding: 0px 35px;*/
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #000000;
    margin-top: 19px;
}

/*.two_line_br {*/
/*    display: flex;*/
/*    max-width: 919px;*/
/*    width: 100%;*/
/*    margin: 0 auto;*/
/*    font-weight: 700;*/
/*    font-size: 24px;*/
/*    line-height: 29px;*/
/*    text-align: center;*/
/*    height: 1px;*/
/*    background: #E0E0E0;*/
/*    margin-top: 80px;*/
/*}*/
.payment_shadow_text {
    max-width: 916px;
    width: 100%;
    margin-top: 40px;
}
.payment_shadow_text p {
    margin-bottom: 30px;
    margin-top: 0px;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #757575;
}
.payment_shadow_text p:last-child {
    margin-bottom: 0px;
}


/*.payment_shadow_text .spanwrap {*/
/*    margin-bottom: 25px;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    flex-direction: column;*/
/*}*/
.payment_shadow_text span {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #757575;
    white-space: pre-line;
}
.opening_block_text {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
}
.button_add_text {
    color: rgba(0, 0, 0, 0.3);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    border-bottom: 1px solid transparent;
    transition: border-bottom 0.4s ease 0s;
    margin-top: 15px;
}
.button_add_text:hover {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    transition: border-bottom 0.4s ease 0s;
}



@media (max-width: 1024px) {
    .payment_page_child{
        padding: 0px;
    }
    .content_page_name_sp {
        padding-left: 40px;
        font-size: 24px;
        line-height: 31px;
    }
    .steps_wrapper_white_block {
        margin-top: 18px;
    }
    .white_block_steps {
        border: 1px solid transparent;
        padding: 0px;
    }
    .white_block_steps_title {
        padding-left: 40px;
        font-size: 16px;
        line-height: 21px;
    }
    .steps_items_wrapper {
        padding: 0px 26px 0px 40px;
    }
    .one_step_item {
        margin-left: 24px;
    }
    .one_step_item_text {
        margin-top: 10px;
        font-size: 12px;
        margin-left: 4px;
    }
    .payment_page_content_aft {
        padding: 0px 40px;
        margin-bottom: 40px;
        margin-top: 48px;
    }
    .aft_heading_text {
        font-size: 16px;
        line-height: 21px;
    }
    .payment_page_methods {
        margin-top: 18px;
    }
    .payment_page_methods_item_wrapper {
        padding: 5px;
    }
    .payment_page_methods_item {
        max-width: 151px;
        min-height: 128px;
        padding: 13px;
    }
    .img_method_wrapper {
        max-width: 95px;
        max-height: 42px;
    }
    .payment_shadow_text span {
        font-size: 12px;
    }
}


@media (max-width: 768px)  {
    .payment_abs_div_content {
        padding: 0px 20px;
    }
    .content_page_name_sp {
        font-size: 18px;
        line-height: 23px;
        padding-left: 0px;
    }
    .white_block_steps {
        margin-top: 40px;
    }
    .white_block_steps_title {
        padding-left: 0px;
    }
    .steps_wrapper_white_block {
        margin-top: 20px;
        flex-direction: column;
    }
    .steps_items_wrapper {
        flex-direction: column;
        padding: 0px;
    }
    .line_abs_steps_support {
        width: 15px;
        height: 100%;
        justify-content: center;
    }
    .line_abs_steps_line {
        width: 1px;
        height: 100%;
    }
    .one_step_counter {
        width: 15px;
        height: 15px;
        background: black;
    }
    .one_step_item {
        margin-left: 0px;
        margin-top: 20px;
        position: relative;
        max-width: 100%;
    }
    .one_step_item:first-child {
        margin-top: 0px;
    }
    .step_title_one_step {
        margin-top: 0px;
        position: absolute;
        margin-left: 20px;
        top: 0;
        font-size: 12px;
        line-height: 16px
    }
    .one_step_item_text {
        margin-left: 20px;
    }
    .payment_page_content_aft {
        margin-top: 40px;
        padding: 0px 20px;
    }
    .aft_heading_text {
        font-size: 14px;
        line-height: 18px;
    }
    .payment_page_methods {
        margin-top: 15px;
    }
    .payment_page_methods_item_wrapper {
        padding: 2px;
    }
    .payment_page_methods_item {
        max-width: 82px;
        min-height: 75px;
        padding: 2px;
    }
    .img_method_wrapper {
        max-width: 55px;
        max-height: 23px;
    }
    .text_method_wrapper {
        margin-top: 8px;
        font-size: 8px;
        line-height: 10px;
    }
    .payment_shadow_text {
        margin-top: 33px;
    }
}
