.stocks-trends__text-pictures {
	display:flex;
	gap:2rem;
	justify-content: space-between;
	padding: 2rem 0;
}

.stocks-trends__text-pictures figure{
	margin-inline:auto;
}
	
.stocks-trends__text-pictures img{
	width:100%;
	max-width:450px;
	margin-inline:auto;
}
.stocks-trends__gallery-description {
	color: rgba(26, 26, 26, 1);
	font-family: "IBM Plex Mono";
	font-size: 18px;
	font-weight: 400;
	line-height: 28.8px;
	letter-spacing: -0.02em;
	text-align: center;
}