.promo_page_wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    padding: 20px;
}

.promo_page_title {
    margin-top: 15px;
    margin-left: 10px;
    font-weight: 600;
    font-size: 22px;
}

.form-control {
   width: 100%;
   height: 100%;
}

.rdt {
    width: 180px;
    height: 40px;
    font-weight: 600;
}

.promo_page_content_container {
    background-color: #fff;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    display: flex;
    width: 100%;
    margin-top: 20px;
    padding: 10px;
    flex-direction: column;
}
.promo_page_content_container.add_new {
    flex-direction: row;
    flex-wrap: wrap;
}
.wrapper_for_promo_settings {
 display: flex;
    flex-direction: column;
}
.promo_dop_options_controll {
    display: flex;
    width: 280px;
    height: 40px;
    font-weight: 600;
    border: 1px solid black;
    color: black;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    cursor: pointer;
    border-radius: 5px;
}
.promo_product_conditions {
    display: flex;
    flex-direction: column;
    margin-left: 40px;
}

.promo_dop_options_controll:hover {
    background-color: black;
    color: white;
    transition: background-color 0.4s ease 0s, color 0.4s ease 0s;
}

.promocode_dialog_wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}
.promocode_variable_settings {
    display: flex;
}
.promo_page_content_container_heading {
    display: flex;
    margin-top: 20px;
    font-weight: 600;
    font-size: 18px;
    /*text-transform: uppercase;*/
}
.product_selector_cat_or_product {
    display: flex;
    padding: 10px 10px;
    font-weight: 600;
    border: 1px solid black;
    color: black;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    cursor: pointer;
    border-radius: 5px;
    margin-left: 30px;
}
.product_selector_cat_or_product:first-child {
    margin-left: 0px;
}
.product_selector_cat_or_product.active {
    background-color: black;
    color: white;
    transition: background-color 0.4s ease 0s, color 0.4s ease 0s;
}


.select_promo_type_container {
    display: flex;
    margin-top: 30px;
}
.promo_page_select_promo_type {
    display: flex;
    font-weight: 600;
    text-transform: uppercase;
    padding: 7px 14px;
    border: 1px solid black;
    margin-left: 20px;
    cursor: pointer;
    border-radius: 5px;
}
.promo_page_select_promo_type:first-child {
    margin-left: 0px;
}

.promo_page_select_promo_type:hover {
    background-color: black;
    color: white;
    transition: background-color 0.4s ease 0s, color 0.4s ease 0s;

}

.promo_page_select_promo_type.active {
    background-color: black;
    color: white;
    transition: background-color 0.4s ease 0s, color 0.4s ease 0s;
}
.promo_settings_detail {
 display: flex;
    flex-direction: column;
    padding-bottom: 10px;
}
.promo_input{
    text-transform: uppercase;
}
.promo_settings_detail_wrapper_input {
    display: flex;
    max-width: 550px;
    margin-top: 15px;
    flex-direction: column;
}
.promo_input_heading {
    display: flex;
    color: black;
    font-weight: 600;
    font-size: 14px;
}
.promo_settings_detail_wrapper_input input {
    flex-grow: 1;
    height: 38px;
    border: 1px solid #DADADA;
    border-radius: 5px;
    padding-left: 5px;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    margin-top: 5px;
    font-weight: 600;
}
.promo_settings_detail_wrapper_input .select_promo_type_container {
    margin-top: 10px;
}
.promo_add_section_and_notification {
    display: flex;
    margin-top: 30px;
    flex-direction: column;
    max-width: 550px;
}
.promo_add_button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 14px;
    border: 1px solid black;
    font-weight: 600;
    cursor: pointer;
}
.promo_add_button:hover {
    background-color: black;
    color: white;
    transition: background-color 0.4s ease 0s, color 0.4s ease 0s;
}
.promo_server_response {
    margin-top: 30px;
    font-weight: 500;
    color: #CE3F3F;
    text-transform: uppercase;
}
.promo_variable_container {
    display: flex;
    margin-top: 20px;
    flex-direction: column;
}
.one_promo_information {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    position: relative;
}
.one_promo_left_container {
    display: flex;
    flex-direction: column;
}
.one_promo_info {
    display: flex;
}

.one_promo_info_controll_panel {
    display: flex;
    flex-direction: column;
    width: 145px;
    margin-left: 20px;
}

.one_promo_info_wrapper {
    display: flex;
    margin-top: 15px;
}
.one_promo_name_param {
    font-weight: 600;
    font-size: 16px;
}
.one_promo_value_param {
    display: flex;
    margin-left: 30px;
}
.category_select_from_promo_wrapper {
    width: 800px;
    height: 400px;
    margin-top: 30px;
    display: flex;
    border: 1px solid gray;
}
.this_category_list_promo {
    max-width: 400px;
    width: 100%;
    max-height: 400px;
    overflow-y: scroll;
}
.selected_category_on_promo {
    max-width: 350px;
    margin-left: 30px;
    width: 100%;
    max-height: 400px;
    overflow-y: scroll;
}
.one_line_promo_category {
    padding: 5px 10px;
}
.one_line_promo_category:hover {
    cursor: pointer;
    background-color: lightgrey;
}

.selected_category_heading_line {
    font-weight: 600;
    width: 100%;
    display: flex;
    border-bottom: 1px solid black;
}

.promocode_products_container {
    display: flex;
    height: 400px;
    width: 800px;
    margin-top: 30px;
    border: 1px solid gray;
    flex-direction: column;
    position: relative;
}
.search_catalog_products_for_promo {
    display: flex;
    height: 40px;
    width: 100%;
    border: 1px solid black;
    padding-left: 10px;
    font-weight: 600;
}
.search_catalog_products_for_promo.disactive {
    user-select: none;
    pointer-events: none;
    font-weight: 600;
    background-color: pink;
    color: black;
}

.product_search_wrapper_promo {
    height: 400px;
    overflow-y: scroll;
    position: relative;
}
.promo_one_search_item {
    display: flex;
    position: relative;
    height: 30px;
    width: 100%;
    align-items: center;
    cursor: pointer;
    padding-left: 10px;
}
.promo_one_search_item:hover {
    background-color: lightgray;
}

.promo_color_indicator {
    display: flex;
    margin-left: 30px;
}
.product_promo_config {
    display: flex;
    flex-direction: column;
    position: absolute;
    letter-spacing: 0px;
    bottom: 0px;
    height: 250px;
    overflow-y: scroll;
    width: 800px;
    z-index: 2;
    background-color: white;
    border: 1px solid black;

}
.promo_for_collors_wrapper {
    display: flex;
}

.promo_one_prod_color {
    display: flex;
    height: 40px;
    align-items: center;
    cursor: pointer;
    background-color: white;
    padding-left: 10px;
}
.promo_one_prod_color.active {
    background-color: lightgreen;
}
.promo_one_prod_color:hover {
    background-color: lightgray;
}
.close_abs_modal {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 30px;
    cursor: pointer;
    background-color: palevioletred;
}
.close_abs_modal:hover {
    background-color: lightgrey;
}
.one_item_of_list_products_promo {
    margin-top: 10px;
    padding: 10px;
    cursor: pointer;
    align-items: center;
    border: 1px solid lightgrey;
    width: max-content;
}
.one_item_of_list_products_promo:hover {
    background-color: lightgrey;
}
.info_list_promo_options {
    display: flex;
    flex-direction: column;
    margin-left: 30px;
}
.one_promo_controll_btn {
    display: flex;
    padding: 10px;
    margin-top: 20px;
    border: 1px solid black;
    flex-direction: column;
    cursor: pointer;
    font-weight: 600;
}
.one_promo_controll_btn:hover {
    background-color: black;
    color: white;

}

.one_promo_one_list {
    display: flex;
    margin-top: 5px;
}
.one_promo_category_list {
    margin-left: 40px;
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    width: 700px;
    overflow-y: scroll;
    max-height: 265px;
}

.delete_one_promo {
    display: flex;
    margin-left: 30px;
    padding: 0px 15px;
    border: 1px solid black;
    height: 50px;
    align-items: center;
    font-weight: 600;
    cursor: pointer;
    background-color: #f0aeae;
}
.delete_one_promo:hover {
    background-color: black;
    color: white;
}