:root {
    --img_in_text_hint_color:#80808075;
}
.add_one_stock_and_trend {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.add_stock_and_trend_space_wrap {
    height: 100%;
    padding: 18px;
    min-width: 954px;
}

.add_stock_and_trend_space_container {
    border-radius: 5px;
    display: flex;
    min-width: 916px;
    height: 100%;
    width: 100%;
}

.item_type_change_buttons {
    display: flex;
}

.add_stock_and_trends__add-sliders-buttons {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
}

.btn_for_control_item_type {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 14px;
    font-weight: 600;
    border: 1px solid black;
}
.btn_for_control_item_type {
    margin-left: 25px;
    cursor: pointer;
    transition: all 0.4s ease 0s;
}
.btn_for_control_item_type:hover {
    background-color: black;
    color: white;
    transition: all 0.4s ease 0s;
}
.btn_for_control_item_type:first-child {
    margin-left: 0px;
}
.btn_for_control_item_type.active {
    background-color: black;
    color: white;
    transition: all 0.4s ease 0s;
}

.wrapper_two_images_stock {
    width: 400px;
    display: flex;
    justify-content: space-around;
    max-width: 700px;
    max-height: 250px;
    position: relative;
    padding: 15px;
    border: 1px solid #c9cccf;
    border-radius: 5px;
}

.wrapper_top_images_stock_container {
    display: flex;
    max-width: 800px;
    margin: 0 auto;
    justify-content: center;
    padding: 15px;
    width: 100%;
    height: 310px;
}

.stock_slider_one_image {
    margin: 10px;
    width: 150px;
}
.stock_slider_insert_in_text_hint {
    text-align: left;
    margin-inline: auto;
    display: block;
    color: var(--img_in_text_hint_color);
    margin-bottom: .5rem;
    font-size: 12px;
}
.stock_slider_insert_in_text_hint--big {
    font-size: 1.3rem;
    text-align: left;
    margin-left:0;
}
.field_hint_message {

}
.stock_and_trend_text_image_descr {
    margin-top:1rem;
}

.stock_slider_images {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding: 15px;
    border: 1px solid #c9cccf;
    border-radius: 5px;
    gap: 2rem;
}

.stock_and_trend_information {
    width: 100%;
    margin-top: 35px;
    background-color: #fff;
    box-shadow: 0 0 5px rgba(23, 24, 24, 0.05), 0 1px 2px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 20px;
}