.returns-item-page {
    margin-top: 30px;
    padding: 25px;
}

.returns-item-page__field {
    display: flex;
    margin-bottom: 10px;
}

.returns-item-page__field-title {
    margin-right: 10px;
}

.returns-item-page .admin_inputs_wrapper {
    width: 100%;
}
.fields_refund_wrapper {
    width: 65%;
}
.control_buttons_refund {
    margin-top: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.return_and_status_button {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.return_refunds_page {
    display: flex;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;
    color: #000000;
}
.return_refunds_page svg {
    margin-right: 20px;
}
.status_refund {
    border: 1px solid #020202;
    padding: 7px;
    display: flex;
    max-width: 200px;
}