.adm_page_not_found {
    padding: 20px;
}
.title_not_found {
    display: flex;
    width: 100%;
    height: 76px;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.03em;
}
.content_not_found {
    display: flex;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    padding: 10px;
}
.field_not_found_wrap {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    width: 100%;
    padding: 5px;
}
.name_field_not_found {
    font-weight: 600;
    font-size: 12px;
}
.fields_container_nf {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-right: 20px;
}
.input_not_found {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #c9cccf;
    font-weight: 600;
    font-size: 12px;
    padding: 5px 5px;
    border-radius: 5px;
    margin-top: 5px;
}
.input_not_found.textarea {
    resize: none;
    height: 60px;
}
.img_not_found{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.one_img_not_found {
    padding: 0px 10px;
    max-width: 300px;
    width: 100%;
}
.one_img_not_found .preview_container_img {
    max-width: 300px;
    width: 100%;
}
.save_not_found {
    display: flex;
    height: 40px;
    width: 500px;
    margin: 0 auto;
    margin-top: 20px;
    background: white;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    font-weight: 600;
    cursor: pointer;
}
.save_not_found:hover {
    box-shadow: 0px 0px 4px 0px rgb(34 60 80 / 80%);
}

.save_not_found.trig {
    animation: trigerSaveNotFound 2s ease infinite;
}
@keyframes trigerSaveNotFound{
    0% {
        background: white;
        color: black;
    }
    50% {
        background: black;
        color: white;
    }
    100% {
        background: white;
        color: black;
    }

}