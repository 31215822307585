.wrap_notfound {
    background-color: rgba(240,103,1,1);
    width: 100%;
    min-height: 100vh;
    /*background: linear-gradient(178.71deg, #F26903 19.91%, #F36002 98.9%);*/
    margin-bottom: 32px;
    z-index: 1;
}
.child_nf{
    width: 100%;
    height: 100%;
    position: relative;
}
.content_nf_wrapper {
    width: 100%;
    height: 100%;
    display: flex;
}
.content_nf {
    position: relative;
    z-index: 2;
    max-width: 1312px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 0 40px 40px;
}
.absolute_nf {
    width: 100%;
    height: 100%;
}
.background_nf {
    position: absolute;
    object-fit: contain;
    width: 100%;
    height: 100%;
    z-index: -1;
    bottom: 0;
    right: 0;
}
.mistake {
    font-family: 'Druk Cyr';
    color: #1A1A1A;
    font-size: 340px;
    font-style: italic;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 13.6px;
    text-transform: uppercase;
}

.text_nf {
    display: flex;
    flex-direction: column;
}
.style_text_nf {
    color: #1A1A1A;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
}

.button_nf {
    width: 410px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #1A1A1A;
    background: #1A1A1A;
    text-decoration: none;
    color: #FFF;
    transition: background-color 0.4s ease 0s, color 0.4s ease 0s;
    margin-top: 40px;
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
}

.button_nf:hover {
    background-color: #ffffff;
    color: #1A1A1A;

}

header.header.scroll {
    background: transparent;
}

header.header.scroll:hover {
    background: #FFF;
}

header.header.scroll._scrolling {
    background: rgba(255, 255, 255, 0.35);
}

header.header.scroll._scrolling:hover {
    background: #FFFFFF;
}

@media (max-width: 1024px) {
    .mistake {
        font-size: 400px;
        letter-spacing: 16px;
    }
    .style_text_nf {
        font-size: 36px;
    }
    .button_nf {
        margin-top: 24px;
    }

}
@media (max-width: 768px) {
    .content_nf {
        padding: 0 20px 20px;
    }
    .mistake {
        font-size: 200px;
        letter-spacing: 8px;
    }
    .button_nf {
        height: 49px;
        font-size: 16px;
        width: 234px;
        margin-top: 16px;
    }
    .style_text_nf {
        font-size: 20px;
    }
}