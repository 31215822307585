.one_delivery_method_component {
    display: flex;
    width: 100%;
    min-height: 100px;
    border: 1px solid black;
    margin-top: 20px;
    flex-direction: column;
    padding: 20px;
    position: relative;
}

.one_delivery_top_line {
    display: flex;
    width: 100%;
    height: 40px;
    align-items: center;
}

.delivery_id_container_info {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
    font-size: 18px;
    font-weight: 600;
}
.area_or_city_dilivery {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    font-size: 18px;
    font-weight: 600;
}
.delivery_current_target_name {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    font-size: 18px;
    font-weight: 600;
}
.one_delivery_containers_for_content_wrapper {
    display: flex;
    flex-direction: column;
    max-width: 500px ;
    width: 100%;
}
.one_delivery_one_field_wrapper {
    display: flex;
    flex-direction: column;
}
.one_delivery_name_field_text {
    font-weight: 600;
    font-size: 16px;
    margin-top: 10px;
}

.one_delivery_standard_input {
    width: 100%;
    display: flex;
    height: 40px;
    border: 1px solid lightgrey;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    padding-left: 20px;
    margin-top: 10px;
    font-weight: 600;
}
.one_delivery_sell_input {
    max-width: 450px;
    display: flex;
    height: 40px;
    border: 1px solid lightgrey;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    padding-left: 20px;
    margin-top: 10px;
    font-weight: 600;
}
.one_delivery_sell_input.disactive {
    background-color: gray;
    pointer-events:none;
}
.sell_type {
    border: 1px solid #020202;
    padding: 7px;
    display: flex;
    max-width: 450px;
}
.delivery_save_controller {
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
    border: 1px solid black;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
}
.delete_button_one_delivery {
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
    border: 1px solid black;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
}
.delete_button_one_delivery:hover {
    color: white;
    background-color: black;
    transition: color 0.4s ease 0s, background-color 0.4s ease 0s;
}

.delivery_save_controller:hover {
    color: white;
    background-color: black;
    transition: color 0.4s ease 0s, background-color 0.4s ease 0s;
}

.delivery_save_controller.disactive {
    background-color: lightgrey;
    border-color: gray;
    color: gray;
    user-select: none;
    pointer-events: none;
}

.response_server_text_delivery {
    display: flex;
    height: 40px;
    font-weight: 600;
    align-items: center;
    margin-top: 20px;
    color: green;
    font-size: 18px;
}

.one_delivery_method_support_wrapper {
    display: flex;
    width: 100%;
}
.one_delivery_method_settings_wrapper {
    display: flex;
    height: 100%;
    max-width: 500px;
    margin-left: 40px;
    width: 100%;
    flex-direction: column;
}