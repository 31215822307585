
.oblozka_one_sity {
    width: 100%;
}
.s_button {
    cursor: pointer;
    white-space: nowrap;
    position: relative;
    /*border-bottom: 1px solid transparent;*/
    /*margin-top: 5px;*/
    display: flex;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
    color: #000000;
}
.s_button.active {
    font-weight: 700;
    text-decoration-line: unset;
}

.s_button.non_arrows {

}
.s_button.arrows {
    padding-right: 20px;
    font-weight: 600;
    position: relative;
}
.s_button.open:hover {
    text-decoration-line: underline;
    transition: text-decoration-line 0.4s ease 0s;
}
.s_button.arrows::after {
    content: "";
    height: 1px;
    width: 6px;
    background-color: #000000FF;
    position: absolute;
    right: 9px;
    top: 10px;
    transform: rotate(45deg);
}

.s_button.arrows::before {
    content: "";
    height: 1px;
    width: 6px;
    background-color: #000000FF;
    position: absolute;
    right: 5px;
    top: 10px;
    transform: rotate(-45deg);
}
@media (max-width: 767px) {

    /*.s_button {*/
    /*    font-size: 16px;*/
    /*}*/
    /*.s_button.open:hover {*/
    /*    border-bottom: 1px solid transparent;*/
    /*}*/

}

