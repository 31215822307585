.lookbook_home {
    width: 100%;
}
.name_block_look{
    font-weight: 500;
    font-size: 30px;
    display: flex;
    align-items: center;
    color: #000000;
    padding: 0px 40px;
    margin-top: 160px;
}
.name_block_look a{
    text-decoration: none;
    color: inherit;
}
.button_and_arrows {
    display: flex;
    justify-content: space-between;
    padding: 0px 40px;
    margin-top: 20px;
}
.arrows_look_block {
    display: flex;
    max-width: 60px;
    width: 100%;
    justify-content: space-between;
}
.one_arrows_look {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.go_all_lookbook {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border-bottom: 1px solid transparent;
    transition: border-bottom 0.2s ease 0s;
}
.go_all_lookbook:hover {
    border-bottom: 1px solid black;
    transition: border-bottom 0.4s ease 0s;
}
.button_go_lookbook {
    font-weight: 600;
    font-size: 16px;
    color: #1A1A1A;
    margin-right: 10px;
    min-height: 24px;
    text-decoration: none;
    display: flex;
    align-items: center;
}
.lookbook-slider {
    margin-top: 20px;
    display: flex;
    flex-direction: column-reverse;
}
.lookbook-slider .swiper-slide {
    width: max-content;
}
.lookbook-slider .swiper-scrollbar {
    margin-top: 25px;
    position: static;
    background: #cecece;
    height: 3px;
    width: auto;
    border-radius: 0;
}
.lookbook-slider .swiper-scrollbar-drag {
    background: rgb(25,25,25);
    border-radius: 0;
}
.lookbook_scroll {
    margin-top: 20px;
    display: flex;
    align-items: flex-start;
    overflow-x: scroll;
    padding-bottom: 25px;
    scroll-behavior: smooth;
}
.lookbook_scroll::-webkit-scrollbar {
    height: 3px;
}
.lookbook_scroll::-webkit-scrollbar-track {
    background: #cecece;
    border: 1px solid transparent;
    background-clip: content-box;
}
.lookbook_scroll::-webkit-scrollbar-thumb {
    background: rgb(25,25,25);
    border: 1px solid rgb(0, 0, 0);
}
.one_look_link {
    display: block;
    margin: 0px 20px;
    text-decoration: none;
    width: max-content;
}
.one_look_link:hover .name_look_home {
    border-bottom: 1px solid black;
    transition: border-bottom 0.4s ease 0s;
}
.wrap_components_look {
    display: flex;
    flex-direction: column;
    width: max-content;
}
.image_look {
    width: max-content;
}
.image_look img {
    width: 940px;
    object-fit: contain;
}
.name_look_home {
    width: max-content;
    font-weight: 600;
    font-size: 16px;
    color: #1A1A1A;
    margin-top: 12px;
    border-bottom: 1px solid transparent;
    transition: border-bottom 0.2s ease 0s;
}

@media (max-width: 1600px) {
    .image_look img {
        width: 780px;
    }
}
@media (max-width: 1400px) {
    .image_look img {
        width: 700px;
    }
}


@media (max-width: 1024px) {

    .name_block_look {
        font-size: 18px;
        margin-top: 60px;
    }
    .button_go_lookbook {
        font-size: 14px;
    }
    .image_look img {
        width: 500px;
    }
}


@media (max-width: 768px) {
    .lookbook_scroll {
        padding-bottom: 15px;
    }
    .lookbook-slider .swiper-scrollbar {
        margin-top: 15px;
    }
    .name_block_look {
        padding: 0px 20px;
        margin-top: 40px;
    }
    .button_and_arrows {
        padding: 0px 20px;
    }
    .image_look img {
        width: 360px;
    }
    .one_look_link {
        margin: 0px 10px;
    }
}