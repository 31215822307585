.contacts {
    height: 100%;
    width: 100%;
    /*display: flex;*/
    /*flex-direction: column;*/
    /*justify-content: center;*/
    /*align-items: center;*/
    margin: 0 auto;
    background: linear-gradient(160.43deg, #FAFAFC 24.85%, #FAFAFC 82.29%);
    transform: matrix(1, 0, 0, 1, 0, 0);
    position: relative;
    /*z-index: -1;*/
}
.block_height_banner {
    max-width: 1920px;
    padding: 0px 40px;
    width: 100%;
    position: relative;
    margin: 0 auto;
}
.contacts_banner {
    width: 100%;
    /*height: 635px;*/
    /*position: relative;*/
}
.c_background_img {
    max-width: 300px;
    width: 100%;
    height: auto;
    object-fit: contain;
    position: absolute;
    z-index: -1;
    bottom: 0;
    right: 40px;
}

.c_content_absolute {
    width: 100%;
    display: flex;
    /*position: absolute;*/
    /*top: 175px;*/
}
.c_content_static {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /*align-items: center;*/
}
.title_contacts {
    font-weight: 500;
    font-size: 30px;
    color: #000000;
}
.c_cards_content {
    /*max-width: 1263px;*/
    /*width: 100%;*/
    margin-top: 20px;
    display: flex;
    /*height: max-content;*/
    /*justify-content: center;*/
    flex-wrap: wrap;
}
.c_social_parants {
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    /*justify-content: flex-end;*/
    margin-top: 40px;
    padding-bottom: 40px;
}
.c_social {
    /*max-width: 579px;*/
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /*align-items: center;*/
    /*text-align: center;*/
}
.c_title_social {
    font-weight: 500;
    font-size: 24px;
    color: #000000;
}
.c_text_social {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
    margin-top: 18px;
}
.wrap_icons_content {
    display: flex;
    margin-top: 18px;
    flex-wrap: wrap;
}
/*.c_social_link{*/
/*    margin-left: 20px;*/
/*}*/
/*.c_social_link:first-child {*/
/*    margin-left: 0px;*/
/*}*/
.c_social_link svg {
    width: 35px;
    height: 35px;
}
.c_social_link:hover svg {
    /*box-shadow: 0px 0px 20px rgb(0 0 0 / 15%);*/
    /*border-radius: 10px;*/
    border: 2px solid black;
    transition: border 0.1s ease 0.2s;
    padding: 2px;
}
.c_social_link:hover rect{
    fill: white;
}
.c_social_link:hover path{
    fill: black;
}
.c_social_link:hover path.path_icon{
    fill: #ffffff;
}


.c_border {
    /*max-width: 919px;*/
    /*width: 100%;*/
    /*height: 1px;*/
    /*background-color: #E0E0E0;*/
    /*margin-top: 85px;*/
}
.c_requisites {
    max-width: 582px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    border: 1px solid black;
}
.c_title_requisites {
    font-weight: 500;
    font-size: 24px;
    color: #000000;
    padding: 18px 40px;
    border-bottom: 1px solid black;
    width: 100%;
}
.c_text_requisites {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 18px 40px;
}

.requisites_bold.transfer {
    white-space: pre-line;
}

.requisites_bold {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
}
.c_text_paragraph {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
    margin-top: 30px;
}

.c_text_paragraph.transfer {
    white-space: pre-line;
    margin-top: 0px;
}
.c_without_indentation {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}
.bottom_container_container {
    background-color: #fff;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    height: auto;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.c_social_icon {
    margin-right: 40px;
}
@media (max-width: 1366px) {
    .c_background_img {
        max-width: 250px;
    }
}

@media (max-width: 1024px)  {
    .contacts {
        height: auto;
    }
    .title_contacts {
        font-size: 24px;
    }
    .c_cards_content {
        margin-top: 10px;
    }
    .c_social_link svg {
        width: 28px;
        height: 28px;
    }
    .c_background_img {
        max-width:220px;
        right: 0;
    }

}

@media (max-width: 768px) {
    .block_height_banner {
        padding: 0px 20px;
    }
    .title_contacts {
        font-size: 18px;
    }
    .c_cards_content {
        margin-top: 0px;
    }
    .c_title_social {
        font-size: 18px;
    }
    .c_text_social {
        margin-top: 10px;
        line-height: 23px;
    }
    .wrap_icons_content {
        margin-top: 20px;
    }
    .c_social_icon {
        margin-right: 20px;
    }
    .c_title_requisites {
        padding: 20px;
        font-size: 18px;
    }
    .c_text_requisites {
        padding: 20px;
    }
    .requisites_bold {
        font-size: 14px;
    }
    .c_text_paragraph {
        margin-top: 20px;
        font-size: 14px;
    }
    .c_without_indentation {
        margin-top: 20px;
    }
}

@media (max-width: 428px) {
    .c_social_icon {
        margin-right: 10px;
    }
}