
.confirm_wrapper_and_err {
    display: flex;
    flex-direction: column;
}
.confirm_err_text {
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: flex-start;
    color: #CE3F3F;
    font-size: 11px;
    /*text-transform: uppercase;*/
    font-weight: 600;
    letter-spacing: 0.03em;
    margin-top: 5px;
}


.confirm_use_info {
    display: flex;
    cursor: pointer;
    margin-top: 40px;
}

.user_confirm_text {
    max-width: 534px;
    /*display: flex;*/

    width: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #757575;
    margin-left: 16px;
}
.user_confirm_text a {
    text-decoration: none;
    color: #757575;
}
.user_confirm_text a:hover {
    text-decoration: none;
    color: #030000;
    transition: color 0.2s ease 0s;
}
.confirm_selector {
    width: 24px;
    height: 24px;
    /*border-radius: 50%;*/
    border: 1px solid #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.child_confirm_selector {
    width: 24px;
    height: 24px;
    background: #1A1A1A;
    /*border-radius: 50%;*/
    opacity: 0;
    transition: opacity 0.4s ease 0s;
    position: relative;
}
.child_confirm_selector:before {
    position: absolute;
    content: "";
    width: 6px;
    height: 2px;
    background: #ffffff;
    transform: rotate(45deg);
    top: 12px;
    left: 6px;
}
.child_confirm_selector:after {
    position: absolute;
    content: "";
    width: 9px;
    height: 2px;
    background: #ffffff;
    transform: rotate(-45deg);
    top: 11px;
    left: 9px;
}
.confirm_use_info.active .child_confirm_selector {
    opacity: 1;
    transition: opacity 0.4s ease 0s;
    
    
}