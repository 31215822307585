
.breadcrumbs_content {
    /*position: absolute;*/
    /*left: 0px;*/
    padding-left: 40px;
    color: rgba(0, 0, 0, 0.8);
    display: flex;
    height: 60px;
    align-items: center;
    width: 100%;
}

.breadcrumbs_item_content {
    display: flex;
    padding-right: 25px;
    position: relative;
    cursor: pointer;
}
.breadcrumbs_item_content a {

    color: rgba(0, 0, 0, 0.8);
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-decoration-line: underline;
}
.breadcrumbs_item_content:last-child a {
    text-decoration: none;
}

.breadcrumbs_item_content:first-child {
    margin-left: 0px;
}

/*.breadcrumbs_item_content:before {*/
/*    content: "";*/
/*    height: 1px;*/
/*    width: 7px;*/
/*    background-color: #FFFFFF;*/
/*    opacity: 0.5;*/
/*    position: absolute;*/
/*    right: 15px;*/
/*    top: 10px;*/
/*    transform: rotate(-45deg);*/
/*}*/
.breadcrumbs_item_content:after {
    content: "";
    height: 1px;
    width: 11px;
    background-color:rgba(108, 117, 125, 1);
    opacity: 0.5;
    position: absolute;
    right: 9px;
    top: 6px;
    transform: rotate(-70deg);
}
.breadcrumbs_item_content:last-child:after{
    display: none;
}
.breadcrumbs_item_content:last-child:before{
    display: none;
}


@media (max-width: 768px) {
    .breadcrumbs_content {
        padding-left: 20px;
    }
    .breadcrumbs_item_content {
        padding-right: 10px;
    }
    .breadcrumbs_item_content:after {
        right: 0px;
    }
}
