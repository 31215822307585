footer {
    min-height: 350px;
    width: 100%;
    /*margin-top: 100px;*/
    border-top: 1px solid #CFCFCF;
    padding: 60px 30px 30px 30px;
}

footer.hiddenFooter {
    display: none;
}

.first_line_footer {
    display: flex;
    justify-content: space-between;
}

.payment_and_social {
    display: flex;
    max-width: 620px;
    width: 100%;
    justify-content: space-between;
    /*margin-right: 40px;*/
}

.two_line_footer {

}

.with_suport {
    max-width: 200px;
    margin: 20px 0 0 60px;
}

.with_suport a {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #1A1A1A;
    text-decoration: none;
}

.fond_icon {
    display: block;
    margin-top: 12px;
    width: 61px;
    height: 35px;
}

.payment_block {

}

@media (max-width: 1500px) {
    .with_suport {
        margin-left: 0px;
    }
}


@media (max-width: 1400px) {
    /*.payment_and_social {*/
    /*    flex-direction: column;*/
    /*    width: auto;*/
    /*    margin-right: 0px;*/
    /*}*/
    /* .payment_and_social { */
    /* flex-direction: column; */
    /* max-width: max-content; */
    /* } */

}

@media (max-width: 1024px) {
    .first_line_footer {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        max-width: 780px;
        margin: 0 auto;
    }

    .with_suport {
        margin-top: 40px;
    }

    .first_line_footer .logo_conteiner {
        grid-column: span 3;
    }

    .midle_bar_wrapper {
        grid-column: span 2;
    }

    .payment_and_social {
        /*flex-direction: row;*/
        /*margin-top: 50px;*/
        /*max-width: 780px;*/
        /*width: 100%;*/
        /*margin-left: 0px;*/
        display: contents;
    }

    .payment_block {
        margin-top: 40px;
    }

    .footer_contact_us {
        margin-top: 40px;
        grid-column: span 2;

    }

    .footer .payment_images_wrapper {
        max-width: 100%;
    }
}

@media (max-width: 767px) {
    .footer {
        padding: 35px 15px 30px 15px;
    }

    .footer .social_link_us {
        margin-right: 20px;
    }

    .first_line_footer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .payment_and_social {
        flex-direction: column;
        margin-top: 40px;
        align-items: center;
    }

    .with_suport {

        max-width: 275px;
    }

    .footer .payment_methods {
        align-items: flex-start;
    }


}

@media (max-width: 428px) {
    .first_line_footer {
        display: flex;
        align-items: flex-start;
    }

    .payment_and_social {
        align-items: flex-start;
        text-align: start;
    }
}

@media (max-width: 400px) {
    .footer {
        padding: 30px 15px 25px 15px;
    }

    .payment_and_social {
        margin-top: 35px;
    }

}

