.VacanciesModal {
    position: fixed;
    top: 50%;
    z-index: -1;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.0s ease 0s;
    left: -100%;
    max-width: 546px;
    min-height: 290px;
    width: 100%;
    /*padding: 30px;*/
    border: 2px solid #000000;
    /*box-shadow: 0px 0px 20px rgb(0 0 0 / 30%);*/
    background: white;
}
.VacanciesModal.active {
    z-index: 15;
    opacity: 1;
    transition: opacity 0.6s ease 0.4s;
    left: 50%;
}
.vacancies_modal_close_and_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 18px 18px 40px;
    border-bottom: 2px solid #000000;
}
.vacancies_modal_close {
    width: 24px;
    height: 24px;
    cursor: pointer;
    position: relative;
}
.vacancies_modal_close::before{
    content: " ";
    position: absolute;
    width: 24px;
    height: 2px;
    transform: rotate(-45deg);
    background-color: #000000;
    top: 11px;
    right: 0;
    border-radius: 10px;
}
.vacancies_modal_close::after{
    content: " ";
    position: absolute;
    width: 24px;
    height: 2px;
    transform: rotate(45deg);
    background-color: #000000;
    top: 11px;
    right: 0;
    border-radius: 10px;
}


.vacancies_cards_modal {
    max-width: 600px;
    min-height: 332px;
    width: 100%;
    height: 100%;
    padding: 30px;
    border: 1px solid #E0E0E0;
    box-shadow: 0px 0px 20px rgb(0 0 0 / 15%);
    background: white;
    margin: 10px;
}
.vacancies_cards_subtitle_modal {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
}
.employment_wage_wrapper_modal {
    padding: 28px 40px;
}
.employment_wage_modal {
    display: flex;
}
.employment_wage_container_modal {
    margin-left: 18px;
}
.employment_wage_container_modal:first-child {
    margin-left: 0px;
}

.employment_wage_subtitle_modal {
    font-weight: 400;
    font-size: 14px;
    color: #9A9A9A;
    margin-top: 18px;
}
.employment_wage_subtitle_modal:first-child {
    margin-top: 0px;
}
.employment_wage_text_modal {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #1A1A1A;
    margin-top: 18px;
}
.employment_wage_text_modal:first-child {
    margin-top: 0px;
}

.vacancies_button_modal {
    margin-top: 35px;
}

.button_response_vacancies_modal {
    padding: 14px;
    border: 1px solid #1A1A1A;
    max-width: 402px;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #1A1A1A;
    cursor: pointer;
    transition: background 0.4s ease 0s, color 0.4s ease 0s;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}
.button_response_vacancies_modal:hover {
    background: #1A1A1A;
    color: white;
    transition: background 0.4s ease 0s, color 0.4s ease 0s;
}

@media (max-width: 1024px) {

}

@media (max-width: 768px) {
    .VacanciesModal {
        width: 95%;
    }
    .vacancies_modal_close_and_title {
        padding: 18px 20px;
    }
    .employment_wage_wrapper_modal {
        padding: 20px;
    }
    .employment_wage_modal {
        flex-direction: column;
    }
    .employment_wage_container_modal {
        margin-top: 10px;
        margin-left: 0;
    }
    .employment_wage_container_modal:first-child {
        margin-top: 0px;
    }
    .employment_wage_text_modal {
        margin-top: 10px;
    }
    .vacancies_button_modal {
        margin-top: 20px;
    }
    .employment_wage_subtitle_modal {
        font-size: 12px;
    }
    .button_response_vacancies_modal {
        font-size: 12px;
        line-height: 16px;
    }

}

