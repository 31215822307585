.state_city_mob {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    cursor: pointer;
    transition: opacity 0.4s ease 0s, font-weight 0.2s ease 0s;
    padding: 1px 15px;
}
.state_city_mob.active {
    opacity: 1;
    font-weight: 600;
    transition: opacity 0.4s ease 0s, font-weight 0.4s ease 0s;
}


@media (max-width: 768px) {
    .state_city_mob {
        font-size: 14px;
    }
}
