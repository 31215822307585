.main_settings_header_and_footer {
    padding: 30px;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
}
.content_container_slayer {
    background-color: #fff;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    width: 100%;
    padding: 10px;
    display: flex;
    min-height: 200px;
    padding: 20px;
    flex-direction: column;
    margin-top: 30px;
}
.work_area_settings_menu_clear {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
}
.one_category_btn {
    display: flex;
    width: 150px;
    height: 45px;
    align-items: center;
    justify-content: center;
    color: black;
    font-weight: 600;
    font-size: 11px;
    text-transform: uppercase;
    border: 1px solid black;
    margin:5px;
    position: relative;
    cursor: grab;
    justify-content: center;
    text-align: center;
}
.one_category_btn:hover {
    background-color: rgba(194, 194, 194, 0.25);
}

.one_category_btn.only_parrent:after {
    position: absolute;
    content: "PARRENT MENU ONLY";
    font-weight: 600;
    color: #ff007b;
    font-size: 7px;
    top: 2px;
    right: 3px;
}

.for_drag_drop_menu_settings {
    display: flex;
    flex-wrap: wrap;

    width: 100%;
    min-height: 100px;
    background-color: rgba(241, 241, 241, 0.41);
    margin-top: 40px;
    align-items: center;
    padding: 0px 10px;
}

.one_item_header_top_menu {
    display: flex;
    padding: 0px 0px;
    border: 1px solid gray;
    margin: 5px;
    cursor: pointer;
    flex-direction: column;
    position: relative;
    user-select: none;

}
.one_item_header_top_menu.active {
    border-color: green;
    background-color: #badeba;
}
.one_item_header_top_menu:hover {
    background-color: lightgrey;
}
.delete_top_menu_level {
    display: flex;
    width: 100%;
    background-color: rgba(230, 145, 145, 0.18);
    font-size: 12px;
    padding: 3px 3px;
    text-align: center;
    justify-content: center;
    font-weight: 600;
    position: absolute;
    top: -30px;
}
.delete_top_menu_level:hover {
    background-color: indianred;
}

.name_top_menu_level_item {
    height: 45px;
    width: 100%;
    padding: 10px 10px;
    display: flex;
    font-weight: 600;

}
.detail_area_menu {
    background-color: white;
    padding: 30px;
    display: flex;
    width: 100%;
    min-height: 300px;
    margin-top: 40px;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    flex-direction: column;
}

.add_new_column {
    height: 70px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: lightgrey;
}
.columns_wrapper_menu {
    display: flex;
    margin-top: 40px;
    min-height: 200px;
}
.area_menu_one_column {
    display: flex;
    flex-direction: column;
    margin: 0px 10px;
    width: 170px;
    height: 100%;
    padding: 10px;
    padding-top:20px;
    font-weight: 600;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    position: relative;
}
.delbutton_column {
    cursor: pointer;
    position: absolute;
    top: 0px;
    right: 10px;
    background-color: rgba(255, 0, 0, 0.25);
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
}
.delbutton_column:hover {
    background-color: black;
    color:white;
}

.support_header_menu_page {
    margin-top: 40px;
    display: flex;
    font-weight: 600;
}
.last_child_item_menu {
    display: flex;
    margin-left: 10px;
    padding-top: 20px;
    font-weight: 500;
    position: relative;
}
.save_btn_aft {
    margin-top: 20px;
    height: 40px;
    width: 140px;
    border: 1px solid gray;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    font-weight: 600;
    cursor: pointer;
}
.save_btn_aft:hover {
    background-color: black;
    color: white;
}

.save_btn_aft.trig {
    animation: trigerSaveFooter 2s ease infinite;
}

@keyframes trigerSaveFooter{
    0% {
        background: white;
        color: black;
    }
    50% {
        background: black;
        color: white;
    }
    100% {
        background: white;
        color: black;
    }

}
.save_footer_content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.del_button_one_item {
    position: absolute;
    right: 1px;
    top: 10px;
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    cursor: pointer;
    background-color: #E69191;
}


.del_button_one_item:hover {
    background-color: black;
    color: white;
}

.container_foooter_column {

}
.support_footer {
    display: flex;
    font-weight: 600;
    padding: 10px 0px;
    font-size: 20px;
}
.add_new_column_footer {
    display: flex;
    padding: 5px 10px;
    border: 1px solid black;
    border-radius: 5px;
    max-width: 200px;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
    cursor: pointer;
    transition: color 0.4s ease 0s, background 0.4s ease 0s;
    margin-top: 10px;
}
.add_new_column_footer.non_active {
    border: 1px solid #757575;
    color: #757575;
    transition: border 0.4s ease 0s, color 0.4s ease 0s;
    -webkit-user-select: none;
    user-select: none;
    pointer-events: none;
}
.add_new_column_footer:hover {
    background-color: black;
    color: white;
    transition: all 0.4s ease 0s;
}
.constructor_menu_footer {
    display: flex;
}
.one_column_footer {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    border: 2px solid #c9cccf;
    padding: 6px;
    border-radius: 10px;
    margin-top: 20px;
}
.one_column_footer:last-child {
    margin-right: 0px;
}
.del_one_column {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}
.del_column_child {
    width: 20px;
    height: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.del_column_child:hover {
    color: #CE3F3F;
    transition: color 0.4s ease 0s;
}
.one_input_field {
    display: flex;
    flex-direction: column;
    margin-right: 5px;
    width: 100%;
}
.one_input_field_content {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    width: 100%;
}
.name_input_footer {
    font-weight: 600;
    font-size: 12px;
}
.field_footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #c9cccf;
    font-weight: 600;
    font-size: 12px;
    padding: 5px 5px;
    border-radius: 5px;
    margin-top: 5px;
}
.wrapper_new_field {
    display: flex;
    justify-content: space-between;
}
.add_new_line_footer {
    margin-top: 10px;
    font-weight: 600;
    font-size: 12px;
    padding: 5px 10px;
    border: 1px solid black;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    margin-right: 10px;
}
.add_new_line_footer:hover {
    background-color: black;
    color: white;
    transition: all 0.4s ease 0s;
}
.one_field_footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
}
.choosing_link {
    border: 1px solid #c9cccf;
    padding: 1px;
    display: flex;
    height: 27px;
    margin-top: 5px;
    border-radius: 5px;
    max-width: 160px;
}
.other_content_footer{
    margin-top: 20px;
}

.wrapper_social_footer {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.subtitle_social_footer {
    margin-top: 10px;
    margin-left: 30px;
    font-size: 14px;
    font-weight: 600;
}
.subtitle_block_footer {
    font-weight: 600;
    padding: 10px 0px;
    font-size: 18px;
}
.choice_social_footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-left: 30px;
    box-shadow: 0 0 5px rgb(23 24 24 / 15%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    margin-top: 15px;
}

.feedback_footer_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}
.wrap_feedback_footer {
    display: flex;
}
.custom_fields_feedback {
    width: 100%;
    margin-left: 30px;
}
.wrapper_dop_field_contacts {
    display: flex;
    box-shadow: 0 0 5px rgb(23 24 24 / 15%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    margin-top: 15px;
    padding: 10px;
}
.dop_field_footer {
    width: 100%;
}
.pay_footer_wrap {
    display: flex;
    flex-wrap: wrap;
}
.one_pay_footer {
    margin: 10px;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
}
.one_pay_footer .preview_container_img {
    width: 250px;
}
.pay_field_wrap {
    height: 250px;
    display: flex;
}
.logo_and_othen_text {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}
.logo_footer_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.logo_img_footer {
    position: relative;
    display: flex;
    width: 300px;
    height: 250px;
    background-color: #fff;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    padding: 10px;
}
.text_footer_wrapper {
    width: 100%;
}
.footer_copywriting {
    /*width: 100%;*/
}
.footer_link_politic {
    /*width: 100%;*/
}
.text_link_politic {
    display: flex;
    flex-direction: column;
}








.running_line_div_controller {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 400px;
}

.btn_controll_run_line {
    display: flex;
    height: 40px;
    width: 150px;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    color: black;
    border: 1px solid black;
    margin-top: 20px;
    cursor: pointer;
}

.btn_controll_run_line.active {
    background-color: black;
    color: white;
}
.run_line_span {
    font-weight: 600;
    font-size: 13px;
    margin-top: 20px;
    display: flex;
}

.runline_text_input {
    display: flex;
    width: 300px;
    height: 40px;
    padding: 5px;
    color: black;
    border: 1px solid black;
    margin-top: 20px;
}

.save_runline_btn {
    display: flex;
    padding: 10px;
    font-weight: 600;
    cursor: pointer;
    width: 300px;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
}
.save_runline_btn:hover {
    background-color: black;
    color: white;
}
.save_runline_notificator {
    display: flex;
    height: 30px;
    font-weight: 600;
    margin-top: 10px;
}
