.delivery_manage_wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 20px;
    flex-direction: column;
}

.delivery_manager_content {
    background-color: #fff;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    display: flex;
    width: 100%;
    height: 100%;
    margin-top: 20px;
    padding: 10px;
    flex-direction: column;
}
.delivery_manager_info_content {
    display: flex;
    width: 100%;
    margin-top: 20px;
    font-size: 16px;
    color: #2e2d2d;
    font-weight: 500;
    flex-direction: column;
    padding: 30px;
}

.delivery_manager_info_content p {
    font-size: 16px;
    color: #2e2d2d;
    font-weight: 500;
}
.delivery_wrapper_for_input {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.add_new_delivery_block {
    display: flex;
    width: 100%;
    margin-top: 30px;
    padding: 30px;
    border: 1px solid lightgrey;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    flex-direction: column;
}

.standard_delivery_one_field {
    width: 100%;
    display: flex;
    height: 40px;
    border: 1px solid lightgrey;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    padding-left: 20px;
    margin-top: 10px;
    font-weight: 600;
}
.delivery_input_title {
    font-weight: 600;
    font-size: 14px;
}
.delivery_targets_wrapper {
    display: flex;
    margin-top: 10px;
    flex-wrap: wrap;
}
.target_delivery_changer {
    display: flex;
    margin-left: 25px;
    text-transform: uppercase;
    justify-content: center;
    align-items: center;
    padding: 10px 30px;
    border: 1px solid black;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
}
.target_delivery_changer:first-child {
    margin-left: 0px;
}
.target_delivery_changer:hover {
    background-color: black;
    color: white;
    transition: background-color 0.4s ease 0s, color 0.4s ease 0s;
}
.target_delivery_changer.active {
    background-color: black;
    color: white;
}
.delivery_search_wrapper {
    display: flex;
    margin-left: 50px;
    flex-direction: column;
    width: 400px;
}
.delivery_search_response_container {
    display: flex;
    width: 100%;
    height: 100%;
    max-height: 400px;
    border: 1px solid lightgrey;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    margin-top: 20px;
    flex-direction: column;
    overflow-y: scroll;
}

.delivery_target_value_style {
    display: flex;
    margin-top: 25px;
    font-weight: 600;
    font-size: 15px;
}
.pre_result_responser {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 600;
}
.delivery_search_responser_one_item {
    display: flex;
    padding: 10px 5px;
    margin-top: 3px;
    width: 100%;
    font-weight: 600;
    cursor: pointer;
}
.delivery_search_responser_one_item:hover {
    background-color: rgba(211, 211, 211, 0.34);
}

.delivery_target_value_style.ok_target {
    position: relative;
}

.delivery_target_value_style.ok_target:after {
    position: absolute;
    content: "";
    width: 2px;
    height: 15px;
    right: 15px;
    top: 5px;
    background-color: green;
    transform: rotate(45deg);
}
.delivery_target_value_style.ok_target:before {
    position: absolute;
    content: "";
    width: 2px;
    height: 8px;
    right: 23px;
    top: 10px;
    background-color: green;
    transform: rotate(-45deg);
}

.delivery_adm_page_heading {
    display: flex;
    width: 100%;
    margin-top: 60px;
    font-size: 20px;
    font-weight: 600;
    padding: 0px 30px;
}



.add_one_product_last_container {
    display: flex;
    flex-grow: 1;
    margin-left: 30px;
    height: 100%;
    /*background-color: #E69191;*/
    flex-direction: column;
    max-width: 400px;
    padding: 0px 20px;
}
.add_new_delivery_button {
    display: flex;
    text-transform: uppercase;
    justify-content: center;
    align-items: center;
    padding: 10px 30px;
    border: 1px solid black;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    margin-top: 47px;
}

.add_new_delivery_button:hover {
    background-color: black;
    color: white;
    transition: background-color 0.4s ease,color 0.4s ease 0s;

}

.add_new_delivery_button.disactive {
    background-color: lightgrey;
    color: gray;
    border-color: gray;
    pointer-events: none;
    user-select: none;
}
.add_new_delivery_response_server {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    font-size: 12px;
    font-weight: 600;
    flex-direction: column;
}
.add_new_delivery_response_server span {
    margin-top: 10px;
}











.all_delivery_methods_wrapper {
    display: flex;
    width: 100%;
    height: auto;
    padding: 30px;
    /*background-color: pink;*/
    margin-top: 60px;
    flex-direction: column;
}
.all_delivery_list_head {
    height: 80px;
    display: flex;
    width: 100%;
    margin-top: 20px;
    font-size: 24px;
    font-weight: 600;
    padding: 0px 10px;
    align-items: center;
}
.add_new_delivery_standard_controll {
    display: flex;
    width: 100%;
    height: 100%;
}

.add_new_delivery_integration {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;

}

.add_new_delivery_integration_heading {
    display: flex;
    width: 100%;
    margin-top: 40px;
    font-weight: 600;
}
.active_delivery_integrations {
    display: flex;
    width: 100%;
    margin-top: 30px;
    align-items: center;
}

.active_delivery_integrations_check {
    display: flex;
    width: 50px;
    min-width: 50px;
    height: 50px;
    border: 1px solid black;
    cursor: pointer;
    position: relative;
}
.active_delivery_integrations_check.active:before {
    content: "";
    width: 16px;
    height: 2px;
    left: 8px;
    top: 27px;
    border-radius: 5px;
    position: absolute;
    display: flex;
    transform: rotate(45deg);
    background-color: black;
}
.active_delivery_integrations_check.active:after {
    content: "";
    width: 25px;
    height: 2px;
    left: 17px;
    top: 23px;
    position: absolute;
    border-radius: 5px;
    display: flex;
    transform: rotate(-45deg);
    background-color: black;
}

.active_delivery_integrations_info {
    font-weight: 600;
    display: flex;
    margin-left: 30px;
}

.delivery_itegration_list {
    display: flex;
    margin-top: 40px;
}

.delivery_map_controller_check {
    display: flex;
    margin-top: 20px;
    align-items: center;
}
.delivery_one_method_board {
    display: flex;
    margin-left: 30px;
    padding: 20px 30px;
    border: 1px solid black;
    cursor: pointer;
    font-weight: 600;
}
.delivery_one_method_board.active {
    background-color: black;
    color: white;
}
.delivery_one_method_board:hover {
    background-color: black;
    color: white;
}
.delivery_one_method_board:first-child {
    margin-left: 0px;
}

.delivery_payment_methods_list {
    display: flex;
    flex-direction: column;
}
.delivery_payment_methods_list_heading {
    display: flex;
    font-weight: 600;
    font-size: 15px;
    margin-top: 40px;
}
.no_payment_methods {
    display: flex;
    font-weight: 600;
    font-size: 15px;
    margin-top: 40px;
    color: #CE3F3F;
}
.payment_methods_front_wrap {
    display: flex;
}
.one_pay_method_button {
    display: flex;
    padding: 10px 30px;
    margin-left: 30px;
    margin-top: 40px;
    border: 1px solid black;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 16px;
    cursor: pointer;
}
.one_pay_method_button:first-child {
    margin-left: 0px;
}

.one_pay_method_button:hover {
    background-color: black;
    color: white;
}
.one_pay_method_button.active {
    background-color: black;
    color: white;
}