.registration_or_not_wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}
.question_in_basket_wrapper {
    max-width: 481px;
    width: 100%;
    min-height: 340px;
    padding: 10px;
    display: flex;
    flex-direction: column;
}
.question_in_basket_heading {
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    color: #1A1A1A;
}
.question_in_basket_agitation {
    display: flex;
    margin-top: 32px;
    max-width: 383px;
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
}

.question_in_basket_button_registration_or_not {
    text-transform: uppercase;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 72px;
    height: 50px;
    background: #1A1A1A;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
    border: 1px solid transparent;
}

/*.question_in_basket_button_registration_or_not:hover {*/
/*    transform: scale(1.01);*/
/*}*/


.dont_want_registration {
    display: flex;
    padding: 5px 10px;
    width: auto;
    margin-top: 20px;
    color: black;
    cursor: pointer;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #000000;
    justify-content: center;

}


@media (max-width: 768px) {
    .question_in_basket_heading {
        font-size: 24px;
        line-height: 24px;
    }
    .question_in_basket_agitation {
        margin-top: 20px;
        font-size: 14px;
    }
    .question_in_basket_button_registration_or_not {
        margin-top: 30px;
    }
}