.adm_about_page {
    padding: 20px;
}
.about_title {
    display: flex;
    width: 100%;
    height: 76px;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.03em;
}
.seo_adm_about {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    padding: 10px;
}
.field_about_wrap {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    width: 100%;
    padding: 5px;
}
.name_field_about {
    font-weight: 600;
    font-size: 12px;
}
.input_field_about {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #c9cccf;
    font-weight: 600;
    font-size: 12px;
    padding: 5px 5px;
    border-radius: 5px;
    margin-top: 5px;
}
.input_field_about.textarea {
    resize: none;
    height: 150px;
}
.view_adm_about {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    padding: 10px;
    margin-top: 20px;
}

.choosing_view{
    display: flex;
    justify-content: space-between;
}
.one_view {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.img_view_block {
    max-width: 250px;
    width: 100%;
}
.img_view_block img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.button_view_block {
    display: flex;
    padding: 5px 10px;
    border: 1px solid black;
    border-radius: 5px;
    width: 150px;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
    cursor: pointer;
    transition: color 0.4s ease 0s, background 0.4s ease 0s;
    margin-top: 18px;
}

.one_block_view {

}
.del_one_view {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.delete_view {
    width: 24px;
    height: 24px;
    cursor: pointer;
    position: relative;
}
.delete_view::before {
    content: " ";
    position: absolute;
    width: 24px;
    height: 4px;
    transform: rotate(-45deg);
    background-color: #000000;
    top: 12px;
    right: 0;
}
.delete_view::after {
    content: " ";
    position: absolute;
    width: 24px;
    height: 4px;
    transform: rotate(45deg);
    background-color: #000000;
    top: 12px;
    right: 0;
}
.warning_text {
    font-weight: 600;
    font-size: 18px;
    width: 100%;
    text-align: center;
    color: #CE3F3F;
    margin-top: 10px;
}
.name_about_block  {
    font-weight: 600;
    font-size: 22px;
    width: 100%;
    text-align: center;
    color: #000000;
    margin-top: 10px;
}
.block_nesting {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    padding: 10px;
    margin-top: 20px;
}
.nested_fields {
    display: flex;
    margin-top: 20px;
}
.img_about_wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
}
.one_img_about {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 10px;
    width: 100%;
    min-height: 250px;
}

.container_fields {
    display: flex;
    flex-direction: column;
    width: 100%;
}


.save_button_about {
    display: flex;
    height: 40px;
    width: 500px;
    margin: 0 auto;
    margin-top: 20px;
    background: white;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    font-weight: 600;
    cursor: pointer;
}
.save_button_about:hover {
    box-shadow: 0px 0px 4px 0px rgb(34 60 80 / 80%);
}

.save_button_about.trig {
    animation: trigerSaveAbout 2s ease infinite;
}

@keyframes trigerSaveAbout{
    0% {
        background: white;
        color: black;
    }
    50% {
        background: black;
        color: white;
    }
    100% {
        background: white;
        color: black;
    }

}