.add_one_product {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.pagename_mby_controll {
    height: 70px;
    padding: 15px 20px;
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: 20px;
}
.page_name_add_product {
    font-size: 20px;
    padding: 10px 20px;
    letter-spacing: 0.03em;
    font-weight: 600;
}
.add_product_space_wrap {
    height: 100%;
    padding: 18px;
    min-width: 954px;
    width: max-content;
    margin: 0 auto;
}

.add_product_space_container {
    border-radius: 5px;
    display: flex;
    min-width: 916px;
    width: max-content;
    height: 100%;
    margin: 0 auto;
}

.trend_one_item_in_all {
    margin-left: 20px;
    max-width: 100px;
    min-height: 20px;
    padding: 5px;
    border: 1px solid lightgray;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
}
.trend_one_item_in_all:hover {
    background-color: black;
    color: white;
    cursor: pointer;
}

.trend_one_item_in_all.active {
    background-color: black;
    color: white;
    cursor: pointer;
}

.right_bar_product_add {
    display: flex;
    flex-direction: column;
    width: 400px;
    height: 100%;
    margin-left: 30px;
    background-color: #fff;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    padding: 20px;
}


.main_add_product_work_area {
    height: 100%;
    display: flex;
    min-width: 916px;
    width: max-content;
    flex-direction: column;
    padding: 15px 15px;

}
.child_products_heading {
    margin-top: 40px;
    color: black;
    font-size: 16px;
    font-weight: 600;
    display: flex;
}
.fields_text_aft {
    font-weight: 500;
    font-size: 12px;
    color: black;
}


.wrap_for_row_field {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.wrap_for_row_field--sliders {
    display: flex;
    justify-content: space-between;
}

.tabs_product_container {
    display: flex;
    align-items: center;
    margin-top: 40px;
}


.add_new_collor_product {
    width: 28px;
    height: 28px;
    border-radius: 25%;
    background-color: #BCBCBC69;
    margin-left: 30px;
    cursor: pointer;
    position: relative;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    display: flex;
    font-weight: 500;
}

.add_new_collor_product:hover {
    background-color: #BCBCBC;
    transition: background-color 0.4s ease 0s;
}

.pages_child_product_area {
    margin-top: 20px;
    display: flex;
    width: 100%;

}
.product_information {
    margin-top: 35px;
    background-color: #fff;
    box-shadow: 0 0 5px rgba(23, 24, 24, 0.05), 0 1px 2px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 20px;
}
.admin_inputs_wrapper {
    display: flex;
    flex-direction: column;

}
.field_info {
    font-size: 14px;
    font-weight: 500;
    margin: 5px 0px;
    color: black;

}
.admin_field_styles {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #c9cccf;
    font-weight: 600;
    max-width: 100%;
    width: 100%;
    font-size: 12px;
    padding: 10px 5px;
    border-radius: 5px;
}

.admin_field_styles--short {
    max-width:300px;
}

.admin_field_styles.disabled{
    background-color: #BCBCBC;
    opacity: 1;
}

.admin_field_styles::placeholder {
    font-weight: 600;
}
.customEditor {
    padding: 6px 5px 0;
}
.textarea_wrapper_f_vysev {
    max-width: 100%;
    display: flex;
    width: 100%;
    height: 200px;
    border-radius: 5px;
    border: 1px solid #c9cccf;
    margin: 40px 0px;
}
.textarea_wrapper_f_vysev textarea {
    display: flex;
    width: 100%!important;
    height: 100%!important;
    padding: 20px;
    resize: none;
    font-size: 16px;
    font-weight: 500;
    white-space: pre-line;

}
.mtgn_title_field {
    display: flex;
    margin: 15px 0px;
    font-size: 14px;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    color: black;
}
.save_wrapper_info {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 40px;
}
.save_btn {
    display: flex;
    width: 200px;
    height: 40px;
    /*background-color: #88b579;*/
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 0.04em;
    border-radius: 5px;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
}
.save_btn:hover {
    background-color: #69b64d;
    transition: background-color 0.4s ease 0s;
}

.obmer_wrapper {
    display: flex;
    width: 100%;
    min-height: 200px;
    margin-top: 20px;
    flex-direction: column;
}
.obmer_title {
    display: flex;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 500;
    width: 100%;
    justify-content: center;
}
.size_model_field {
    margin: 15px 0px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    color: black;
}
.add_name_obmer {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    height: 30px;
    cursor: pointer;
    background-color: rgba(128, 128, 128, 0.10);
    border: 1px solid rgba(128, 128, 128, 0.32);
    margin-top: 10px;
}
.add_name_obmer:hover {
    background-color: rgba(128, 128, 128, 0.32);
}
.add_new_obmer {
    display: flex;
    width: 100%;
    height: 52px;
    border-radius: 5px;
    border: 1px solid #c9cccf;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 25px;
    font-weight: 600;
}
.add_new_obmer:hover {
    background-color: #f4f4f4;
    transition: background-color 0.4s ease 0s;
}

.support_info_add_one {
    font-size: 12px;
    letter-spacing: 0.03em;
    font-weight: 500;
    margin-top: 15px;
    margin-bottom: 15px;
}
.red_sup {
    color: #CE3F3F;
    font-weight: 600;
    font-size: 18px;
}

.save_informer_widget {
    position: fixed;
    top: 50%;
    right: -150%;
    transition: right 1s ease 0s;
    min-height: 100px;
    width: 300px;
    background-color: white;
    display: flex;
    flex-direction: column;
    border: 3px solid #ef0654;
    border-radius: 10px;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    padding:30px;
    transform:translate(0%,-50%);
    z-index: 30;
}

.save_informer_widget.active {
    right: 10px;
    transition: right 1s ease 0s;
}

.response_error_item {
    display: flex;
    width: 100%;
    margin-top: 10px;
    font-size: 12px;
    font-weight: 500;
}

.sostav_title {
    display: flex;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 500;
    width: 100%;
    justify-content: center;
}
.add_new_sostav {
    display: flex;
    width: 100%;
    height: 52px;
    border-radius: 5px;
    border: 1px solid #c9cccf;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 25px;
    font-weight: 600;
}

.add_new_sostav:hover {
    background-color: #F4F4F4;
    transition: background-color 0.4s ease 0s;
}
.landrys_wrapper {
    display: flex;
    width: 100%;
    margin-top: 40px;
    justify-content: center;
    flex-wrap: wrap;
}

.landry_text_wrapper {
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    padding: 0px 5px;
    text-align: center;
    font-weight: 500;
}

.landry_target_item {
    max-width: 130px;
    width: 100%;
    padding: 10px;
    justify-content: center;
}

.landry_wpapper_padding_content {
    border: 2px solid rgba(0, 0, 0, 0.03);
    cursor: pointer;
    padding: 3px;
}

.landry_wpapper_padding_content.active {
    border-color: black;
}

.landry_wpapper_padding_content:hover {
    box-shadow: 0px 0px 4px 0px rgba(34, 60, 80, 0.8);

}

.landry_img_container {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 50px;
}

.category_container_right_bar {
    width: 100%;
    min-height: 200px;
    display: flex;
    flex-direction: column;
}
.product_category_heading {
    display: flex;
    width: 100%;
    font-size: 16px;
    font-weight: 600;
}
.category_setter_wrap {
    display: flex;
    flex-direction: column;
    /*margin-top: px;*/
    max-height: 500px;
    overflow-y: scroll;
}

.one_item_category_setter {
    display: flex;
    height: 30px;
    width: 100%;
    margin-top: 10px;
    align-items: center;
    cursor: pointer;
}
.one_item_category_setter:hover {
    background: rgba(245, 245, 245, 0.35);
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
}
.category_check_name {
    margin-left: 25px;
}

.category_check_box {
    display: flex;
    width: 25px;
    height: 25px;
    border: 1px solid black;
    position: relative;
}

.category_check_box.active:after {
    content: "";
    width: 2px;
    height: 8px;
    background-color: black;
    position: absolute;
    top: 10px;
    left: 6px;
    border-radius: 5px;
    transform: rotate(-45deg);
}

.category_check_box.active:before {
    content: "";
    width: 2px;
    height: 15px;
    background-color: black;
    position: absolute;
    top: 4px;
    left: 13px;
    border-radius: 5px;
    transform: rotate(45deg);
}
.category_filter_input_container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    margin-top: 10px;
}

.input_filter_category {
    border: 1px solid #c9cccf;
    font-weight: 600;
    max-width: 100%;
    width: 100%;
    font-size: 12px;
    padding: 10px 5px;
    border-radius: 5px;
}

.category_counter {
    display: flex;
    width: 100%;
    font-weight: 600;
    margin-top: 20px;
}

.product_category_url {
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 10px;
}