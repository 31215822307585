.name_obmer_wrapper {
    display: flex;
    margin-top: 10px;
}
.delete_name_obmer {
    padding: 5px;
    background-color: rgba(208, 9, 9, 0.23);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 10px;
    font-weight: 600;
    border: 1px solid black;
    cursor: pointer;
    min-height: 30px;
    line-height: 10px;
    -webkit-user-select: none;
    user-select: none;
    margin-left: 30px;
}
.delete_name_obmer:hover {
    color: white;
    background-color: black;
    border-color: white;
}
.one_ombmer_container {
    display: flex;
    margin-top: 20px;
    width: 100%;
    min-height: 150px;
    border-radius: 5px;
    border: 1px solid #c9cccf;
    justify-content: space-between;
    padding: 10px 5px;
}
.obmer_size_name {
    display: flex;
    max-width: 300px;
    /*background-color: pink;*/
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0px 10px;
}
.obmer_name_size_input {
    display: flex;
    height: 50px;
    width: 140px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: 1px solid gray;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    margin-top: 10px;
    pointer-events: none;
    user-select: none;
}
.obmer_parrent_wrapper {
    display: flex;
    padding: 10px 0px;
    justify-content: space-between;
    align-items: center;
}
.obmer_field_two_wrapper {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}
.obmer_name_param {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #c9cccf;
    font-weight: 600;
    max-width: 100%;
    width: 100%;
    font-size: 12px;
    padding: 5px 5px;
    border-radius: 5px;
    margin-top: 5px;
}
.omb_label {
    font-size: 12px;
    font-weight: 600;
}
.omb_label.name_obmer {
    font-size: 14px;
}

.add_fields_obmer {
    display: flex;
    /*background-color: pink;*/
    width: 200px;
    margin-left: 30px;
    justify-content: center;
    align-items: center;
    font-size: 45px;
    font-weight: 500;
    cursor: pointer;
    user-select: none;
}
.add_fields_obmer:hover {
    background-color: rgba(128, 128, 128, 0.32);
    transition: background-color 0.8s ease 0s;
}
.obmer_wrapper_fields_pdn {
    padding: 10px;
}
.remove_fields_obmer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    padding: 0px 10px;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    user-select: none;
}
.remove_fields_obmer:hover {
    background-color: rgba(230, 145, 145, 0.58);
    transition: background-color 0.8s ease 0s;
}
.delete_obmer_button {
    writing-mode: vertical-lr;
    text-orientation: upright;
    display: flex;
    width: 90px;
    justify-content: center;
    align-items: center;
    letter-spacing: -0.2em;
    font-weight: 600;
    cursor: pointer;
}
.delete_obmer_button:hover {
    background-color: rgba(230, 145, 145, 0.58);
    transition: background-color 0.8s ease 0s;
}

.obmer_size_container {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    flex-wrap: wrap;
}
.mimi_size_obmer {
    display: flex;
    padding: 5px;
    border:1px solid black;
    font-weight: 600;
    margin-left: 10px;
    margin-top: 10px;
    font-size: 10px;
    cursor: pointer;
}

.mimi_size_obmer:hover {
    background-color: black;
    color: white;
}

.mimi_size_obmer:first-child {
    margin-left: 0px;
}