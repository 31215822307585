.favorites_one_product_wrapper {
    padding: 0px 10px;
    margin-top: 50px;
}

.favorites_one_product {
    max-width: 450px;
    width: 100%;
    cursor: pointer;
    padding-bottom: 10px;
    position: relative;
}
.favorites_dop_controll_bar_product {
    position: absolute;
    width: 100%;
    height: 95px;
    top: 0px;
    left: 0px;
    display: flex;
    padding: 0px 25px;
    justify-content: flex-end;
    align-items: center;
}
.favorites_add_favorite_product {
    height: 53px;
    width: 53px;
    background: #ffffff6b;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.favorites_add_favorite_product.active  svg path {
    fill: black;
}

.favorites_image_container {
    width: 100%;
    max-height: 600px;
    position: relative;
}
.favorites_image_container img {
    height: 100%;
    width: 100%;
}
.favorites_name_wrapper_product {
    width: 100%;
    /*padding-top: 20px;*/
    /*padding-bottom: 20px;*/
    /*max-width: 450px;*/
    /*min-height: 84px;*/
    display: flex;
    align-items: center;
}
.favorites_name_product_text {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #1A1A1A;
}

.favorites_price_product_wrapper {
    display: flex;
}
.favorites_valute_sublol {
    margin-left: 5px;
}

.favorites_standart_photo_product {
    display: flex;
    height: 100%;
    width: 100%;
}
.favorites_hover_photo_product {
    display: none;
}

.favorites_one_product:hover .favorites_standart_photo_product {
    display: none;
}
.favorites_one_product:hover .favorites_hover_photo_product {
    display: flex;
}
.favorites_add_favorite_product:hover  svg path {
    fill: transparent;
}

.favorites_controll_panel_offers_and_colors {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 250px;
    background: #ffffff6b;
    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 27px 0px;
    align-items: center;
}

.favorites_one_product:hover .favorites_controll_panel_offers_and_colors {
    opacity: 1;
    transition: opacity 0.4s ease 0s;
}
.favorites_color_picker_product {
    height: 50px;
    display: flex;
    justify-content: center;
    font-weight: 700;

}
.favorites_size_picker_product {
    height: 30px;
    display: flex;
    justify-content: center;
    font-weight: 700;
}

.favorites_add_product_to_cart {
    width: 410px;
    height: 58px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1A1A1A;
    text-transform: uppercase;
}



.bottom_one_product_wrapper {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: black;
    justify-content: space-between;
    position: relative;
    cursor: pointer;
}
