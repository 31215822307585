.shop {
    width: 100%;
    display: flex;
    justify-content: center;
}
.s_conteiner {
    max-width: 1920px;
    width: 100%;
    height: 100%;
    padding: 0px 40px;
    /*position: relative;*/
    display: flex;
    /*justify-content: space-between;*/
    flex-direction: column;
    /*background: #E69191;*/
    /*border-top: 1px solid #E0E0E0;*/
}
.map_and_cities {
    display: flex;
    align-items: center;
    /*width: 100%;*/
    min-height: 20px;
    margin-top: 20px;
}
.s_content {
    /*max-width: 950px;*/
    width: 45%;
    /*padding-right: 30px;*/
    background: #FFFFFF;
    /*border-right: 1px solid #E0E0E0;*/
}
.shop_title {
    font-weight: 500;
    font-size: 30px;
    color: #000000;
}
.maps_shop {
    display: flex;
    margin-top: 40px;
    padding-bottom: 40px;
}
.s_bread_crumbs {
    min-height: 70px;
}
.cities {
    display: flex;
    align-items: center;
    height: 30px;
}
.select_city {
    /*margin-left: 30px;*/
    white-space: nowrap;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
}
.parents_button_city {
    position: relative;
    display: flex;
    align-items: center;
}
.button_sity {
    display: flex;
    margin-left: 10px;
    margin-right: 5px;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.button_sity.active {
    position: absolute;
    background: white;
    z-index: 1;
    top: -12px;
    left: 0;
    padding: 10px 15px;
    border: 2px solid #000000;
    width: auto;
    opacity: 1;
}

.store_list {
    width: 100%;
    padding-bottom: 10px;
    padding-left: 50px;
}
.s_store_child {
    height: 1101px;
    overflow: scroll;
    overflow-x: hidden;
}
.s_store_child_non, .s_store_child {
    /*padding-top: 20px;*/
}

.s_store_child::-webkit-scrollbar {
    background-color: transparent;
    width: 3px;
}
.s_store_child::-webkit-scrollbar-thumb {
    background-color: black;
}

.s_maps {
    /*max-width: 843px;*/
    width: 55%;
    height: 960px;
}

.map_error {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #CE3F3F;
    font-weight: 600;
}



@media (max-width: 1200px) {
    .store_list {
        padding-left: 15px;
    }
    .s_conteiner {
       padding: 0px 20px;
    }
}

@media (max-width: 1024px) {

    .s_conteiner {
        padding: 0px 40px;
    }
    .shop_title {
        font-size: 24px;
    }
    .maps_shop {
        flex-direction: column-reverse;
        margin-top: 20px;
    }
    .s_store_child {
        height: 700px;
    }
    .s_content {
        width: 100%;
    }
    .store_list {
        padding-left: 0px;
    }
    .s_maps {
        width: 100%;
        height: 438px;
        margin-top: 40px;
    }

}


@media (max-width: 768px) {
    .s_store_child {
        height: 100%;
    }
    .s_conteiner {
        padding: 0px 20px;
    }
    .shop_title {
        font-size: 18px;
        line-height: 23px;
    }
    .map_and_cities {

    }
    .s_maps {
        margin-top: 20px;
        height: 447px;
    }
    .button_sity.active {
        top: 11px;
        left: -27px;
    }

}