.tt_container {
    position: relative;
    display: flex;
    margin-left: 10px;
}
  
.base_tooltip {
    display:inline-block;
    min-width: 300px;
    left: 150%;
    padding:10px 20px;
    color:black;
    background-color: white;
    font-weight: 400;
    font-size:14px;
    position:absolute;
    z-index:99999999;
    box-sizing:border-box;
    border: 1px solid black;
}

@media (max-width: 1024px) {

    .base_tooltip {
        right: 150%;
        left: auto;
    }
}