.fly_basket_promokod {
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.fly_basket_input_promokod {
    display: flex;
    height: 100%;
    flex-grow: 1;
    padding: 0px 17px;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    opacity: 1;
    color: #000000;
    text-align: left;
    text-transform: uppercase;
}
.go_active_promo {
    display: flex;
    height: 100%;
    width: 70px;
    position: relative;
}
.go_active_promo:hover {
    background-color: black;
    transition: background-color 0.4s ease 0s;
    cursor: pointer;
}
.go_active_promo:after {
    content: "";
    position: absolute;
    width: 2px;
    height: 15px;
    background-color: #8d8d8d;
    left: 50%;
    top: 13px;
    border-radius: 2px;
    transform: rotate(135deg);
}
.go_active_promo:hover:after {
    background-color: white;
    transition: background-color 0.4s ease 0s;
}
.go_active_promo:before {
    content: "";
    position: absolute;
    width: 2px;
    height: 15px;
    background-color: #8d8d8d;
    left: 50%;
    top: 22px;
    border-radius: 2px;
    transform: rotate(-135deg);
}
.go_active_promo:hover:before {
    background-color: white;
    transition: background-color 0.4s ease 0s;
}

.fly_basket_input_promokod::placeholder {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    text-transform: uppercase;
    opacity: 1;
    color: #757575;
    text-align: left;
    margin-left: 30px;
}
.wrapper_for_fly_basket_promo {
    display: flex;
    max-width: 440px;
    height: 50px;
    border: 1px solid #D7D6D6;
}
.fly_basket_promokod_notification {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    height: 20px;
    font-size: 12px;
    font-weight: 400;
}

@media(max-width: 834px) {
    .fly_basket_promokod_notification {
        display: block;
        text-align: center;
    }
}

@media(max-width: 768px){
    .wrapper_for_fly_basket_promo{
        max-width: none;
    }

}