.wrapper_page_contacts {
    display: flex;
    height: 100%;
    width: 100%;
}
.contacts_wrapper {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
}
.contacts_adm_title {
    display: flex;
    width: 100%;
    height: 76px;
    padding: 15px 20px;
    margin-top: 20px;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.03em;
}
.workspace_area_contacts {
    height: 100%;
    width: 100%;
    padding: 18px;
    display: flex;
    flex-direction: column;
}
.content_manager_contacts {
    background-color: #fff;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    height: auto;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}
.content_manager_contacts:first-child {
    margin-top: 0px;
}
.left_wrapper_contacts {
    max-width: 50%;
    display: flex;
    width: 100%;
    flex-direction: column;
}



.right_wrapper_contacts {
    position: relative;
    display: flex;
    width: 300px;
    max-height: 250px;
    background-color: #fff;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    padding: 10px;
}

.bottom_container_contacts {
    background-color: #fff;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    height: auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 20px;
}

.button_new_cards {
    display: flex;
    padding: 5px 10px;
    border: 1px solid black;
    border-radius: 5px;
    width: 150px;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
    cursor: pointer;
    transition: color 0.4s ease 0s, background 0.4s ease 0s;
}
.button_new_cards:hover {
    color: white;
    background: black;
    transition: color 0.4s ease 0s, background 0.4s ease 0s;
}

.container_cards_contact {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    flex-wrap: wrap;
}

.fields_container_link {
    display: flex;
    align-items: center;
    margin-top: 10px;
}
.link_contacts_check_box {
    display: flex;
    width: 25px;
    height: 25px;
    border: 1px solid black;
    position: relative;
    margin-left: 10px;
    cursor: pointer;
}

.link_contacts_check_box.active::before {
    content: "";
    width: 2px;
    height: 15px;
    background-color: black;
    position: absolute;
    top: 4px;
    left: 13px;
    border-radius: 5px;
    transform: rotate(45deg);
}
.link_contacts_check_box.active::after {
    content: "";
    width: 2px;
    height: 8px;
    background-color: black;
    position: absolute;
    top: 10px;
    left: 6px;
    border-radius: 5px;
    transform: rotate(-45deg);
}
.social_fields_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
/*.social_fields_container svg,span,input {*/
/*    margin-right: 10px;*/
/*}*/
.contacts_social_block {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.contacts_field_social {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #c9cccf;
    font-weight: 600;
    font-size: 12px;
    padding: 5px 5px;
    border-radius: 5px;
}
.subtitle_social_active {
    margin-top: 10px;
    margin-left: 30px;
    font-size: 14px;
    font-weight: 600;
}
.social_right_block {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-left: 30px;
    box-shadow: 0 0 5px rgb(23 24 24 / 15%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    margin-top: 15px;
}

.social_right_block:first-child {
    margin-top: 0px;
}
.content_requisites_contacts {
    background-color: #fff;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    height: auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}







.save_button_contacts_content {
    display: flex;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    height: 42px;
    background-color: #fff;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    margin-top: 30px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: 600;
    text-transform: uppercase;
}
.save_button_contacts_content:hover {
    color: white;
    background-color: black;
    transition: color 0.8s ease 0s, background-color 0.8s ease 0s;
}
.save_button_contacts_content.active {
    animation: trigerSocialManager 2s ease infinite;
}


@keyframes trigerSocialManager {
    0% {
        background: white;
        color: black;
    }
    50% {
        background: black;
        color: white;
    }
    100% {
        background: white;
        color: black;
    }

}
