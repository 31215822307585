.main.adminCheck {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.spin_wrapper {
    position: absolute;
    left: 50%;
    right: 50%;
    opacity: 1;
    width: 8vmax;
    height: 8vmax;
    transform: translate(-50%,50%);
}

.spin_wrapper.formActive {
    opacity: 0;
    transition: 0.3s ease 0s;
}



div.spinner:after {
    content: '';
    width: 6vmax;
    height: 6vmax;
    display: block;
    position: absolute;
    top: calc(50% - 3vmax);
    left: calc(50% - 3vmax);
    border-left: 3px solid black;
    border-radius: 100%;
    animation: spinLeft 800ms linear infinite;
}



div.spinner:before {
    content: '';
    width: 6vmax;
    height: 6vmax;
    display: block;
    position: absolute;
    top: calc(50% - 3vmax);
    left: calc(50% - 3vmax);
    border-left: 3px solid black;
    border-radius: 100%;
    animation: spinLeft 800ms linear infinite;
}

div.spinner:after {
    width: 4vmax;
    height: 4vmax;
    top: calc(50% - 2vmax);
    left: calc(50% - 2vmax);
    border: 0;
    border-right: 2px solid black;
    animation: none;
}




@keyframes spinLeft {
    from {transform:rotate(0deg);}
    to {transform:rotate(720deg);}
}

@keyframes spinRight {
    from {transform:rotate(360deg);}
    to {transform:rotate(0deg);}
}



div.spinner {
    width: 8vmax;
    height: 8vmax;
    opacity: 1;
    border-radius: 100%;
    animation: spinRight 800ms linear infinite;
}



.admin_auth_form_container {
    opacity: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.admin_auth_form_container.formActive {
    opacity: 1;
    height: 100%;
    transition: opacity 0.3s ease 0.35s;
    z-index: 5;
}

.auth_form_for_admin {
    max-width: 350px;
    width: 100%;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    border: 1px solid #0000000a;
    box-shadow: 0px 0px 12px 3px rgba(34, 60, 80, 0.02);
    padding: 35px;
}

.auth_page_title {
    text-transform: uppercase;
    letter-spacing: 0.04em;
    color: black;
    font-size: 25px;
    font-weight: 700;
    margin: 30px 0px;
}
.inputwrapper_auth_form {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.name_field_auth {
    font-weight: 500;
    letter-spacing: 0.1em;
    margin-left: 10px;
    margin-top: 10px;
    width: max-content;
    font-size: 15px;
}
.auth_input_atm {
    background: transparent;
    width: 100%;
    border-bottom: 2px solid black;
    padding-bottom: 5px;
    margin-top: 10px;
    padding-left: 10px;
    font-size: 15px;
    font-weight: 500;
}
.error_field_auth_admin_page {
    color: #CE3F3F;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    min-height: 40px;
    padding: 0px 10px;
}

.auth_button_admin {
    margin: 0 auto;
    display: flex;
    background: black;
    color: white;
    justify-content: center;
    align-items: center;
    height: 40px;
    max-width: 250px;
    width: 100%;
    cursor: pointer;
}




