.vacancies_cards {
    max-width: 545px;
    min-height: 289px;
    width: 100%;
    height: initial;
    /*padding: 30px;*/
    border: 2px solid #000000;
    /*box-shadow: 0px 0px 20px rgb(0 0 0 / 15%);*/
    background: white;
    margin-right: 33px;
    margin-top: 40px;
}
.vacancies_cards_subtitle {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
    padding: 18px 40px;
    border-bottom: 2px solid black;
}
.employment_wage_wrapper {
    padding: 28px 40px;
}
.employment_wage {
    display: flex;

}
.employment_wage_container {
    /*max-width: 200px;*/
    /*width: 100%;*/
    margin-left: 18px;
}
.employment_wage_container:first-child {
    margin-left: 0px;
}

.employment_wage_subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #9A9A9A;
    margin-top: 18px;
}
.employment_wage_subtitle:first-child {
    margin-top: 0px;
}
.employment_wage_text {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #1A1A1A;
    margin-top: 18px;
}
.employment_wage_text:first-child {
    margin-top: 0px;
}
.vacancies_button {
    display: flex;
    margin-top: 24px;
    align-items: center;
    max-width: 390px;
    justify-content: space-between;
}
.button_response_vacancies {
    padding: 14px;
    border: 1px solid #1A1A1A;
    max-width: 182px;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.4s ease 0s, color 0.4s ease 0s;
}
.button_response_vacancies:hover {
    background: #1A1A1A;
    color: white;
    transition: background 0.4s ease 0s, color 0.4s ease 0s;
}
.button_learn_more {
    font-weight: 500;
    font-size: 14px;
    color: #9A9A9A;
    text-decoration: none;
    margin-left: 27px;
    border-bottom: 1px solid transparent;
    transition: border-bottom 0.4s ease 0s;
}
.button_learn_more:hover {
    transition: border-bottom 0.4s ease 0s;
    border-bottom: 1px solid #9A9A9A;
}


@media (max-width: 1024px) {
    .vacancies_cards {
        margin-top: 40px;
        max-width: unset;
        margin-right: 0px;
    }
    .vacancies_cards:first-child {
        margin-top: 20px;
    }
}
@media (max-width: 768px) {
    .vacancies_cards {
        margin-top: 20px;
    }
    .vacancies_cards_subtitle {
        padding: 18px 20px;
    }
    .employment_wage {
        flex-direction: column;
    }
    .employment_wage_wrapper {
        padding: 20px;
    }
    .employment_wage_container {
        margin-top: 10px;
        margin-left: 0px;
    }
    .employment_wage_container:first-child {
        margin-top: 0px;
    }
    .employment_wage_text{
        margin-top: 10px;
        font-size: 14px;
    }
    .employment_wage_subtitle {
        font-size: 12px;
    }
    .vacancies_button {
        flex-direction: column;
        align-items: flex-start;
        margin-top: 20px;
    }
    .button_learn_more {
        margin-top: 20px;
        margin-left: 0px;
    }



}
