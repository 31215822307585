.refound_and_delivery {
    display: flex;
    /*height: 100%;*/
    /*width: 100%;*/
    flex-direction: column;
    align-items: center;
}
.refound_and_delivery_child {
    max-width: 1620px;
    width: 100%;
    padding: 0px 10px;
    margin-bottom: 40px;
}
.conteiner_banner {
    /*width: 100%;*/
    /*height: 831px;*/
    /*position: relative;*/
}
.banner_delivery_img {
    width: 100%;
    object-fit: cover;
    height: 100%;
}
.wrapper_info_delivery {

}



@media (max-width: 1440px) {
    .refound_and_delivery_child {
        max-width: 1143px;
    }

}


@media (max-width: 1024px) {

    .refound_and_delivery_child {
        padding: 0px 40px;
    }

}

@media (max-width: 768px) {
    .refound_and_delivery_child {
        padding: 0px 20px;
    }
}

