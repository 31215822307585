
.users_page_wrapper {
    padding: 30px;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
}
.list_all_users {
    margin-bottom: 40px;
    max-height: 700px;
    overflow-y: scroll;
}
.redact_user_modal {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    border: 2px solid black;
    background-color: white;
    width: 100%;
    height: 100%;
    max-width:1200px;
    max-height: 800px;
    padding: 15px;
}
.wrapper_all_users {
    background-color: #fff;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    width: 100%;
    display: flex;
    padding: 20px;
    flex-direction: column;
    margin-top: 40px;
}
.add_new_user_block {
    background-color: #fff;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    width: 100%;
    display: flex;
    padding: 20px;
    flex-direction: column;
}
.wrapper_all_users {
    display: flex;
    flex-direction: column;
}

.user_list_page_heading {
    display: flex;
    font-weight: 600;
}


.users_page_wrapper .controll_and_sort_users_list {
    display: flex;
    margin-top: 30px;
}
.users_page_wrapper .wrapper_for_role_button {
    display: flex;
}

.role_button_users {
    display: flex;
    padding: 10px;
    border: 1px solid black;
    cursor: pointer;
    margin-left: 30px;
    font-weight: 600;
}

.role_button_users:hover {
    background-color: black;
    color: white;
}
.role_button_users.active {
    background-color: black;
    color: white;
}

.search_user_input {
    height: 40px;
    align-items: center;
    font-weight: 600;
    border: 1px solid black;
    padding: 10px;
}

.redact_user_top_controll_line {
    display: flex;
    justify-content: flex-end;
}
.redact_user_close {
    display: flex;
    padding: 10px;
    border: 1px solid black;
    font-weight: 600;
    cursor: pointer;
}
.redact_user_close:hover {
    background-color: black;
    color: white;
    transition: all 0.8s;
}
.container_for_user_field_wrap {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}
.user_field_redact {
    display: flex;
    margin-top: 10px;
    padding: 5px;
    border: 1px solid black;
    width: 200px;
    font-weight: 600;
}

.no_tuch {
    user-select: none;
    pointer-events: none;
    border-color: gray;
    color: gray;
}
.btns_for_set_role {
    display: flex;
    margin-top: 10px;
}

.btn_set_role_btn {
    padding: 10px;
    border: 1px solid black;
    font-weight: 600;
    cursor: pointer;
    margin-left: 20px;
}
.btn_set_role_btn.active {
    background-color: black;
    color: white;
}
.btn_set_role_btn:first-child {
    margin-left: 0px;
}
.controll_user_fw {
    font-weight: 600;
}

.go_save_user_fields {
  display: flex;
    font-weight: 600;
    padding: 10px;
    cursor: pointer;
    margin-top: 20px;
    border: 1px solid black;
    width: 200px;
    justify-content: center;
}
.go_save_user_fields:hover {
    background-color: black;
    color: white;
}