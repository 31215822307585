.controll_modal_page {
    background-color: #fff;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    display: flex;
    width: 100%;
    margin-top: 20px;
    padding: 10px;
    flex-direction: column;
}

.one_modal_page_input {
    width: 100%;
    max-width: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #c9cccf;
    font-weight: 600;
    font-size: 12px;
    padding: 5px 5px;
    border-radius: 5px;
    margin-top: 5px;
}
.file_upload_modal {
    margin-top: 20px;}
.modal_pfd_file {
    margin-top: 20px;
}
.button_deleted_pdf {
    cursor: pointer;
    color: #CE3F3F;
}
.save_btn_modal_page {
    display: flex;
    padding: 5px 10px;
    border: 1px solid black;
    font-size: 14px;
    background: white;
    font-weight: 600;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    max-width: 200px;
    height: 42px;
    align-items: center;
    margin-right: 20px;
    margin-top: 40px;
}
.one_modal_page_name_input {
    font-weight: 600;
    font-size: 12px;
    margin-top: 15px;
}

.one_modal_image_upload_wrapper {
    display: flex;
    justify-content: flex-start;
    height: 300px;
    min-height: 300px;
}
.one_modal_image_download_container {
    height: 100%;
    width: 200px;

}

.one_modal_image_download_container .preview_container_img {
    max-width: 200px;
}

.save_modal_indicator_text {
    display: flex;
    margin-top: 30px;
    font-weight: 600;
    margin-left: 10px;
}

